<template>
  <UI> </UI>
</template>

<script>
import UI from "../components/AdminUI.vue";
export default {
  components: {
    UI,
  },
};
</script>

<style></style>
