import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        options: {
            customProperties: true
        },
        light: true,
        themes: {
                dark: {
                    primary: '#3680A7',
                    secondary: '#F28A00',
                    background: '#EEEEEE'
                },
                light: {
                    primary: '#3680A7',
                    background: '#EEEEEE',
                    secondary: '#F28A00',
                }
            } 
        },
});
