<template>
  <v-dialog v-model="localValue.visible" max-width="400">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Autorizacion</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div class="row">
          <div class="col">
            <v-text-field
              v-model="localValue.strUsuario"
              :rules="[() => !!localValue.strUsuario || 'Llene este campo']"
              append-icon="mdi-account"
              label="Usuario"
              outlined
              required
            >
            </v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-text-field
              v-model="localValue.strPassword"
              :rules="[() => !!localValue.strPassword || 'Llene este campo']"
              append-icon="mdi-shield-lock"
              type="password"
              label="Password"
              outlined
              required
            >
            </v-text-field>
          </div>
        </div>
        <div class="row" v-if="version == 2">
          <div class="col">
            <v-text-field
              v-model="localValue.strUsuarioPara"
              :rules="[() => !!localValue.strUsuarioPara || 'Llene este campo']"
              append-icon="mdi-account"
              label="Usuario Asignar"
              outlined
              required
            >
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <v-btn block color="primary" @click="autorizar('CUENTA')"> Autorizar </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-btn block color="success" @click="nip.visible = true"> NIP </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>

    <v-dialog v-model="nip.visible" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="nip.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>NIP</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <div class="row">
            <div class="col">
              <v-text-field
                v-model="localValue.strNIP"
                :rules="nipRules"
                append-icon="mdi-shield-lock"
                type="password"
                label="NIP"
                outlined
                required
              >
              </v-text-field>
            </div>
          </div>
          <div class="row" v-if="version == 2">
            <div class="col">
              <v-text-field
                v-model="localValue.strUsuarioPara"
                :rules="[() => !!localValue.strUsuarioPara || 'Llene este campo']"
                append-icon="mdi-account"
                label="Usuario Asignar"
                outlined
                required
              >
              </v-text-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <v-btn block color="primary" @click="autorizar('NIP')"> Autorizar </v-btn>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    value: {
      required: true,
    },
    empresa: {
      type: Number,
      default: 1,
    },
    version: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localValue: this.value,
      loading: false,
      nip: {
        visible: false,
        val: "",
      },
      nipRules: [
        (v) => !!v || "Ingrese el NIP",
        (v) => v.length == 4 || "Ingrese los 4 digitos de su NIP",
      ],
    };
  },
  watch: {
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    async autorizar(version) {
      const loading = this.$vs.loading();
      try {
        if (version == "NIP") {
          //! MODIFICAR, MOVER A USERFUNCTIONS
          const responseAuth = (
            await axios.post(
              process.env.VUE_APP_API_ROUTE + "POS_AutorizaNIP/" + this.empresa,
              this.nip
            )
          ).data;
          if (responseAuth.IntError > 0) throw responseAuth.StrMessage;
          this.$emit("authorization", this.localValue.event);
          if (this.version == 2) {
            this.$emit("authorization", {
              name: this.localValue.event,
              data: this.localValue.strUsuarioPara,
            });
          } else {
            this.$emit("authorization", this.localValue.event);
          }
        } else if (version == "CUENTA") {
          //! MOVER A USER FUNCIONTS
          const responseAuth = (
            await axios.post(
              process.env.VUE_APP_API_ROUTE + "POS_Autoriza/" + this.empresa,
              this.localValue
            )
          ).data;
          if (responseAuth.IntError > 0) throw responseAuth.StrMessage;
          if (this.version == 2) {
            this.$emit("authorization", {
              name: this.localValue.event,
              data: this.localValue.strUsuarioPara,
            });
          } else {
            this.$emit("authorization", this.localValue.event);
          }
        }
      } catch (e) {
        if (e.response) {
          this.$vs.notification({
            color: "danger",
            width: "100%",
            position: "bottom-center",
            title: "Error",
            text: e.response.data,
          });
        } else {
          this.$vs.notification({
            color: "danger",
            width: "100%",
            position: "bottom-center",
            title: "Error",
            text: e,
          });
        }
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style></style>
