<template>
  <v-dialog v-model="visible" persistent max-width="350">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelar()">
          {{ cancelarText }}
        </v-btn>
        <v-btn color="green" text @click="aceptar()">
          {{ aceptarText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    aceptarText: {
      type: String,
      default: "",
    },
    cancelarText: {
      type: String,
      default: "",
    },
    event: {
      type: String,
      default: "",
    },
  },
  methods: {
    cancelar() {
      this.$emit("cancelar", this.event);
    },
    aceptar() {
      this.$emit("aceptar", this.event);
    },
  },
};
</script>

<style>
</style>