<template>
  <v-dialog v-model="localValue.visible" width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title v-if="localValue.version"
          >Agregar {{ localValue.version }} especial</v-toolbar-title
        >
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Descripcion"
              rounded
              v-model="localValue.strDescripcion"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Cantidad"
              min="1"
              rounded
              @keypress="isNumber($event)"
              type="number"
              v-model="localValue.dblCantidad"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Precio"
              min="0"
              rounded
              outlined
              type="number"
              @keypress="isFloat($event)"
              prefix="$"
              v-model="localValue.dblPrecio"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="guardar()" block color="primary">Guardar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Toast } from "../controllers/DataController.js";
import { isNumber, isFloat } from "../js/utilities";

export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    isNumber: isNumber,
    isFloat: isFloat,

    guardar() {
      try {
        if (
          this.localValue.strDescripcion == "" ||
          this.localValue.strDescripcion == null
        ) {
          throw "Favor de agregar una descripcion";
        }
        const obj = {
          strDescripcion: this.localValue.strDescripcion.toUpperCase(),
          dblCantidad: this.localValue.dblCantidad,
          dblPrecio: this.localValue.dblPrecio,
          version: this.localValue.version,
        };
        this.$emit("guardar", obj);
        this.localValue.visible = false;
      } catch (error) {
        this.$toast.error(error, new Toast());
      }
    },
  },
  watch: {
    ["localValue.visible"](newValue) {
      if (newValue) {
        this.localValue.dblCantidad = 1;
        this.localValue.dblPrecio = 0.0;
        this.localValue.strDescripcion = "";
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
