<template>
  <v-dialog v-model="localValue.visible" persistent width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Opciones de Detalle</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-list flat>
          <v-list-item @click="editarDetalle()" v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-tooltip-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="eliminarDetalle()" v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="cancelarDetalle()">
            <v-list-item-icon>
              <v-icon>mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cancelar / Devolver</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="descuentoDetalle()">
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Descuento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="reconfigurar()" v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi mdi-autorenew</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reconfigurar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      visible:true
    };
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  methods: {
    eliminarDetalle() { 
      this.$emit("eliminar");
    },
    editarDetalle() {
      this.$emit("editar");
    },
    descuentoDetalle() {
      this.$emit("descuento");
    },
    cancelarDetalle() {
      this.$emit("cancelar");
    },
    reconfigurar() {
      this.$emit("reconfigurar");
    },
   
  },
  mounted() {
      //conf para mostrar panel de articulos
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    }
 };
</script>

<style></style>
