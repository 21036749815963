export function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
    } else {
        const result = parseInt(evt.target.value);
        if (result < 0) {
            evt.preventDefault();
        } else {
            return true;
        }
    }
}

var numeral = require("numeral");

export function isMoney(value) {
    if (!value) 
        return numeral(0.00).format("$0,0.00");
    else 
        return numeral(value).format("$0,0.00");
}

export function isFloat(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
    ) {
        evt.preventDefault();
    } else {
        return true;
    }
}

import moment from "moment";

export function toDateString(date, version) {
    if (version == 1)
    return moment(date).format('DD/MM/YYYY');
    else if (version == 2)
    return moment(date).format('DD/MM/YYYY hh:mm:ss A');
    else if (version == 3)
    return moment(date).format('hh:mm:ss A');
    else if (version == 4)
    return moment(date).format('LL');
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  
export function guid() {
    return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
    );
}