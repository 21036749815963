<template>
  <v-dialog
    v-model="localValue.visible"
    persistent
    max-width="700"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Registro Inventario </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <div class="row">
          <div class="col">
            <v-select
              :items="acciones"
              outlined
              item-text="label"
              item-value="value"
              label="Accion"
              v-model="accion"
            ></v-select>
          </div>
        </div>
        <v-simple-table v-if="accion == 1">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Clave</th>
              <th>Existencias</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(tr, i) in data_1" :data="tr">
              <td>{{ tr.strNombre }}</td>
              <td>{{ tr.strNombreCorto }}</td>
              <td>{{ tr.Existencias }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="accion == 2">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Clave</th>
              <th>Existencias</th>
              <th>Entradas</th>
              <th>Final</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(tr, i) in data_1" :data="tr">
              <td>{{ tr.strNombre }}</td>
              <td>{{ tr.strNombreCorto }}</td>
              <td>{{ tr.Existencias }}</td>
              <td>
                <v-text-field type="number" v-model="tr.Entradas"></v-text-field>
              </td>
              <td>{{ parseInt(tr.Existencias) + parseInt(tr.Entradas) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table v-if="accion == 3">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Clave</th>
              <th>Existencias</th>
              <th>Salidas</th>
              <th>Final</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(tr, i) in data_1" :data="tr">
              <td>{{ tr.strNombre }}</td>
              <td>{{ tr.strNombreCorto }}</td>
              <td>{{ tr.Existencias }}</td>
              <td>
                <v-text-field type="number" v-model="tr.Salidas"></v-text-field>
              </td>
              <td>{{ parseInt(tr.Existencias) - parseInt(tr.Salidas) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <div class="row">
          <div class="col">
            <v-text-field
              v-if="accion == 2 || accion == 3"
              v-model="comentario"
              label="Comentario"
              outlined
            ></v-text-field>
          </div>
        </div>

        <v-btn block color="primary" v-if="accion == 2" @click="entradas()">
          Guardar Entradas
        </v-btn>
        <v-btn block color="primary" v-if="accion == 3" @click="salidas()">
          Guardar Salidas
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Toast } from "../controllers/DataController.js";
export default {
  props: {
    value: {
      required: true,
    },
    idm: {
      type: String,
      default: "",
    },
    empresa: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      acciones: [
        { label: "Seleccione una opcion...", value: 0 },
        { label: "Ver existencias", value: 1 },
        { label: "Entradas", value: 2 },
        { label: "Salidas", value: 3 },
      ],
      localValue: this.value,
      editActive: false,
      edit: null,
      editProp: {},
      accion: 0,
      data_1: [],
      loading: false,
      comentario: "",
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    async accion() {
      this.loading = true;
      try {
        this.data_1 = [];
        //this.data_1 = (await axios.get(CSTRHOSTNAME + CSTRAPPNAME + "/TPV/POSLite/POS.aspx?action=POS_ExistenciasInventario&intEmpresa=" + this.empresa + "&intSucursal=0&idm=" + this.idm)).data;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  methods: {
    async entradas() {
      this.loading = true;
      try {
        if (this.comentario == "") {
          throw "Ingrese un comentario";
        }
        //await axios.post(CSTRHOSTNAME + CSTRAPPNAME + "/TPV/POSLite/POS.aspx?action=POS_GeneraEntradasInventario&intEmpresa=" + this.empresa + "&intSucursal=0&comentario=" + this.comentario + "&idm=" + this.idm, this.data_1);
        //this.data_1 = (await axios.get(CSTRHOSTNAME + CSTRAPPNAME + "/TPV/POSLite/POS.aspx?action=POS_ExistenciasInventario&intEmpresa=" + this.empresa + "&intSucursal=0&idm=" + this.idm)).data;
        this.$toast.success("Entradas generadas con exito!", new Toast());
        this.comentario = "";
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
    async salidas() {
      this.loading = true;
      try {
        if (this.comentario == "") {
          throw "Ingrese un comentario";
        }
        //validamos que no existan negativos de existencias negativos
        this.data_1.forEach((x) => {
          if (parseInt(x.Existencias) - parseInt(x.Salidas) < 0) {
            throw "No se pueden guardar negativos";
          }
        });
        //await axios.post(CSTRHOSTNAME + CSTRAPPNAME + "/TPV/POSLite/POS.aspx?action=POS_GeneraSalidasInventario&intEmpresa=" + this.empresa + "&intSucursal=0&comentario=" + this.comentario + "&idm=" + this.idm, this.data_1);
        //this.data_1 = (await axios.get(CSTRHOSTNAME + CSTRAPPNAME + "/TPV/POSLite/POS.aspx?action=POS_ExistenciasInventario&intEmpresa=" + this.empresa + "&intSucursal=0&idm=" + this.idm)).data;
        this.$toast.success("Salidas generadas con exito!", new Toast());

        this.comentario = "";
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
