<template>
  <v-dialog v-model="localValue.visible" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              @click:row="selectRow"
              :search="search"
              :headers="headers"
              :items="rows"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar..."
                  class="mx-4"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      search: null,
      localValue: this.value,
    };
  },
  methods: {
    selectRow(item) {
      this.$emit("selected", item);
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
