<template>
  <div>
    <Loader v-model="loading" />

    <v-dialog v-model="clientesDialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="clientesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Clientes</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="clientes"
                  :search="search"
                  @click:row="seleccionaCliente"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Facturacion</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion de Venta</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha de Venta"
                        outlined
                        v-model="fecha"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="# de Ticket"
                        v-model="ticket"
                        outlined
                        type="number"
                        min="0"
                        max="10000"
                      ></v-text-field>
                    </v-col>

                    <v-col md="1">
                      <v-btn x-large block color="primary" @click="buscar()">
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="factura.intPOSEnc != 0">
          <v-col>
            <v-card>
              <v-card-title> Informacion de Ticket</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :value="isMoney(posenc.dblSubtotal)"
                        label="SubTotal"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="isMoney(posenc.dblIVA)"
                        label="IVA"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :value="isMoney(posenc.dblImporteDescuento)"
                        label="Descuento"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Total"
                        :value="isMoney(posenc.dblTotal)"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="detalles.length > 0">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Articulo</th>
                            <th class="text-left">Cantidad</th>
                            <th class="text-left">Precio</th>
                            <th class="text-left">IVA</th>
                            <th class="text-left">Precio</th>
                            <th class="text-left">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in detalles" :key="item.intPOSDet">
                            <td>{{ item.strNombre }}</td>
                            <td>{{ item.dblCantidad }}</td>
                            <td>{{ isMoney(item.dblPU) }}</td>
                            <td>{{ isMoney(item.dblImporteDescuento) }}</td>
                            <td>{{ item.dblIVA }}</td>
                            <td>{{ isMoney(item.dblTotal) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="factura.intPOSEnc != 0">
          <v-col>
            <v-card>
              <v-card-title>Informacion de Facturacion</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-btn @click="limpiar" block color="primary">Limpiar</v-btn>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Razon Social / Nombre"
                          v-model="factura.strNombre"
                          outlined
                          :rules="rules.razon"
                          required
                          @input="factura.strNombre = factura.strNombre.toUpperCase()"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="RFC"
                          :counter="13"
                          :rules="rules.rfc"
                          v-model="factura.strRFC"
                          outlined
                          required
                          @input="factura.strRFC = factura.strRFC.toUpperCase()"
                        ></v-text-field>
                      </v-col>
                      <v-col md="1">
                        <v-btn @click="buscarCliente" icon color="primary">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Calle"
                          v-model="factura.strDireccion"
                          :rules="rules.calle"
                          required
                          outlined
                          @keyup="toUpper($event)"
                          @input="
                            factura.strDireccion = factura.strDireccion.toUpperCase()
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Colonia"
                          v-model="factura.strColonia"
                          :rules="rules.colonia"
                          required
                          outlined
                          @keyup="toUpper($event)"
                          @input="factura.strColonia = factura.strColonia.toUpperCase()"
                        ></v-text-field>
                      </v-col>
                      <v-col md="1">
                        <v-text-field
                          label="# Exterior"
                          v-model="factura.strNumeroExterior"
                          :rules="rules.exterior"
                          required
                          outlined
                          @keyup="toUpper($event)"
                          @input="
                            factura.strNumeroExterior = factura.strNumeroExterior.toUpperCase()
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col md="1">
                        <v-text-field
                          label="# Interior"
                          v-model="factura.strNumeroInterior"
                          outlined
                          @keyup="toUpper($event)"
                          @input="
                            factura.strNumeroInterior = factura.strNumeroInterior.toUpperCase()
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          label="C.P."
                          v-model="factura.intCP"
                          :counter="5"
                          :rules="rules.cp"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Pais"
                          v-model="factura.strPais"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-select
                          :items="estados"
                          outlined
                          item-text="nombre"
                          item-value="nombre"
                          label="Estado"
                          v-model="factura.strEstado"
                        />
                      </v-col>
                      <v-col>
                        <v-select
                          :items="municipios"
                          :rules="rules.municipio"
                          required
                          outlined
                          label="Municipio"
                          v-model="factura.strMunicipio"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Correo"
                          v-model="factura.strCorreo"
                          :rules="rules.correo"
                          required
                          outlined
                          @input="factura.strCorreo = factura.strCorreo.toLowerCase()"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="# Celular"
                          v-model="factura.strTelefono"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          :items="[
                            {
                              value: 'PUE',
                            },
                            {
                              value: 'PPD',
                            },
                          ]"
                          outlined
                          item-text="value"
                          item-value="value"
                          label="Metodo de Pago"
                          v-model="factura.strMetodoPago"
                        />
                      </v-col>
                      <v-col>
                        <v-select
                          :items="usocfdi"
                          outlined
                          item-text="descripcion"
                          item-value="valor"
                          label="Uso CFDI"
                          v-model="factura.strSATCodigoUsoCFDI"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-btn @click="facturar" block color="secondary">Facturar</v-btn>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import { isMoney, toDateString } from "../js/utilities";
import moment from "moment";

class Factura {
  constructor() {
    this.intPOSEnc = 0;
    this.intCliente = 0;
    this.intTipoCliente = 0;
    this.datFecha = null;
    this.strNombre = "";
    this.strRFC = "";
    this.strDireccion = "";
    this.strColonia = "";
    this.strPoblacion = "";
    this.intCP = 0;
    this.strTelefono = "";
    this.strCorreo = "";
    this.strNumeroExterior = "";
    this.strNumeroInterior = "";
    this.strEstado = "NUEVO LEON";
    this.strPais = "MEXICO";
    this.intTipoPago = null;
    this.intCondicionPago = null;
    this.strSATCodigoUsoCFDI = "P01";
    this.intTipoFactura = 0;
    this.strSerie = "";
    this.strMetodoPago = "PUE";
  }
}

export default {
  data() {
    return {
      municipios: [],
      clientes: [],
      valid: false,
      municipiosdb: [],
      fecha: moment().format("yyyy-MM-DD"),
      ticket: 0,
      factura: new Factura(),
      session: new Session(),
      ordenFunctions: new POSEncFunctions(),
      loading: false,
      posenc: [],
      detalles: [],
      estados: [],
      clientesDialog: false,
      usocfdi: [],
      selectedCliente: null,
      search: "",
      headers: [
        { text: "Nombre", value: "strNombre" },
        { text: "RFC", value: "strRFC" },
        { text: "Direccion", value: "strDireccion" },
        { text: "# Ext", value: "strNumeroExterior" },
        { text: "Colonia", value: "strColonia" },
        { text: "Municipio", value: "strPoblacion" },
      ],
      rules: {
        razon: [(v) => !!v || "La razon social es un campo obligatorio"],
        calle: [(v) => !!v || "Favor de llenar el campo"],
        colonia: [(v) => !!v || "Favor de llenar el campo"],
        exterior: [(v) => !!v || "Favor de llenar el campo"],
        cp: [
          (v) => !!v || "Favor de llenar el campo",
          (v) => v.toString().length == 5 || "El codigo postal debe de tener 5 digitos",
        ],
        municipio: [(v) => !!v || "Favor de seleccionar un municipio"],
        rfc: [
          (v) => !!v || "El RFC es un campo obligatorio",
          (v) =>
            (v.length > 11 && v.length < 14) ||
            "El RFC tiene que tener entre 12 a 13 caracteres",
        ],
        correo: [
          (v) => !!v || "El correo es un campo obligatorio",
          (v) => /.+@.+/.test(v) || "El formato del correo es incorrecto",
        ],
      },
    };
  },
  components: {
    Loader,
  },
  watch: {
    async ["factura.strEstado"](newValue) {
      this.factura.strMunicipio = "";
      this.municipios = this.municipiosdb[newValue];
      this.municipios = this.municipios.map((x) => x.toUpperCase());
    },
  },
  methods: {
    limpiar() {
      this.factura.intCliente = 0;
      this.factura.intTipoCliente = 0;
      this.factura.datFecha = null;
      this.factura.strNombre = "";
      this.factura.strRFC = "";
      this.factura.strDireccion = "";
      this.factura.strColonia = "";
      this.factura.strPoblacion = "";
      this.factura.intCP = 0;
      this.factura.strTelefono = "";
      this.factura.strCorreo = "";
      this.factura.strNumeroExterior = "";
      this.factura.strNumeroInterior = "";
      this.factura.strMunicipio = null;

      this.factura.strEstado = "NUEVO LEON";
      this.factura.strPais = "MEXICO";
      this.factura.intTipoPago = null;
      this.factura.intCondicionPago = null;
      this.factura.strSATCodigoUsoCFDI = "P01";
      this.factura.intTipoFactura = 0;
      this.factura.strSerie = "";
      this.factura.strMetodoPago = "PUE";
    },
    seleccionaCliente(cliente) {
      this.factura.intCliente = cliente.intCliente;
      this.factura.strNombre = cliente.strNombre;
      this.factura.strRFC = cliente.strRFC;
      this.factura.strDireccion = cliente.strDireccion;
      this.factura.strColonia = cliente.strColonia;
      this.factura.strPoblacion = cliente.strPoblacion;
      this.factura.intCP = cliente.intCP;
      this.factura.strTelefono = cliente.strTelefono;
      this.factura.strCorreo = cliente.strEmail;
      this.factura.strNumeroExterior = cliente.strNumeroExterior;
      this.factura.strNumeroInterior = cliente.strNumeroInterior;
      this.factura.strEstado = cliente.strEstado;
      this.factura.strPais = cliente.strPais;
      this.factura.intTipoPago = cliente.intTipoPago;
      this.factura.intCondicionPago = cliente.intCondicionPago;
      this.factura.strSATCodigoUsoCFDI = cliente.strSATCodigoUsoCFDI;
      this.factura.intTipoFactura = cliente.intTipoFactura;
      this.factura.strMetodoPago = "PUE";
      this.factura.strMunicipio = cliente.strPoblacion;

      this.clientesDialog = false;
    },
    async buscarCliente() {
      try {
        this.loading = true;
        this.clientes = await this.ordenFunctions.buscarClienteRFC(this.factura.strRFC);
        this.clientesDialog = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    toUpper(event) {
      event.target.value = event.target.value.toUpperCase();
    },
    isMoney: isMoney,
    toDateString: toDateString,
    goBack() {
      this.$router.push("/Main");
    },
    async facturar() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.loading = true;
        let valid = console.log(valid);
        let postData = {
          ...this.factura,
          ...this.$config.facturacion,
        };
        await this.ordenFunctions.facturar(postData);
        this.$toast.success("Factura generada con exito", new Toast());
        this.factura = new Factura();
        this.detalles = [];
        this.fecha = moment().format("yyyy-MM-DD");
        this.ticket = 0;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async buscar() {
      this.loading = true;
      try {
        if (this.ticket == 0 || this.ticket == null || this.ticket == "") {
          this.$toast.error(ErrorWeb("Favor de escribir un ticket"), new Toast());
          return;
        }
        this.posenc = await this.ordenFunctions.getPOSFacturacion({
          fecha: this.fecha,
          ticket: this.ticket,
        });
        this.factura.datFecha = this.posenc.datFecha;
        this.detalles = await this.ordenFunctions.detalles(this.posenc.intPOSEnc);
        this.factura.intPOSEnc = this.posenc.intPOSEnc;
      } catch (e) {
        this.factura = new Factura();
        this.detalles = [];
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    }
    this.usocfdi = await (await fetch("/usocfdi.json")).json();
    this.estados = await (await fetch("/estados.json")).json();
    this.municipiosdb = await (await fetch("/municipios.json")).json();
    this.municipios = this.municipiosdb[this.factura.strEstado];
    this.municipios = this.municipios.map((x) => x.toUpperCase());
  },
};
</script>

<style></style>
