<template>
  <v-dialog v-model="localValue.visible" width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Agregar articulo</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <div style="text-align: center">
              <h5>{{ articuloNombre }}</h5>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Cantidad"
              min="1"
              rounded
              @keypress="isNumber($event)"
              type="number"
              v-model="articuloCantidad"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Precio"
              min="0"
              rounded
              outlined
              readonly
              @keypress="isNumber($event)"
              prefix="$"
              v-model="articuloPrecio"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn rounded @click="guardar()" block color="primary">Guardar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    articulo: {
      type: Object,
      default: () => {},
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      articuloNombre: "",
      articuloCantidad: 1,
      articuloPrecio: 0.0,
    };
  },
  methods: {
    limpiarArticulo() {
      this.$emit("cerrado");
    },
    guardar() {
      const obj = {
        cantidad: this.articuloCantidad,
        precio: this.articuloPrecio,
        ...this.articulo,
      };
      this.$emit("guardar", obj);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        const result = parseInt(evt.target.value);
        if (result < 0) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    articulo: {
      handler() {
        if (this.articulo != {}) {
          this.articuloCantidad = 1;
          this.articuloNombre = this.articulo.strNombre;
          this.articuloPrecio = this.articulo.dblPU;
        } else {
          this.articuloCantidad = 1;
          this.articuloNombre = "";
          this.articuloPrecio = 0;
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
