<template>
  <UI :entity="entity">
    <template v-slot:main="{ entity }">
      <Search
        @selected="selectItem"
        :headers="headers"
        :rows="entities"
        v-model="search"
      />
      <Loader v-model="loading" />
      <v-container fluid>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-card-title dark> Motivo Descuento </v-card-title>
            <v-btn icon dark @click="fillData()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon dark @click="nuevo()">
              <v-icon>mdi-new-box</v-icon>
            </v-btn>
            <v-btn icon dark @click="guardar()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon dark @click="eliminar()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col md="2">
                  <v-text-field
                    outlined
                    label="Folio"
                    v-model="entity.intFolio"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="Clave"
                    v-model="entity.strNombreCorto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strNombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </UI>
</template>

<script>
import { POSMotivoDescuento, fillObject } from "../../controllers/AdminController";
import UI from "../../components/AdminUI.vue";
import Search from "../../components/SearchComponent.vue";
import Loader from "../../components/Loader.vue";
import { Toast, ErrorWeb } from "../../controllers/DataController.js";

export default {
  data() {
    return {
      search: {
        visible: false,
      },
      loading: false,
      entities: [],
      headers: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
      ],
      entity: new POSMotivoDescuento(),
    };
  },
  mounted() {
    var vm = this;
    window.addEventListener("keydown", async function (event) {
      // NOTE: metaKey == Command on Mac
      if ((event.metaKey || event.ctrlKey) && event.key == "g") {
        event.preventDefault();
        await vm.guardar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "e") {
        event.preventDefault();
        await vm.eliminar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "q") {
        event.preventDefault();
        vm.nuevo();
      }
    });
  },
  methods: {
    nuevo() {
      this.entity = new POSMotivoDescuento();
    },
    async eliminar() {
      this.loading = true;
      try {
        await this.entity.delete();
        this.$toast.success("Registro eliminado", new Toast());
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        if (this.entity.intPOSMotivoDescuento == 0) {
          await this.entity.save();
          this.$toast.success("Registro agregado con exito", new Toast());
        } else {
          await this.entity.update();
          this.$toast.success("Registro agregado con exito", new Toast());
        }
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    selectItem(item) {
      this.search.visible = false;
      this.entity = fillObject(this.entity, item);
    },
    async fillData() {
      this.loading = true;
      try {
        this.entities = await this.entity.get();
        this.search.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UI,
    Loader,
    Search,
  },
};
</script>

<style></style>
