<template>
  <v-container fluid>
    <Loader v-model="loading" />
    <ReservasActivas
      v-model="reservas"
      :reservas="reservasActivas"
      @reservacion="seleccionaReservacion"
    />
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="localValue.strHabitacion"
          rounded
          readonly
          label="Habitacion"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="localValue.strApellidoReferencia"
          rounded
          readonly
          label="Nombre Reservacion"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-btn :disabled="disabled" block color="primary" rounded @click="verReservas"
          >BUSCAR RESERVACION</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ReservasActivas from "../components/ReservasActivasComponent.vue";
import { Session } from "../controllers/DataController.js";
import Loader from "../components/Loader.vue";
import { POSEncPagoFunctions } from "../controllers/POSController.js";
export default {
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reservasActivas: [],
      session: new Session(),
      reservas: {
        visible: false,
      },
      loading: false,
      localValue: this.value,
      pagoFunctions: new POSEncPagoFunctions(this.$config.api_backend_route),
    };
  },
  components: {
    ReservasActivas,
    Loader,
  },
  methods: {
    seleccionaReservacion(reserva) {
      this.localValue = {
        intHotReservacion: reserva.intHotReservacion,
        strApellidoReferencia: reserva.strApellidoReferencia,
        strHabitacion: reserva.strHabitacion,
      };
    },
    async verReservas() {
      this.loading = true;
      try {
        this.reservasActivas = await this.pagoFunctions.getReservas(this.session.empresa);
        this.reservas.visible = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style></style>
