<template>
  <v-stepper v-model="current" dark>
    <v-stepper-header v-if="!localValue[0].padre">
      <template v-for="n in steps">
        <v-stepper-step :key="n.toString() + '-step'" :complete="current > n" :step="n">
          {{ localValue[n - 1].strNombre }}
          <small v-if="another_stepper(localValue[n - 1])">PASO REQUERIDO</small>
          <small v-else-if="localValue[n - 1].intCantidadSeleccionable > 0"
            >SELECCIONE {{ localValue[n - 1].intCantidadSeleccionable }}</small
          >
          <small v-else>OPCIONAL</small>
        </v-stepper-step>
        <v-divider v-if="n !== steps" :key="n"></v-divider>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content v-for="n in steps" :key="n.toString() + '-content'" :step="n">
        <v-container fluid v-if="!another_stepper(localValue[n - 1])">
          <v-row>
            <v-col
              md="2"
              :key="hijo.intArticuloConfiguration"
              v-for="hijo in localValue[n - 1].hijos"
            >
              <v-card
                min-height="100%"
                :key="hijo.intArticuloConfiguration"
                @click="selected(n, hijo)"
                :color="hijo.color"
                light
              >
                <v-img :src="hijo.strImagen" height="80px"></v-img>
                <v-card-title>
                  <h6>
                    {{ hijo.strNombreCorto + " - " + isMoney(hijo.dblPrecio) }}
                  </h6>
                </v-card-title>
                <v-card-subtitle>{{ hijo.strNombre }}</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-col>
            <v-btn
              md="2"
              :key="hijo.intArticuloConfiguration"
              v-for="hijo in localValue[n - 1].hijos.filter((x) => x.selected)"
              @click="eliminar(hijo)"
            >
            ({{ hijo.dblCantidad }}) {{ hijo.strNombre }}
            
          </v-btn>
        </v-col>
          
        </v-container>
        <v-container fluid v-else>
          <ArticuloStepper
            @finishChild="finishChild"
            @nextchild="nextChild"
            v-model="localValue[n - 1].hijos"
            :child="true"
            :childstep="n"
            :steps="getSteps(localValue[n - 1])"
          >
          </ArticuloStepper>
        </v-container>
        <v-row v-if="!another_stepper(localValue[n - 1])">
          <v-col v-if="current > 1">
            <v-btn block color="secondary" @click="backStep(n)">Atras</v-btn>
          </v-col>
          <v-col v-if="current < steps">
            <v-btn
              block
              color="primary"
              :disabled="
                !(
                  localValue[n - 1].intCantidadSeleccionable ==
                  localValue[n - 1].hijos.filter((x) => x.dblCantidad >0).reduce((a, b) => {
                      return a + b.dblCantidad; }, 0)
                    ||
                  localValue[n - 1].intCantidadSeleccionable == 0
                )
              "
              @click="nextStep(n)"
              >Siguiente</v-btn
            >
          </v-col>
          <v-col v-if="current == steps">
            <v-btn
              block
              color="success"
              :disabled="
                !(
                  localValue[n - 1].intCantidadSeleccionable ==
                  localValue[n - 1].hijos.filter((x) => x.dblCantidad >0).reduce((a, b) => {
                      return a + b.dblCantidad; }, 0)
                    ||
                  localValue[n - 1].intCantidadSeleccionable == 0
                )
              "
              @click="finish(n)"
              >Terminar</v-btn
            >
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { Toast } from "../controllers/DataController.js";
import { isMoney } from "../js/utilities";
import { POSDetArticuloConfiguracion } from "../controllers/POSController";

export default {
  name: "ArticuloStepper",
  expose: ['reload'],
  props: {
    value: {
      required: true,
    },
    steps: {
      type: Number,
      default: 0,
    },
    child: {
      type: Boolean,
      default: false,
    },
    childstep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localValue: this.value,
      current: 1,
      configuracionesTemporal: [],
      configuracionestemp: null,
    };
  },
  methods: { 
    isMoney: isMoney,
    another_stepper(configuration) {
      //* Buscar si tiene hijos con pasos
      return configuration.hijos.filter((hijo) => hijo.intPaso == 1).length > 0;
    },
    cerrar() {
      this.current = 1;
    },
    eliminar(hijo){
     hijo.dblCantidad=hijo.dblCantidad - 1;
     console.log(hijo.dblCantidad)
     this.$forceUpdate();

    if(hijo.dblCantidad ==0){
      hijo.color = "#ffffff";
      hijo.selected = false;
      hijo.select == false;
      this.$forceUpdate();
    }
      // let seleccionado = this.localValue[step - 1].hijos.filter((x) => x.intArticuloConfiguracion==hijo.intArticuloConfiguracion);
    
   },
    selected(step, hijo) {
      try {
        console.log(hijo.select)
        if (hijo.select == null || hijo.select == false) {
          if (this.localValue[step - 1].intCantidadSeleccionable > 0) {
           let seleccionados = this.localValue[step - 1].hijos.filter((x) => x.dblCantidad >0).reduce((a, b) => {return a + b.dblCantidad; }, 0);
           if (this.localValue[step - 1].intCantidadSeleccionable == seleccionados) 
            {
              throw (
                "Solo puede seleccionar " +
                this.localValue[step - 1].intCantidadSeleccionable
              );
            }
          }
          
          hijo.color = "primary";
          hijo.selected = true;
          hijo.dblCantidad=hijo.dblCantidad + 1;
          this.$forceUpdate();
        } else {
          hijo.color = "#ffffff";
          hijo.selected = false;
          hijo.dblCantidad=hijo.dblCantidad - 1;
          this.$forceUpdate();
        }

      } catch (e) {
        this.$toast.error(
          "Error en " + this.localValue[step - 1].strNombre + ". " + e,
          new Toast()
        );
      }
    },
    selecteds(step, hijo) {
      try {
        if (hijo.selected == null || hijo.selected == false) {
          if (this.localValue[step - 1].intCantidadSeleccionable > 0) {
            let seleccionados = this.localValue[step - 1].hijos.filter((x) => x.selected);
            if (
              this.localValue[step - 1].intCantidadSeleccionable == seleccionados.length
            ) {
              throw (
                "Solo puede seleccionar " +
                this.localValue[step - 1].intCantidadSeleccionable
              );
            }
          }
          hijo.color = "primary";
          hijo.selected = true;
          hijo.selectedCant=hijo.selected;
          this.$forceUpdate();
        } else {
          hijo.color = "#ffffff";
          hijo.selected = false;
          this.$forceUpdate();
        }
      } catch (e) {
        this.$toast.error(
          "Error en " + this.localValue[step - 1].strNombre + ". " + e,
          new Toast()
        );
      }
    },
    finish() {
      let POSDetArticuloConfiguraciones = [];
     
        this.localValue.forEach((configuraciones) => {
        //* Solo llenamos opciones seleccionadas
        let selected = configuraciones.hijos.filter((x) => x.selected);
        selected.forEach((x, index) => {
          POSDetArticuloConfiguraciones.push(new POSDetArticuloConfiguracion(x, index));
        });
      });
      this.current = 1;
      if (this.child && this.current != this.steps) {
        console.log(POSDetArticuloConfiguraciones)
        this.$emit("nextchild", POSDetArticuloConfiguraciones);
      } else { 
        console.log(POSDetArticuloConfiguraciones)
        this.$emit("finish", POSDetArticuloConfiguraciones);
      }
    },
    finishChild(POSDetArticuloConfiguraciones) {
      console.log(POSDetArticuloConfiguraciones)
      this.$emit("finish", POSDetArticuloConfiguraciones);
    },
    nextChild(POSDetArticuloConfiguraciones) {
      POSDetArticuloConfiguraciones.forEach((x) => {
        this.configuracionesTemporal.push(x);
      });

      if (this.current == this.steps) {
        let response = [];
        this.configuracionesTemporal.forEach((x) => {
          response.push({ ...x });
        });
        this.configuracionesTemporal = [];
        if (this.child) {
          this.$emit("finishChild", response);
        } else {
          this.$emit("finish", response);
        }
      } else {
        this.current = this.current + 1;
      }
    },
    backStep(n) {
      this.current = n - 1; 
    },
    nextStep(n) {
      this.current = n === this.steps ? 1 : n + 1;
    },
    getSteps(configuration) {
      return configuration.hijos.length;
    },
    reload(value) {
      console.log(value);
    //  alert('Reloading');
      this.localValue.forEach((padre) => {
          if(padre.selected == true){
            padre.padre = true;
            padre.selected = true;
            padre.select = false;
            padre.color = "primary";
          }
          else{
            padre.selected = false;
            padre.select = false;
            padre.dblCantidad=0;
          }
          padre.hijos.forEach((hijo) => {
            if(hijo.selected == true){
              hijo.selected = true;
              hijo.select = false;
              hijo.color = "primary";
            }
            else{
              hijo.selected = false;
              hijo.select = false;
              hijo.dblCantidad=0;
            }
            hijo.hijos.forEach((nieto) => {
                if(nieto.selected == true){
                  nieto.selected = true;
                  nieto.select = false;
                  nieto.color = "primary";
                }
                else
                {
                  nieto.selected = false;
                  nieto.select = false;
                  nieto.dblCantidad=0;
                }
                if (nieto.hijos) {
                  nieto.hijos.forEach((bisnieto) => {
                    if(bisnieto.selected == true){
                      bisnieto.selected = true;
                      bisnieto.select = false;
                      bisnieto.color = "primary";
                    }
                    else
                    {
                      bisnieto.selected = false;
                      bisnieto.select = false;
                      bisnieto.dblCantidad=0;
                    }
                  });
                }
            });
          });
        });
             
   ///   console.log(JSON.stringify(this.localValue));
      // this.localValue.forEach(element => {
      //   if(element.selected == true){
      //     element.select = true;
      //     element.selected = true;
      //     element.color = "primary";
      //   }
      //   else{
      //     element.dblCantidad=0;
      //   }
      //   if(element.hijos) {
      //     element.hijos.forEach(hijo => {
      //       if(hijo.selected == true){
      //         hijo.select = true;
      //         hijo.selected = true;
      //         hijo.color = "primary";
      //       }
      //       else{
      //         hijo.dblCantidad=0;
      //       }
      //       if (hijo.hijos) {
      //         hijo.hijos.forEach(nieto => {
      //           if(nieto.selected == true){
      //             nieto.select = true;
      //             nieto.selected = true;
      //             nieto.color = "primary";
      //           }
      //           else{
      //             nieto.dblCantidad=0;
      //           }
      //           if (nieto.hijos) {
      //             nieto.hijos.forEach((bisnieto) => {
      //               if(bisnieto.selected == true){
      //                 bisnieto.select = true;
      //                 bisnieto.selected = true;
      //                 bisnieto.color = "primary";
      //               }
      //               else{
      //                 bisnieto.dblCantidad=0;
      //               }
      //             });
      //           }
      //         });
      //       }
      //     });
      //   }
      // });
    
    },
  },
  watch: {
    steps(val) {
      if (this.current > val) this.current = val;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    //  console.log( this.localValue) 
    },
  },
  mounted(){
    //console.log(this.localValue,"entro");
    

  },
};
</script>

<style></style>
