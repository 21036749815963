<template>
  <UI :entity="entity">
    <template v-slot:main="{ entity }">
      <Loader v-model="loading" />
      <Search
        @selected="selectArticulo"
        :headers="headersArticulo"
        :rows="entitiesArticulo"
        v-model="searchArticulo"
      />

      <v-container fluid>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-card-title dark v-if="parenting">
              Crear hijo de {{ parent.strNombre }}</v-card-title
            >
            <v-card-title dark v-else>
              Configuracion de {{ entity.strNombre }}</v-card-title
            >
            <v-btn icon dark @click="guardar()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon dark @click="eliminar()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col md="2">
                  <v-text-field
                    outlined
                    label="Clave"
                    ref="clave"
                    v-model="entity.strNombreCorto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    ref="nombre"
                    v-model="entity.strNombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <v-text-field
                    outlined
                    prepend-icon="mdi-magnify"
                    @click:prepend="buscarArticulo"
                    label="Clave Articulo"
                    readonly
                    v-model="entity.strNombreCortoArticulo"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strNombreArticulo"
                    label="Nombre Articulo"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <v-text-field
                    outlined
                    v-model="entity.intOrden"
                    label="Orden"
                    type="number"
                    min="1"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intExtra"
                    label="Extra"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intMultiple"
                    label="Multiple"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    :disabled="entity.intMultiple == 1"
                    v-model="entity.intCantidadSeleccionable"
                    label="Cantidad Seleccionable"
                    type="number"
                    min="0"
                    ref="cantidad"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </UI>
</template>

<script>
import {
  Articulo,
  ArticuloConfiguracion,
  fillObject,
} from "../../controllers/AdminController";
import UI from "../../components/AdminUI.vue";
import Search from "../../components/SearchComponent.vue";
import Loader from "../../components/Loader.vue";
import { Toast, ErrorWeb } from "../../controllers/DataController.js";
import { isNumber } from "../../js/utilities";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchArticulo: {
        visible: false,
      },
      entitiesArticulo: [],
      headersArticulo: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
      ],
      articulo: new Articulo(),
      loading: false,
      entity: new ArticuloConfiguracion(),
      parent: new ArticuloConfiguracion(),
    };
  },
  computed: {
    parenting: function () {
      return this.$route.query.parenting;
    },
  },
  watch: {
    ["entity.intMultiple"](newValue) {
      if (newValue == 1) {
        this.entity.intCantidadSeleccionable = 0;
      }
    },
  },
  mounted() {
    var vm = this;
    window.addEventListener("keydown", async function (event) {
      // NOTE: metaKey == Command on Mac
      if ((event.metaKey || event.ctrlKey) && event.key == "g") {
        event.preventDefault();
        await vm.guardar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "e") {
        event.preventDefault();
        await vm.eliminar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "q") {
        event.preventDefault();
        vm.nuevo();
      }
    });
  },
  async created() {
    if (this.id && !this.parenting) {
      this.entity.intArticuloConfiguracion = this.id;
      let item = await this.entity.retrieve();
      await this.selectItem(item);
    } else if (this.parenting) {
      this.parent.intArticuloConfiguracion = this.id;
      let item = await this.parent.retrieve();
      this.parent = fillObject(this.parent, item);
      this.entity.intArticuloConfiguracionPadre = this.parent.intArticuloConfiguracion;
      this.entity.intArticuloConfiguracionMaster = this.parent.intArticuloConfiguracionMaster;
      this.entity.intOrden = await this.entity.orden();
    }
  },
  methods: {
    isNumber: isNumber,
    async buscarArticulo() {
      this.loading = true;
      try {
        this.entitiesArticulo = await this.articulo.get();
        this.searchArticulo.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    selectArticulo(item) {
      this.searchArticulo.visible = false;
      this.entity.strNombreArticulo = item.strNombre;
      this.entity.strNombreCortoArticulo = item.strNombreCorto;
      this.entity.intArticulo = item.intArticulo;
      this.entity.strNombre = this.entity.strNombreArticulo;
      this.entity.strNombreCorto = this.entity.strNombreCortoArticulo;
    },
    async eliminar() {
      this.loading = true;
      try {
        await this.entity.delete();
        this.$toast.success("Registro eliminado", new Toast());
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        // if (this.entity.intMultiple == 0 && this.entity.intCantidadSeleccionable == 0) {
        //   this.$refs.cantidad.focus();
        //   throw "Si la configuracion NO es multiple, necesita agregar una cantidad seleccionable";
        // }
        if (this.entity.strNombre == "") {
          this.$refs.nombre.focus();
          throw "Favor de agregar Nombre";
        }
        if (this.entity.strNombreCorto == "") {
          this.$refs.clave.focus();
          throw "Favor de agregar Clave";
        }

        if (this.entity.intArticuloConfiguracion == 0) {
          this.entity.intArticuloConfiguracion = (
            await this.entity.save()
          ).intArticuloConfiguracion;
          this.$toast.success("Registro agregado con exito", new Toast());
        } else {
          await this.entity.update();
          this.$toast.success("Registro agregado con exito", new Toast());
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async selectItem(item) {
      this.loading = true;
      try {
        this.entity = fillObject(this.entity, item);
        if (this.entity.intArticulo > 0) {
          this.articulo.intArticulo = this.entity.intArticulo;
          let articulo = await this.articulo.retrieve();
          this.entity.strNombreArticulo = articulo.strNombre;
          this.entity.strNombreCortoArticulo = articulo.strNombreCorto;
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async fillData() {
      this.loading = true;
      try {
        this.entities = await this.entity.get();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UI,
    Loader,
    Search,
  },
};
</script>

<style></style>
