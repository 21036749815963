<template>
  <v-dialog v-model="localValue.visible">
    <Loader v-model="loading" />
    <PrinterDialog @log="eliminarRegistro" v-model="printer" :impresoras="impresoras" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Listado de Impresiones</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="results">
              <template v-slot:[`item.strUsuarioAlta`]="{ item }">
                {{ item.strUsuarioAlta.toUpperCase() }}
              </template>
              <template v-slot:[`item.datFechaAlta`]="{ item }">
                {{ toDateString(item.datFechaAlta, 2) }}
              </template>
              <template v-slot:[`item.intImpreso`]="{ item }">
                <v-icon @click="imprimir(item)"> mdi-printer </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Session, ErrorWeb, Dialog, Toast } from "../controllers/DataController";
import { POSLogImpresion, POSEncFunctions } from "../controllers/POSController";
import { toDateString } from "../js/utilities";

import Loader from "./Loader.vue";
import PrinterDialog from "./PrinterDialogComponent.vue";
export default {
  props: {
    POSInfo: {
      type: Object,
      default: () => {},
    },
    terminal: {
      type: Object,
      default: () => {},
    },
    value: {
      required: true,
    },
    impresoras: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      results: [],
      headers: [
        { text: "#", value: "intPOSLogImpresion" },
        { text: "Tipo", value: "strTipoTicket" },
        { text: "Version", value: "strVersion" },
        { text: "Descripcion", value: "strDescripcion" },
        { text: "Error", value: "strError" },
        { text: "Usuario", value: "strUsuarioAlta" },
        { text: "Maquina", value: "strMaquinaAlta" },
        { text: "Fecha", value: "datFechaAlta" },
        { text: "", value: "intImpreso" },
      ],
      printer: new Dialog(0, 0, "", "", ""),

      loading: false,
      localValue: this.value,
      session: new Session(),
      log: new POSLogImpresion(new Session(), this.$config.api_backend_route),
      ordenFunctions: new POSEncFunctions(this.$config.api_backend_route),
    };
  },
  methods: {
    toDateString: toDateString,
    convertDateJSON(POSEnc) {
      if (POSEnc.datFecha.toString().includes("T")) {
        return POSEnc.datFecha;
      } else {
        let x = POSEnc.datFecha.split(" ");
        let y = x[0].split("/");
        let response = y[2] + "-" + y[1] + "-" + y[0] + "T" + x[1] + ".511Z";
        return response;
      }
    },
    eliminarRegistro(id) {
      this.results = this.results.filter((x) => {
        return x.intPOSLogImpresion != id;
      });
    },
    async imprimir(item) {
      if (item.strTipoTicket == "Apertura") {
        await this.printOrden(item, item.intPOSEnc, 1);
      } else if (item.strTipoTicket == "Arqueo") {
        await this.printOrden(item, item.intPOSEnc, 1);
      } else if (item.strTipoTicket == "Preparacion") {
        await this.printOrden(item, item.intPOSEnc, 1);
      } else if (item.strTipoTicket == "Pago") {
        await this.printOrden(item, item.intPOSEnc, 2);
      } else if (item.strTipoTicket == "Cierre") {
        await this.printOrden(item, item.intPOSEnc, 2);
      }
    },
    async printOrden(item, intPOSEnc, version) {
      this.loading = true;
      try {
        let posenc = await this.ordenFunctions.retrieve_id(
          this.session.empresa,
          intPOSEnc
        );
        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        this.printer.event = version == 1 ? "preparacion" : "pago";
        this.printer.visible = true;
        this.printer.log = item;
        posenc.datFecha = this.convertDateJSON(posenc);
        this.printer.printable = posenc;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    Loader,
    PrinterDialog,
  },
  watch: {
    async ["localValue.visible"](newValue) {
      if (newValue) {
        this.loading = true;
        try {
          this.results = await this.log.Data();
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
