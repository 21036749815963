import axios from 'axios';
import { Session } from './DataController';

class POSRegister {
    constructor() {
        this.intPOSRegister= 0;
        this.strUsuarioPOS= "";
        this.strComentario= "";
        this.dblImporte= 0.00;
        this.intPOSTipoMovimientoTurno= 0;
        this.dblDenominacion1000= 0;
        this.dblDenominacion500= 0;
        this.dblDenominacion200= 0;
        this.dblDenominacion100= 0;
        this.dblDenominacion50= 0;
        this.dblDenominacion20= 0;
        this.dblMonedas= 0.00;
        this.intPOSRegisterPadre= 0;
        this.dblOtrosPagos= 0.00;
        this.intVersion= 1;
        this.intIntentos= 0;
        this.dblImporteEntregado= 0;
        this.dblDiferencia= 0;
        this.dblTotal= 0.00;
    }
}

class POSRegisterFunctions {
    async saveMeseros(meseros){
        const session = new Session();

        const result = await axios.post("POS_GuardarMeseros", meseros, {
            headers: session
        });
        return result.data;
    }
    async save(register){
        const session = new Session();

        const posregister = await axios.post("POS_SaveRegister", register, {
            headers: session
        });
        return posregister.data;
    }
    async pagos(){
        const session = new Session();

        const posregister = await axios.get("POS_GetSumaPagos", {
            headers: session
        });
        return posregister.data;
    }
    async pagosGlobal(){
        const session = new Session();

        const posregister = await axios.get("POS_GetSumaPagosGlobal",{
            headers: session
        });
        return posregister.data;
    }
    async ventas(){
        const session = new Session();

        const posregister = await axios.get("POS_GetSumaVentas", {
            headers: session
        });
        return posregister.data;
    }
    async retrieve(){
        const session = new Session();

        const response = await axios.get("POS_DataRegister", {
            headers: session
        })
        return response.data;
    }
    async cierre(){
        const session = new Session();

        const response = await axios.get("POS_DataCierre", {
            headers: session
        })
        return response.data[0];
    }
    async corteGlobal(intPOSregister,datFecha){
        const session = new Session();

        const response = await axios.get("POS_DataCorteGlobal/"+ intPOSregister+"/"+datFecha, {
            headers: session
        })
        return response.data[0];
    }
    async register_actual(intEmpresa, idm){
        const session = new Session();

        const posregister = await axios.get("POS_RegisterActual/" + intEmpresa + "/" + idm, {
            headers: session
        });
        return posregister.data;
    }
    async register_actualUsuario(intEmpresa, idm,usuario){
        const session = new Session();

        const posregister = await axios.get("POS_RegisterActualUsuario/" + intEmpresa + "/" + idm+ "/"+ usuario,{
            headers: session
        });
        return posregister.data;
    }
    async generarPoliza(intPOSRegister, usuario){
        const session = new Session();

        const posregister = await axios.post("POS_GenearPoliza/" + intPOSRegister + "/" + usuario, {
            headers: session
        });
        return posregister.data;
    }
    async polizaAutomaticaMP(intSucursal, fecha){
        const session = new Session();
        const response = await axios.post("POS_GenerarPolizaMP/" + intSucursal + "/" + fecha, {
            headers: session
        })
        return response.data;
    }
    async generarPoliza_Terminal(intEmpresa,intSucursal, fecha){
        const session = new Session();
        const response = await axios.post("POS_GenerarPolizaTerminal/" + intEmpresa + "/" +  intSucursal + "/" + fecha, {
            headers: session
        })
        return response.data;
    }
    async folioArqueo(){
        const session = new Session();

        const response = await axios.get("POS_FolioArqueo", {
            headers: session
        })
        return response.data;
    }

    async salidas(){
        const session = new Session();

        const posregister = await axios.get("POS_GetSalidasCaja", {
            headers: session
        });
        return posregister.data;
    }
}

export { POSRegister, POSRegisterFunctions };