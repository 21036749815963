import axios from 'axios';
import Cookies from "js-cookie";
export class Session {
    constructor() {
        this.admin_empresa = Cookies.get('ADMIN_EMPRESA') ? parseInt(Cookies.get('ADMIN_EMPRESA')) : 0;
        this.admin_sucursal = Cookies.get('ADMIN_SUCURSAL') ? parseInt(Cookies.get('ADMIN_SUCURSAL')) : 0;
        this.admin_usuario = Cookies.get('ADMIN_USER');
        this.admin_sucursal_nombre = Cookies.get('ADMIN_NOMBRE_SUCURSAL');
        this.admin_empresa_nombre = Cookies.get('ADMIN_NOMBRE_EMPRESA');
    }

    logout() {
        Cookies.remove('ADMIN_EMPRESA');
        Cookies.remove('ADMIN_SUCURSAL');
        Cookies.remove('ADMIN_USER');
    }

    validate() {
        if (this.admin_empresa == 0 || this.admin_sucursal == 0 || this.admin_usuario == "" || this.admin_usuario == null) {
            throw "Error en session, parece ser que no ha iniciado session o la sesion caduco."
        }
    }
}



export function fillObject(object1, object2) {
    Object.keys(object1).forEach(key => {
        if (key != "controller" && key != "view") {
            object1[key] = object2[key];
        }
    })
    return object1;
}

export class Familia {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intFamilia = null;
        this.strNombre = "";
        this.strNombreCorto = "";
        this.strMaquinaAlta = "";
        this.strUsuarioAlta = "";
        this.datFechaAlta = null;
        this.strMaquinaMod = "";
        this.strUsuarioMod = "";
        this.datFechaMod = null;
        this.intAlmacen = 0;
        this.intSubAlmacen = 0;
        this.strColor = "";
        this.intOrdenImpresion = 0;
        this.intActivo = 0;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/Familia", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/Familia/" + this.intFamilia, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/Familia", this);
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/Familia/" + this.intFamilia, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/Familia/" + this.intFamilia, {
            headers: session
        });
        return response.data;
    }
}

export class POSMotivoCancelacion {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intPOSMotivoCancelacion = 0;
        this.intFolio = 0;
        this.strNombre = "";
        this.strNombreCorto = "";
        this.strUsuarioAlta = "";
        this.strMaquinaAlta = "";
        this.datFechaAlta = null;
        this.strUsuarioMod = "";
        this.strMaquinaMod = "";
        this.datFechaMod = null;
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoCancelacion/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoCancelacion", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoCancelacion/" + this.intPOSMotivoCancelacion, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/POSMotivoCancelacion", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/POSMotivoCancelacion/" + this.intPOSMotivoCancelacion, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/POSMotivoCancelacion/" + this.intPOSMotivoCancelacion, {
            headers: session
        });
        return response.data;
    }
}

export class POSMotivoDescuento {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intPOSMotivoDescuento = 0;
        this.intFolio = 0;
        this.strNombre = "";
        this.strNombreCorto = "";
        this.strUsuarioAlta = "";
        this.strMaquinaAlta = "";
        this.datFechaAlta = null;
        this.strUsuarioMod = "";
        this.strMaquinaMod = "";
        this.datFechaMod = null;
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoDescuento/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoDescuento", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMotivoDescuento/" + this.intPOSMotivoDescuento, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/POSMotivoDescuento", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/POSMotivoDescuento/" + this.intPOSMotivoDescuento, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/POSMotivoDescuento/" + this.intPOSMotivoDescuento, {
            headers: session
        });
        return response.data;
    }
}

export class TipoPago {
    constructor() {
        this.intTipoPago = 0;
        this.strNombre = "";
        this.intRequiereBanco = 0;
        this.intActivo = 0;
        this.intPOSRequiereFirma = 0;
        this.strSATCodigo = "";
        this.intPOSReferencia = 0;
        this.strPerfilesAutorizacion = "";
        this.intCuentaContable = 0;
        this.strImagen = "";
        this.intPOS = 0;
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/TipoPago/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/TipoPago", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/TipoPago/" + this.intTipoPago, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/TipoPago", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/TipoPago/" + this.intTipoPago, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/TipoPago/" + this.intTipoPago, {
            headers: session
        });
        return response.data;
    }
}

export class Articulo {
    constructor() {
        this.intArticulo = 0;
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intFamilia = 0;
        this.intGrupo = 0;
        this.intSubGrupo = 0;
        this.strNombre = "";
        this.strNombreCorto = "";
        this.intUnidadMedidaVenta = 0;
        this.intUnidadMedidaAlmacenamiento = 0;
        this.intUnidadMedidaCompra = 0;
        this.dblCosto = 0.00;
        this.dblMinimo = 0.00;
        this.dblMaximo = 0.00;
        this.dblExistencia = 0.00;
        this.dblVenta = 0.00;
        this.strUsuarioAlta = "";
        this.strMaquinaAlta = "";
        this.datFechaAlta = null;
        this.strUsuarioMod = "";
        this.strMaquinaMod = "";
        this.datFechaMod = null;
        this.strImagen = "";
        this.strUsuarioElaboro = "";
        this.strUsuarioReviso = "";
        this.strUsuarioAutorizo = "";
        this.intMateriaPrima = 0;
        this.intFacturable = 0;
        this.intAutorizado = 0;
        this.strDescripcion = "";
        this.dblPeso = 0.00;
        this.intRutaProduccion = 0;
        this.dblPlanta = 0.00;
        this.dblPorcentajeIVA = 0.00;
        this.strEmpresas = "";
        this.dblFactorConversionV_A = 0.00;
        this.dblFactorConversionA_C = 0.00;
        this.dblTolerancia = 0.00;
        this.dblGalvanizado = 0.00;
        this.dblFlete = 0.00;
        this.strCodigoBarra = "";
        this.dblCostoVenta = 0.00;
        this.intDescontinuado = 0;
        this.intInventariable = 0;
        this.intArticuloClasificacionMP = 0;
        this.intArticuloClasificacionPT = 0;
        this.intArticuloLinea = 0;
        this.dblPrecioProveedor1 = 0.00;
        this.dblPrecioProveedor2 = 0.00;
        this.dblPrecioProveedor3 = 0.00;
        this.dblPrecioProveedor4 = 0.00;
        this.dblPorcentajeCompraP1 = 0.00;
        this.dblPorcentajeCompraP2 = 0.00;
        this.dblPorcentajeCompraP3 = 0.00;
        this.dblPorcentajeCompraP4 = 0.00;
        this.intMaestro = 0;
        this.intMoneda = 0;
        this.dblFactorMenudeo = 0.00;
        this.intConceptoCXP = 0;
        this.intAFXCategoriaActivo = 0;
        this.dblPorcentajeIEPS = 0.00;
        this.intCuentaContable = 0;
        this.strImagen2 = "";
        this.strImagen3 = "";
        this.strImagen4 = "";
        this.strImagen5 = "";
        this.intRequiereAjusteMensual = 0;
        this.intPOSImpresora = 0;
        this.intCentroCosto = 0;
        this.intCuentaContableAnticipo = 0;
        this.intCuentaContableControl1 = 0;
        this.intCuentaContableControl2 = 0;
        this.dblComision = 0.00;
        this.dblPorcentajeComision = 0.00;
        this.intArticuloConfiguracion = 0;
        this.intPOS = 0;
        this.intPOSPromocion = 0;
        this.dblPorcentajeInteresDiario = 0.00;
        this.strSATCodigo = "";
        this.dblPorcentajeISH = 0.00;
        this.intConceptoCXPIngreso = 0;
        this.dblTipoCambioCostoVenta = 0.00;
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/Articulo/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/Articulo", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/Articulo/" + this.intArticulo, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/Articulo", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/Articulo/" + this.intArticulo, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/Articulo/" + this.intArticulo, {
            headers: session
        });
        return response.data;
    }
}



export class User {
    constructor() {
        this.usuario = "",
        this.password = "",
        this.empresa = 0;
        this.sucursal = 0;
    }

    async empresas() {
        const response = await axios.get("Admin/User/empresas/" + this.usuario);
        return response.data;
    }

    async sucursales(empresa) {
        const response = await axios.get("Admin/User/sucursales/" + empresa + "/" + this.usuario);
        return response.data;
    }

    async login() {
        const response = await axios.post("Admin/User/login", this);
        return response.data;
    }

    setSession(sucursal, empresa) {
        //Session de 12 horas
        Cookies.set('ADMIN_USER', this.usuario.toUpperCase(), {
            expires: 0.5
        });
        Cookies.set('ADMIN_SUCURSAL', this.sucursal, {
            expires: 0.5
        });
        Cookies.set('ADMIN_EMPRESA', this.empresa, {
            expires: 0.5
        });
        Cookies.set('ADMIN_NOMBRE_SUCURSAL', sucursal, {
            expires: 0.5
        });
        Cookies.set('ADMIN_NOMBRE_EMPRESA', empresa, {
            expires: 0.5
        });
    }
}

export class POSMesero {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intPOSMesero = 0;
        this.intFolio = 0;
        this.strNombre = "";
        this.strApellidoPaterno = "";
        this.strApellidoMaterno = "";
        this.intUsuario = 0;
        this.intActivo = 0;
        this.strUsuarioAlta = "";
        this.strMaquinaAlta = "";
        this.datFechaAlta = null;
        this.strUsuarioMod = "";
        this.strMaquinaMod = "";
        this.datFechaMod = null;
        this.strPOSTerminales = "";
        this.intCajero = 0;
        this.intCancelacion = 0;
        this.intDescuento = 0;
        this.strUsuario = "";
        this.strNIP = "";
        this.terminales = [];
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero", {
            headers: session
        });
        return response.data;
    }

    async users() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero/users", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero/" + this.intPOSMesero, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/POSMesero", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/POSMesero/" + this.intPOSMesero, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/POSMesero/" + this.intPOSMesero, {
            headers: session
        });
        return response.data;
    }

    async username() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero/username/" + this.intUsuario, {
            headers: session
        });
        return response.data;
    }
    async getTerminales() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSMesero/terminales/" + this.intPOSMesero, {
            headers: session
        });
        return response.data;
    }
}

export class POSTerminal {
    constructor() {
       this.intEmpresa = 0;
       this.intSucursal = 0;
       this.intPOSTerminal = 0;
       this.intFolio = 0;
       this.strNombre = "";
       this.strNombreCorto = "";
       this.intPOSImpresora = 0;
       this.strIdTerminal = "";
       this.strUsuarioAlta = "";
       this.strMaquinaAlta = "";
       this.datFechaAlta = null;
       this.strUsuarioMod = "";
       this.strMaquinaMod = "";
       this.datFechaMod = null;
       this.strFamilias = "";
       this.strTerminalesRelacion = "";
       this.intRequiereEwise = 0;
       this.strAlmacen = "";
       this.strSubAlmacen = "";
       this.strCorreo = "";
       this.strDireccion = "";
       this.strTelefono = "";
       this.strRFC = "";
       this.strNombreLegal = "";
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSTerminal/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSTerminal", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSTerminal/" + this.intPOSTerminal, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/POSTerminal", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/POSTerminal/" + this.intPOSTerminal, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/POSTerminal/" + this.intPOSTerminal, {
            headers: session
        });
        return response.data;
    }
}


export class POSImpresora {
    constructor() {
this.intEmpresa = 0;
this.intSucursal = 0;
this.intPOSImpresora = 0;
this.intFolio = 0;
this.strNombre = "";
this.strUbicacion = "";
this.intAnchoTicket = 0;
this.intPOSImpresoraModelo = 0;
this.strUsuarioAlta = "";
this.strMaquinaAlta = "";
this.datFechaAlta = null;
this.strUsuarioMod = "";
this.strMaquinaMod = "";
this.datFechaMod = null;
this.intAlmacen = 0;
this.intSubAlmacen = 0;
this.intActivo = 0;
this.intPuerto = 0;
this.strURL = "";
this.strPOSTerminal = "";
    }

    async folio() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSImpresora/folio", {
            headers: session
        });
        return response.data;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSImpresora", {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/POSImpresora/" + this.intPOSImpresora, {
            headers: session
        });
        return response.data;
    }

    async save() {
        const session = new Session();
        session.validate();
        const response = await axios.post("Admin/POSImpresora", this, {
            headers: session
        });
        return response.data;
    }

    async update() {
        const session = new Session();
        session.validate();
        const response = await axios.put("Admin/POSImpresora/" + this.intPOSImpresora, this, {
            headers: session
        });
        return response.data;
    }

    async delete() {
        const session = new Session();
        session.validate();
        const response = await axios.delete("Admin/POSImpresora/" + this.intPOSImpresora, {
            headers: session
        });
        return response.data;
    }
}


export class ArticuloConfiguracion {
    constructor() {
        this.intEmpresa = 0;
        this.intSucursal = 0;
        this.intArticuloConfiguracion = 0;
        this.intArticuloConfiguracionPadre = 0;
        this.intArticuloConfiguracionMaster = 0;
        this.intArticulo = 0;
        this.strNombre = "";
        this.strNombreCorto = "";
        this.intExtra = 0;
        this.intCantidadSeleccionable = 0;
        this.intPaso = 0;
        this.intOrden = 0;
        this.intMultiple = 0;
    }

    async get() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/ArticuloConfiguration", {
            headers: session
        });
        return response.data;
    }

    async orden() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/ArticuloConfiguration/orden/" + this.intArticuloConfiguracionPadre, {
            headers: session
        });
        return response.data;
    }

    async retrieve() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/ArticuloConfiguration/" + this.intArticuloConfiguracion, {
            headers: session
        });
        return response.data;
    }

     async deleteTree(tree) {
         const session = new Session();
         session.validate();
         const response = await axios.post("Admin/ArticuloConfiguration/delete/" + this.intArticuloConfiguracion, tree);
         return response.data;
     }

    async getTree() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/ArticuloConfiguration/tree/" + this.intArticuloConfiguracion, {
            headers: session
        });
        return response.data;
    }

    async getChild() {
        const session = new Session();
        session.validate();
        const response = await axios.get("Admin/ArticuloConfiguration/child/" + this.intArticuloConfiguracion, {
            headers: session
        });
        return response.data;
    }

      async save() {
          const session = new Session();
          session.validate();
          const response = await axios.post("Admin/ArticuloConfiguration", this, {
              headers: session
          });
          return response.data;
      }

      async update() {
          const session = new Session();
          session.validate();
          const response = await axios.put("Admin/ArticuloConfiguration/" + this.intArticuloConfiguracion, this, {
              headers: session
          });
          return response.data;
      }
}