<template>
  <v-dialog width="1000px" v-model="localValue.isVisible">
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Descuento</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        outlined
                        readonly
                        label="Total"
                        :value="toMoney(localValue.posenc.dblTotal)"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        outlined
                        readonly
                        label="Descuento"
                        :value="toMoney(descuento)"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        outlined
                        readonly
                        label="Nuevo Total"
                        :value="toMoney(total)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Autorizacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.usuario"
                        :rules="[() => !!usuario.usuario || 'Llene este campo']"
                        append-icon="mdi-account"
                        label="Usuario"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.password"
                        :rules="[() => !!usuario.password || 'Llene este campo']"
                        append-icon="mdi-shield-lock"
                        label="Password"
                        type="password"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-radio-group ref="Tipo Descuento" v-model="localValue.tipo" row>
                        <v-radio label="Porcentaje" :value="1" v-show="divporcentaje"></v-radio>
                        <v-radio label="Cantidad" :value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row> 
                    <v-col>
                      <v-select
                        dense
                        :items="descuentos"
                        outlined
                        ref="motivo"
                        item-text="strNombre"
                        item-value="intPOSMotivoDescuento"
                        label="Motivo"
                        v-model="localValue.motivo"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        ref="comentario"
                        v-model="localValue.comentario"
                        label="Comentario"
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense 
                        ref="porcentaje"
                        min="0"
                        max="100"
                        type="number"
                        v-model="localValue.porcentaje"
                        label="Porcentaje"
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" dark block @click="aceptar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
var numeral = require("numeral");
var math = require("mathjs");
import Loader from "./Loader.vue";
import { Usuario, Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";

export default {
  props: {
    descuentos: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      usuario: new Usuario(),
      localValue: this.value,
      posEncFunctions: new POSEncFunctions(),
      session: new Session(),
      divporcentaje:true
    };
  },
  components: {
    Loader,
  },
  computed: {
    descuento() {
      var dbltotal=0.00;
      //porcentaje
      if (this.localValue.porcentaje > 0) {
          if(this.localValue.tipo ==1){
            if(this.localValue.porcentaje > 100)
              throw "El porcentaje no puede ser mayor que 100";

            let x = math.chain(this.localValue.porcentaje).divide(100).done();
            var total= math.chain(this.localValue.posenc.dblTotal).multiply(x).done();
            dbltotal=total;
          } // // cantidad
          else if(this.localValue.tipo ==2){
            let cantidad = math.chain(this.localValue.porcentaje).done();
            dbltotal=cantidad;
          }
          return dbltotal
      }
      else {
        return 0;
      }
    },
    total() {
      if (this.localValue.posenc.dblTotal != null) {
        return math
          .chain(this.localValue.posenc.dblTotal)
          .subtract(this.descuento)
          .done();
      } else {
        return 0;
      }
    },
  },
  methods: {
    async aceptar() {
      this.loading = true;
      try {
        if (
          this.localValue.motivo == null ||
          this.localValue.motivo == "" ||
          this.localValue.motivo == 0
        ) {
          this.$refs.motivo.focus();
          throw "Seleccione un motivo";
        }
        if (this.localValue.comentario == null || this.localValue.comentario == "") {
          this.$refs.comentario.focus();
          throw "Agregue un comentario";
        }

        if (
          this.localValue.porcentaje == 0 ||
          this.localValue.porcentaje == null ||
          this.localValue.porcentaje == ""
        ) {
          this.$refs.porcentaje.focus();
          throw "Agregue un porcentaje";
        }
        //*Validamos usuario
        const validacion = await this.usuario.validate_descuento();
        if (!validacion) throw "No se pudo validar el usuario, no tiene permisos";

     
        this.localValue.posenc.intPOSMotivoDescuento = this.localValue.motivo;
        this.localValue.posenc.dblPorcentajeDescuento = this.localValue.porcentaje;
        this.localValue.posenc.dblImporteDescuento = this.descuento;
        this.localValue.posenc.strUsuarioMod = this.session.usuario;
        this.localValue.posenc.strReferenciaDescuento = this.comentario;
        this.localValue.posenc.strUsuarioDescuento = this.usuario.usuario;
        this.localValue.posenc.dblTotal = this.total;
        this.localValue.posenc.strClaveDescuento = "DESCUENTO";
        this.localValue.posenc.intTipoDescuento=this.localValue.tipo;
        this.localValue.posenc.intPOSDescuento = 1;

        const POSEnc = await this.posEncFunctions.descuento(this.localValue.posenc);
        //*Limpiamos
        this.localValue.isVisible = false;
        this.localValue.porcentaje = 0;
        this.usuario = new Usuario();
        this.localValue.comentario = "";
        this.localValue.motivo = 0;
        this.$emit("guardar", POSEnc);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    toMoney(value) {
      return numeral(value).format("$0,0.00");
    },
    toPercent(value) {
      return numeral(value).format("0.00%");
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  async mounted(){
      //Oculta opcion de porcentaje
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        this.divporcentaje = false;
      } else {
        this.divporcentaje=true
      }
  },
};
</script>

<style></style>
