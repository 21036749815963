<template>
  <v-dialog v-model="value" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5 justify-center"> Cargando </v-card-title>
      <v-card-text>
        <v-progress-linear
          indeterminate
          color="primary"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    watch: {
      localValue(newValue) {
        this.$emit("input", newValue);
      },
      value(newValue) {
        this.localValue = newValue;
      },
    },
  },
};
</script>

<style>
</style>