<template>
  <v-dialog
    v-model="localValue"
    width="500px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Error de usuario</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col> No se encontro ningun usuario validado para esta sesión. </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" block @click="cerrarSesion()"> Ir al LogIn </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    cerrarSesion() {
      this.$emit("login");
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
