<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-select
          rounded
          :readonly="disabled"
          :items="mesas"
          outlined
          item-text="label"
          item-value="value"
          label="Mesa"
          v-model="localValue.mesa"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-select
          rounded
          :readonly="disabled"
          :items="meseros"
          outlined
          item-text="strNombre"
          item-value="intPOSMesero"
          label="Mesero"
          v-model="localValue.mesero"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    mesas: {
      type: Array,
      default: () => [],
    },
    meseros: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    watch: {
      localValue(newValue) {
        this.$emit("input", newValue);
      },
      value(newValue) {
        this.localValue = newValue;
      },
    },
  },
};
</script>

<style></style>
