import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
import axios from 'axios';

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import * as VueGoogleMaps from "vue2-google-maps" // Import package
import VueOffline from 'vue-offline';
import VueGoogleDistance from 'vue-google-distance';
import VueTouchKeyboard from "vue-touch-keyboard";
/*eslint-disable */
import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"; // load default style
/*eslint-enable */

fetch('/config.json')
.then(res => res.json())
.then(config => {
    axios.defaults.baseURL = config.api_backend_route;


    Vue.use(VueTouchKeyboard);
    Vue.use(VueGoogleMaps, {
      load: {
        key: config.google_key,
        libraries: "places"
      }
    });
    Vue.use(VueGoogleDistance);

  Vue.prototype.$config = config;
  Vue.use(VueOffline)
  Vue.use(Toast, {

  });

  Vue.config.productionTip = false
  const host = window.location.host;
  const parts = host.split('.');
  const domainLength = parts.length; // route1.example.com => domain length = 3
  if (domainLength == 3) {
    Vue.prototype.$client = parts[0];
  }else {
    Vue.prototype.$client = "N/A";
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  
})

