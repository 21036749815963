<template>
  <v-container fluid fill-height class="loginOverlay">
    <Loader v-model="loading" />
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 elevation-6>
        <v-toolbar class="pt-5 blue darken-4">
          <v-toolbar-items>
            <v-toolbar-title class="white--text"
              ><h4>Tootle TPV Admin</h4></v-toolbar-title
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field
                  label="Usuario"
                  v-model="user.usuario"
                  outlined
                  append-icon="mdi-magnify"
                  @click:append="searchEmpresa()"
                  :rules="userRules"
                  required
                ></v-text-field>
                <v-select
                  outlined
                  label="Empresa"
                  :items="empresas"
                  item-text="strNombre"
                  item-value="intEmpresa"
                  v-model="user.empresa"
                  :rules="empresaRules"
                  required
                ></v-select>
                <v-select
                  outlined
                  label="Sucursal"
                  :items="sucursalesEmpresa"
                  item-text="strNombre"
                  item-value="intSucursal"
                  v-model="user.sucursal"
                  :rules="sucursalRules"
                  required
                ></v-select>
                <v-text-field
                  label="Password"
                  v-model="user.password"
                  outlined
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  required
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-btn
                    @click="submit"
                    block
                    :class="{
                      'blue darken-4 white--text': valid,
                      disabled: !valid,
                    }"
                    >Login</v-btn
                  >
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Loader from "../../components/Loader.vue";
import { User } from "../../controllers/AdminController";
import { Toast, ErrorWeb } from "../../controllers/DataController";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      e1: true,
      empresas: [],
      sucursales: [],
      sucursalesEmpresa: [],
      user: new User(),
      passwordRules: [(v) => !!v || "La password el obligatoria"],
      userRules: [(v) => !!v || "El usuario es obligatorio"],
      empresaRules: [(v) => !!v || "La empresa es obligatoria"],
      sucursalRules: [(v) => !!v || "La sucursal es obligatoria"],
    };
  },
  components: {
    Loader,
  },
  watch: {
    ["user.usuario"]() {
      this.user.empresa = 0;
      this.user.sucursal = 0;
      this.empresas = [];
      this.sucursales = [];
      this.sucursalesEmpresa = [];
    },
    ["user.empresa"](empresa) {
      this.sucursalesEmpresa = this.sucursales.filter((sucursal) => {
        return sucursal.intEmpresa == empresa;
      });
    },
  },
  methods: {
    async searchEmpresa() {
      try {
        this.loading = true;
        this.empresas = await this.user.empresas();
        this.sucursales = [];
        this.sucursalesEmpresa = [];
        for await (let empresa of this.empresas) {
          let sucursales = await this.user.sucursales(empresa.intEmpresa);
          sucursales.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.user.login();
          let empresa = this.empresas.find((x) => x.intEmpresa == this.user.empresa);
          let sucursal = this.sucursales.find((x) => x.intSucursal == this.user.sucursal);
          this.user.setSession(sucursal.strNombre, empresa.strNombre);
          this.$router.push("/Admin");
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
.loginOverlay {
  background: rgba(33, 150, 243, 0.3);
}

.photoCredit {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>
