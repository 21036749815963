<template>
  <div>
    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>TootleTPV Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout()" icon dark>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list nav dense>
        <div class="text-center">
          <p class="text-center">{{ session.admin_empresa_nombre }}</p>
          <p class="text-center">{{ session.admin_sucursal_nombre }}</p>
        </div>
        <v-subheader>Catalogos</v-subheader>
        <v-list-item-group v-model="group" active-class="secondary">
          <v-list-item @click="go(menu.id)" v-for="menu in menus" :key="menu.nombre">
            <v-list-item-icon>
              <v-icon v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="menu.nombre"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <slot name="main" :entity="entity"></slot>
    </v-main>
  </div>
</template>

<script>
import { Session } from "../controllers/AdminController";
export default {
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    session: new Session(),
    drawer: false,
    group: null,
    menus: [
      { nombre: "Articulos", id: "Articulo", icon: "mdi-database" },
      { nombre: "Familia", id: "Familia", icon: "mdi-database" },
      { nombre: "Meseros / Usuarios", id: "Usuario", icon: "mdi-database" },
      { nombre: "Motivo Cancelacion", id: "MotivoCancelacion", icon: "mdi-database" },
      { nombre: "Motivo Descuento", id: "MotivoDescuento", icon: "mdi-database" },
      { nombre: "Tipo Pago", id: "TipoPago", icon: "mdi-database" },
      {
        nombre: "Configuracion Articulo",
        id: "ConfiguracionArticulo",
        icon: "mdi-database",
      },
    ],
  }),
  mounted() {
    try {
      let session = new Session();
      session.validate();
    } catch (error) {
      alert(error);
      this.$router.replace({ name: "LoginAdmin" });
    }
  },
  methods: {
    logout() {
      let session = new Session();
      session.logout();
      this.$router.replace({ name: "LoginAdmin" });
    },
    go(id) {
      this.$router.replace({ name: id });
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style></style>
