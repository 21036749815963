<template>
  <v-bottom-sheet v-model="localValue.visible">
    <v-sheet class="text-center">
      <v-card outlined>
        <v-container>
          <vue-touch-keyboard
            :options="options"
            :layout="layout"
            :cancel="hide"
            :input="localValue.input"
            :accept="accept"
          />
        </v-container>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    layout: {
      type: String,
      default: "normal",
    },
  },
  data() {
    return {
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },
      localValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    hide() {
      this.localValue.visible = false;
    },
    accept(text) {
      this.$emit("accept", text);
      this.localValue.visible = false;
    },
  },
};
</script>
