<template>
  <v-dialog v-model="localValue.visible" width="650">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Calculo de Distancia</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :disabled="distanceValidation" text @click="calcular()">
            Calcular
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              label="Inicio"
              v-model="localValue.start"
              outlined
              readonly
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              label="Destino"
              v-model="localValue.end"
              outlined
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Distancia"
              v-model="distance.text"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Precio"
              :value="isMoney(precio)"
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <vue-google-distance
          @newDistance="distanceChange"
          @notFound="errorDireccion"
          @tracked="error = false"
          :start="localValue.start"
          :end="localValue.end"
        >
        </vue-google-distance>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
var math = require("mathjs");
import { isMoney } from "../js/utilities";

import { Toast } from "../controllers/DataController.js";
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      distance: {
        text: "",
        value: 0,
      },
      error: false,
      localValue: this.value,
    };
  },
  methods: {
    calcular() {
      if (!this.error && !this.distanceValidation) {
        //* Regresamos el precio
        this.$emit("completed", this.precio);
      }
    },
    errorDireccion() {
      if (!this.error) {
        this.$toast.error(
          "No se ha podido encontrar la direccion, favor de modificarla",
          new Toast()
        );
        this.error = true;
      }
    },
    distanceChange(payload) {
      this.distance = payload;
    },
    isMoney: isMoney,
  },
  computed: {
    precio() {
      if (this.$config.envio_maximo_distancia < this.distance.value) {
        return 0;
      } else {
        if (this.$config.envio_minimo_distancia > this.distance.value) {
          return this.$config.envio_minimo_precio;
        } else {
          //* Calculo de distancia x km
          var distanciaCobrable = math
            .chain(this.distance.value)
            .subtract(this.$config.envio_minimo_distancia)
            .divide(1000)
            .done();
          var precio = math
            .chain(distanciaCobrable)
            .multiply(this.$config.envio_por_kilometro)
            .add(this.$config.envio_minimo_precio)
            .done();
          return math.round(precio, 0);
        }
      }
    },
    distanceValidation() {
      if (this.$config.envio_maximo_distancia < this.distance.value) {
        this.$toast.error(
          "Se ha superado la distancia maxima de entrega, no se puede realizar venta",
          new Toast()
        );
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
