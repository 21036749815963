<template>
  <v-dialog v-model="localValue.visible" width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar articulo</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Cantidad"
              min="1"
              rounded
              @keypress="isNumber($event)"
              type="number"
              v-model="posdet.dblCantidad"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Precio"
              min="0"
              rounded
              outlined
              readonly
              @keypress="isNumber($event)"
              prefix="$"
              v-model="posdet.dblPU"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="guardarEdicionDetalle()" block color="primary">Guardar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    posdet: {
      type: Object,
      default: () => {},
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  methods: {
    guardarEdicionDetalle() {
      this.$emit("guardar", this.posdet);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        const result = parseInt(evt.target.value);
        if (result < 0) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },
};
</script>

<style></style>
