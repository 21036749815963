<template>
  <div>
    <Loader v-model="loading" />
    <v-dialog v-model="confirm" persistent max-width="290">
      <v-card>
        <v-card-title> Confirmacion </v-card-title>
        <v-card-text>Desea eliminar el registro?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="confirm = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="finalizarConfirm()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title>Ordenes de Cocina</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          
          <v-col md="3" v-for="(orden, index) in ordenes" :key="index"  >
            <v-card
              min-height="100px"
              @click="finalizar(orden, index)"
              :color="orden.color"
              
            >
              <h2>Espera: {{orden.lapsoTiempo}}</h2> 
                <h1 COLOR="red">{{orden.strCancelacionDevolucion}}</h1> 
              <v-card-title>
                <v-spacer></v-spacer>
                Orden {{orden.intOrden}} - {{orden.strCliente}} - {{orden.strTipoVenta}}
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text style="font-size: 15px;" >
                <div style="color: #1C2833; " v-for="detalle in orden.posdet" :key="detalle.intPOSEnc">
                  ({{detalle.dblCantidad}}) - {{detalle.strNombre}}
                  <div style="color: #566573 ;"  v-for="detdet in detalle.posdetdet" :key="detdet.intPOSDet">
                   -->  ({{detdet.cantidad}}) - {{detdet.strArticulo}}
                  </div>
                </div>
              </v-card-text>
              <v-card-text>
                <v-card-subtitle>
                  <v-spacer></v-spacer>
                    *****Notas *******
                  <v-spacer></v-spacer>
                   {{orden.strNota}} 
                </v-card-subtitle>

              </v-card-text>
             </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb,Data } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import moment from "moment";
import { toDateString } from "../js/utilities";
import { POSUserFunctions } from "../controllers/UserController.js";


function str_pad_left(string, pad, length) {
          return (new Array(length + 1).join(pad) + string).slice(-length);
      }


export default {
  data() {
    return {
      loading: false,
      show: true,
      session: new Session(),
      dataFunctions: new Data(),
      ordenFunctions: new POSEncFunctions(),
      userFunctions: new POSUserFunctions(),
      ordenes:null,
      detalle:null,
      tiempos: [],
      time:null,
      terminales:[],
      intSucursal:this.$route.params.id,
      intEmpresa:this.$config.empresa,
      confirm:false,
      index:null,
      id:{}
    };
  },
  components: {
    Loader,
  },
  watch: {
    loading(newValue) {
      this.$emit("loading", newValue);
    },

  } ,
 async mounted() {
  console.log(this.intSucursal)
    console.log( this.$route.params.id)
  },
  
 
  
 async created()
  {
    try {
          await this.getOrdenes(this.intSucursal);
            setInterval(() => {
                this.ordenes.forEach(orden => {
                    orden.lapsoTiempo = this.getTime(orden);
                })
            }, 1000);
          
           setInterval(() => {
          this.getOrdenes(this.intSucursal)
       
        }, 15000);
      
         

    } catch (e) {
      this.$toast.error(ErrorWeb(e), new Toast());

    } 
  },
  methods: {
    toDateString: toDateString,
  getTime(orden) {
    // se obtinela fecha actual
    const now = new Date();
    // Convertimos la fecha de inicio de la orden a objeto Date
    const startDate = new Date(orden.fechaInicioMoment);
    
    // Calculamos la diferencia en milisegundos
    let diff = now - startDate;
    // Convertimos la diferencia a segundos
    let seconds = Math.floor(diff / 1000);
    // Calculamos las horas, minutos y segundos
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    // Formateamos el tiempo final
    let finalTime = str_pad_left(hours, '0', 2) + ':' + str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

    console.log("now",now, " ","dif",diff  ," ","seconds",seconds ," ","hours",hours, " ","minutes",minutes)
    console.log(finalTime);
    return finalTime;

 }, 

  
 async getOrdenes(intSucursal){
  try {
    if(intSucursal > 0){
      this.ordenes = [];
      let orden = await this.ordenFunctions.dataOrdenes(intSucursal);
      let id = 0;

      // Ordenar las órdenes por intOrden
      orden.sort((a, b) => a.intOrden - b.intOrden);

      for (let order of orden) {
        let node = {
          key: id.toString(),
          intPOSTipoServicio: order.intPOSTipoServicio,
          intSucursal: order.intSucursal,
          intPOSEnc: order.intPOSEnc,
          intOrden: order.intOrden,
          datFechaInicio: order.datFechaInicio,
          strPOSTipoServicio: order.strPOSTipoServicio,
          strTipoVenta: order.strTipoVenta,
          strCliente: order.strCliente,
          strCancelacionDevolucion: order.strCancelacionDevolucion,
          EstatusEnc: order.EstatusEnc,
          EstatusE: order.EstatusE,
          intCancelacionDevolucion: order.intCancelacionDevolucion,
          intFinalizado: order.intFinalizado,
          strNota: order.strNota,
          lapsoTiempo: "",
          color: "",
          posdet: [],
        };

        let detalle = await this.ordenFunctions.retrieve_idDet(order.intPOSEnc, intSucursal, this.intEmpresa);
        let idDet = 0;
        for (let det of detalle.posDet) {
          let detNodo = {
            key: id.toString() + "-" + idDet.toString(),
            intPOSEnc: det.intPOSEnc,
            intPOSDet: det.intPOSDet,
            intFolio: det.intFolio,
            dblCantidad: det.dblCantidad,
            strNombre: det.strNombre,
            strFamilia: det.strFamilia,
            intCancelacionDevolucion: det.intCancelacionDevolucion,
            strComentarioCancelacion: det.strComentarioCancelacion,
            posdetdet: [],
          };
          
          let idGrandChildren = 0;
          for (let detdet of det.posDetArticuloConfiguracion) {
            let detdetNodo = {
              key: id.toString() + "-" + idDet.toString() + "-" + idGrandChildren.toString(),
              strArticulo: detdet.strNombre,
              cantidad: detdet.dblCantidad
            };
            detNodo.posdetdet.push(detdetNodo);
            idGrandChildren++;
            console.log(detdetNodo);
          }
          node.posdet.push(detNodo);
          idDet++;
        }
        this.ordenes.push(node);

        setInterval(() => {
          this.ordenes.forEach(orden => {
            orden.fechaInicioMoment = moment(orden.datFechaInicio);
            orden.lapsoTiempo = this.getTime(orden);

            let segundosTotales = moment().diff(orden.fechaInicioMoment, 'seconds');
            let hours = Math.floor(segundosTotales / 3600);
            segundosTotales = segundosTotales - hours * 3600;
            let minutes = Math.floor(segundosTotales / 60);
            if ((minutes + hours * 60) >= 5)
              orden.color = "#ffffcc";
            if ((minutes + hours * 60) >= 10)
              orden.color = "#ff9999";
          });
        }, 1000);

        id++;
      }
    }
  } catch (e) {
    this.$toast.error(ErrorWeb(e), new Toast());
  } finally {
    // loading.close();
  }
},
    async finalizar(orden, index) {
          try {

            this.id=orden;
            this.index=index;
            this.confirm=true;
              //  await this.ordenFunctions.finalizarOrden(orden.intPOSEnc,orden.intSucursal);
              // this.ordenes.splice(index, 1);
          } catch (e) {
            this.$toast.error(ErrorWeb(e), new Toast());
          } 
      },
      async finalizarConfirm() {
          try {
            this.confirm=false;
               await this.ordenFunctions.finalizarOrden(this.id.intPOSEnc,this.id.intSucursal);
              this.ordenes.splice(this.index, 1);
          } catch (e) {
            this.$toast.error(ErrorWeb(e), new Toast());
          } 
      },
  }
 
};
</script>


<style>

.h5{
  color:red;
}
  .fade-enter-active, .fade-leave-active {
transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
opacity: 0
}
</style>
