import axios from 'axios';
import Cookies from "js-cookie";
export class Session {
    constructor() {
        this.empresa = Cookies.get('POS_EMPRESA') ? parseInt(Cookies.get('POS_EMPRESA')) : 0;
        this.sucursal = Cookies.get('POS_SUCURSAL') ? parseInt(Cookies.get('POS_SUCURSAL')) : 0;
        this.usuario = Cookies.get('POS_USER');
        this.terminal = Cookies.get('POS_TERMINAL') ? Cookies.get('POS_TERMINAL') : "NINGUNO";
        this.tipo = Cookies.get('POS_TIPO') ? Cookies.get('POS_TIPO') : "comandero";
        this.register = Cookies.get('POS_REGISTER_CAJA') ? Cookies.get('POS_REGISTER_CAJA') : 0;
        this.version = Cookies.get('POS_VERSION') ? Cookies.get('POS_VERSION') : 'desktop' ;
        this.keyboard = Cookies.get('POS_KEYBOARD') ? (Cookies.get('POS_KEYBOARD') == "true" ? true : false) : false;
    }

    setKeyboard(param) {
        Cookies.set('POS_KEYBOARD', param);
    }

    logout() {
        Cookies.remove('POS_EMPRESA');
        Cookies.remove('POS_USER');
        Cookies.remove('POS_TERMINAL');
        Cookies.remove('POS_TIPO');
        Cookies.remove('POS_REGISTER_CAJA');
    }
}

export class SessionVuex {
    constructor() {
        this.empresa = 0;
        this.sucursal = 0;
        this.usuario = "";
        this.terminal = "";
        this.tipo = "";
        this.register = 0;
        this.version =  'desktop' ;
    }
}

export function ErrorWeb(_e) {
    console.log(_e);
    if (_e.response) {
        return "Error. " + _e.response.data;
    } else {
        return "Error. " + _e;
    }
}

export class Usuario {
    constructor() {
        this.usuario = "";
        this.password = "";
    }
    async terminales(usuario) {
        const session = new Session();

        const response = await axios.get("POS_UsuarioTerminales/" + usuario, {
            headers: session
        });
        return response.data;
    }
    async login() {
        const response = await axios.post("POS_UserLogin", this)
         return response.data;
}
    async cajero(usuario) {
        const session = new Session();

        const response = await axios.get("POS_UsuarioMesero/" + usuario, {
            headers: session
        });
        return response.data;
    }
    async ultimo_register(_session) {
        const response = await axios.get("POS_UsuarioUltimoRegister", {
            headers: _session
        });
        return response.data;
    }
    async login_terminal(terminal) {
        const session = new Session();

        const response = await axios.post("POS_UserLoginTerminal/" + terminal, this, {
            headers: session
        });
        return response.data;
    }
    async register_actual(empresa, terminal) {
        const session = new Session();

        const response = await axios.get("POS_RegisterActual/" + empresa + "/" + terminal + "/" + this.usuario, {
            headers: session
        });
        return response.data;
    }
    async validate_descuento() {
        const response = await axios.post("POS_ValidarDescuento", this,);
        return response.data;
    }
    async validate_cancelacion() {
        const response = await axios.post("POS_ValidarCancelacion", this);
        return response.data;
    }
    async validate_cortesia() {
        const response = await axios.post("POS_ValidarCortesia", this);
        return response.data;
    }
    async validate_Autorizacion() {
        const response = await axios.post("POS_ValidarAutorizacion", this);
        return response.data;
    }
}
class Dialog {
    constructor(_option, _impresora, _evento, _idm, _register) {
        return {
            visible: false,
            option: _option,
            impresora: _impresora,
            evento: _evento,
            idm: _idm,
            register: _register
        }
    }
}

class MesaPago {
    constructor() {
        return {
            mesa: 0,
            mesero: 0
        }
    }
}

class LlevarPago {
    constructor() {
        return {
            clave: '',
            tipo: 0,
            telefono:''
        }
    }
}

class AlertMessage {
    constructor() {
        return {
            visible: false,
            header: '',
            body: ''
        }
    }
}

class CancelarEnc {
    constructor() {
        return {
            usuario: '',
            password: '',
            motivo: 0,
            comentario: '',
            visible: false
        }
    }
}

class JuntarCuenta {
    constructor() {
        return {
            POSEnc: {},
            visible: false
        }
    }
}

class SepararCuenta {
    constructor() {
        return {
            POSEnc: {},
            visible: false
        }
    }
}

class Cortesia {
    constructor() {
        return {
            POSEnc: {},
            isVisible: false
        }
    }
}

class CancelarDet {
    constructor() {
        return {
            usuario: '',
            password: '',
            motivo: 0,
            comentario: '',
            posdet: {},
            tipo: 9,
            isVisible: false
        }
    }
}

class DescuentoDet {
    constructor() {
        return {
            isVisible: false,
            usuario: '',
            password: '',
            motivo: 0,
            comentario: '',
            porcentaje: 0,
            posdet: {}
        }
    }
}

class DescuentoEnc {
    constructor() {
        return {
            isVisible: false,
            usuario: '',
            password: '',
            motivo: 0,
            comentario: '',
            porcentaje: 0,
            posenc: {}
        }
    }
}

class Confirm {
    constructor() {
        return {
            visible: false,
            text: '',
            tile: '',
            aceptarText: 'Aceptar',
            cancelarText: 'Cancelar',
            event: 'confirm'
        }
    }
}

class IFrameDialog {
    constructor(_title, _visible, _url) {
        return {
            title: _title,
            visible: _visible,
            url: _url,
            id: ""
        }
    }
}

class OrdenDetalle {
    constructor() {
        return {
            key: 'orden',
            index: 0
        }
    }
}

class DomicilioPago {
    constructor() {
        this.intCliente= 0;
        this.intEmpleado= 0;
        this.strTelefono= '';
        this.strNombre= '';
        this.strDireccion= '';
        this.strColonia= '';
        this.strEntreCalle= '';
        this.strReferencia= '';
        this.strPoblacion= '';
        this.strTelefono2= '';
        this.strRFC = '';
        this.strCorreoElectronico = '';
        this.strNumeroInterior = '';
        this.strNumeroExterior = '';
        this.strRFC = '';
    }
}

class Toast {
    constructor() {
        return {
            position: "bottom-center",
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            duration: 5000,
        }
    }
}



export class TerminalDialog {
    constructor() {
        return {
            isVisible: false,
            terminal: ""
        }
    }
}

export class Data {
    async loadUsuariosOrdenes() {
        const session = new Session();

        const response = await axios.get("POS_UsuariosOrdenes", {
            headers: session
        });
        return response.data;
    }
    async loadMeseros() {
        const session = new Session();

        const response = await axios.get("POS_DataMeseros", {
            headers: session
        });
        return response.data;
    }
    async loadMeserosTerminal() {
        const session = new Session();

        const response = await axios.get("POS_DataMeserosXTerminal", {
            headers: session
        });
        return response.data;
    }
    async loadMeserosRegister () {
        const session = new Session();

        const response = await axios.get("POS_DataMeserosRegister", {
            headers: session
        });
        return response.data;
    }
    async loadTerminalesPago () {
        const session = new Session();

        const response = await  axios.get("POS_DataTerminalPago", {
            headers: session
        });
        return response.data;
    }
    async loadTipoVentas () {
        const session = new Session();

        const tickets = await axios.get("POS_TipoVenta", {
            headers: session
        })
        return tickets.data;
    }
    async loadPreciosTipoVenta (intPOSTipoVenta) {
        const session = new Session();

        const response = await axios.get("POS_DataArticulosTipoVenta/" + intPOSTipoVenta, {
            headers: session
        })
        return response.data;
    }

    async loadTipoPrecio (intPOSTipoVenta){
        const session = new Session();
        const TiposPagos = await axios.get("POS_TipoPagoPorPOSTipoVenta/" + intPOSTipoVenta, {
            headers: session
        })
        return TiposPagos.data;
    }
    async loadPreciosArticuloConfigutacionTipoVenta (intPOSTipoVenta) {
        const session = new Session();

        const response = await axios.get("POS_DataArticuloConfiguracionTipoVenta/" + intPOSTipoVenta, {
            headers: session
        })
        return response.data;
    }
  
    async loadOrdenesUsuario (intPOSMesero) {
        const session = new Session();

        const tickets = await axios.get("POS_DataXRegisterXUsuario/" + intPOSMesero, {
            headers: session
        })
        return tickets.data;
    }
    async loadOrdenes () {
        const session = new Session();

        const tickets = await axios.get("POS_DataXRegister", {
            headers: session
        })
        return tickets.data;
    }
    async loadOrdenesXTerminal () {
        const session = new Session();

        const tickets = await axios.get("POS_DataXTerminal", {
            headers: session
        })
        return tickets.data;
    }
    async loadImpresoras () {
        const session = new Session();

        const posimpresoras = await axios.get("POS_DataImpresorasXTerminal", {
            headers: session
        });
        return posimpresoras.data;
    }
    async loadDataPOS() {
        const session = new Session();

        const productos = await axios.get("POS_DataArticulos", {
            headers: session
        })
        const familias = await axios.get("POS_DataFamilias", {
            headers: session
        })
        const tipospago = await axios.get("POS_DataTiposPago", {
            headers: session
        })
      
        const tickets = await axios.get("POS_DataXRegister", {
            headers: session
        })
        const articuloconfiguraciones = await axios.get("POS_DataArticuloConfiguracion/", {
            headers: session
        })
        const repartidores = await axios.get("POS_DataRepartidores", {
            headers: session
        })
        const motivoscancelacion = await axios.get("POS_DataMotivosCancelacion", {
            headers: session
        })
        const posdescuentos = await axios.get("POS_DataDescuento", {
            headers: session
        })
        const motivosdescuento = await axios.get("POS_DataMotivosDescuento", {
            headers: session
        })
        const poscolonias = await axios.get("POS_DataColonia", {
            headers: session
        })
        const posimpresoras = await axios.get("POS_DataImpresoras", {
            headers: session
        })
        const posmesas = await axios.get("POS_DataMesa", {
            headers: session
        })
        const clientes = await axios.get("POS_DataClientes", {
            headers: session
        })
        const result = [productos.data, familias.data, tipospago.data, tickets.data, articuloconfiguraciones.data, repartidores.data, motivoscancelacion.data, posdescuentos.data, motivosdescuento.data,
            poscolonias.data, null, null, null, posimpresoras.data, posmesas.data,clientes.data
        ]
        return result;
    }

    async loadDataclient(){
        const session = new Session();

        return axios.get("POS_DataClient", {
            headers: session
        });
    }
    async loadDataImpresoras(){
        const session = new Session();

        return axios.get("POS_DataImpresoras", {
            headers: session
        });
    }
    async loadDataEmpresa(){
        const session = new Session();

        return axios.get("POS_RetrieveEmpresa", {
            headers: session
        });
    }
    async loadDataPOSInfo(){
        const session = new Session();

        const response = await axios.get("POS_RetrieveInfo", {
            headers: session
        });
        return response.data;
    }
    async loadDataClientes(strTelefono){
        const session = new Session();

        return axios.get("POS_DataClientes/" + strTelefono, {
            headers: session
        });
    }
    async loadDataTicket(){
        const session = new Session();

        return axios.get("POS_DataXRegister", {
            headers: session
        });
    }
    async loadDataTicketTerminal(){
        const session = new Session();

        return axios.get("POS_DataXTerminal", {
            headers: session
        });
    }
    async loadDataCierre(){
        const session = new Session();

        return axios.get("POS_DataCierre", {
            headers: session
        });
    }
    async loadTootleEnvio(){
        const session = new Session();

        return axios.get("POS_TootleEnvio", {
            headers: session
        });
    }
    async Repartidores() {
        const session = new Session();

        const response = await axios.get("POS_SalidaRepartidor", {
            headers: session
        })
        return response.data;
    }
  

}

export {
    DomicilioPago,
    JuntarCuenta,
    SepararCuenta,
    MesaPago,
    LlevarPago,
    Confirm,
    AlertMessage,
    IFrameDialog,
    CancelarEnc,
    CancelarDet,
    DescuentoDet,
    DescuentoEnc,
    Dialog,
    OrdenDetalle,
    Toast,
    Cortesia
};