<template>
  <v-dialog v-model="localValue">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ordenes</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col md="2" v-for="orden in ordenes_carousel" :key="orden.intPOSEnc">
            <v-card
              min-height="100%"
              @click="seleccionar(orden)"
              dark
              :color="getColor(orden)"
              :img="getImage(orden)"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                {{ title +" "+ orden.intOrden }}
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row align="center" class="text-center mx-0">
                  <b>{{ getLabel(orden.intPOSTipoServicio) }}</b>
                </v-row>
                <v-row align="center" class="mx-0">
                  <b>{{ getEstatus(orden.intEstatus) }}</b>
                </v-row>
                <v-row v-if="orden.intPOSTipoServicio == 1" align="center" class="mx-0">
                  <b>{{ orden.strMesa }}</b>
                </v-row>
                <v-row v-if="orden.intPOSTipoServicio == 2" align="center" class="mx-0">
                  <b>{{ orden.cliente.strNombre + " " + orden.cliente.strTelefono }}</b>
                </v-row>
                <v-row v-if="orden.intPOSTipoServicio == 2" align="center" class="mx-0">
                  <b>{{ orden.cliente.strDireccion + " " + orden.cliente.strColonia }}</b>
                </v-row>
                <v-row v-if="orden.intPOSTipoServicio == 3" align="center" class="mx-0">
                  <b>{{ orden.strMesa }}</b>
                </v-row>
                <v-row v-if="orden.intPOSTipoServicio == 1" align="center" class="mx-0">
                  <b>{{ getMesero(orden) }}</b>
                </v-row>
                <v-row align="center" class="mx-0">
                  <b>{{ toDateString(orden.datFecha, 2) }}</b>
                </v-row>
                <v-row v-if="title == 'Factura' " align="center" class="mx-0">
                  <b> GUIA {{ orden.strMesa }}</b>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { toDateString } from "../js/utilities";

export default {
  props: {
    ordenes: {
      type: Array,
      default: () => [],
    },
    meseros: {
      type: Array,
      default: () => [],
    },
    register: {
      type: Number,
      default: 0,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localValue: this.value,
      title: this.$config.ordenTitle
    };
  },
  methods: {
    toDateString: toDateString,
    getLabel(intPOSTipoServicio) {
      if (intPOSTipoServicio == 1) return "MESA";
      else if (intPOSTipoServicio == 2){
        if(this.title =="Factura")
          return "";
        else 
          return "DOMICILIO"
      } 
      else if (intPOSTipoServicio == 3) return "LLEVAR";
    },
    getColor(orden) {
      if (orden.intPOSTipoServicio == 1) return "primary";
      else if (orden.intPOSTipoServicio == 2) return "green";
      else if (orden.intPOSTipoServicio == 3) return "secondary";
    },
    getMesero(orden) {
      const mesero = this.meseros.find(
        (mesero) => mesero.intPOSMesero == orden.intPOSMesero
      );
      if (mesero != null) {
        return mesero.strNombre + " " + mesero.strApellidoPaterno;
      } else {
        return "NO ASIGNADO";
      }
    },
    getImage(orden) {
      if (orden.intPOSTipoServicio == 1) return "/imagenes/table.jpg";
      else if (orden.intPOSTipoServicio == 2 && orden.intEstatus ==3) return "/images/domicilio.jpg";
      else if (orden.intPOSTipoServicio == 3) return "/imagenes/takeaway.jpg";
    },
    getEstatus(estatus) {
       //conf para dicex
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        if (estatus == 1 ) return "ALTA";
        else if (estatus == 2) return "PAGADA";
        else if (estatus == 3) return "PAGO PARCIAL";
        else if (estatus == 9) return "CANCELADA";
      }
      else{
        if (estatus == 1) return "REGISTRADA";
        else if (estatus == 2) return "EN PROCESO";
        else if (estatus == 3) return "TERMINADA";
        else if (estatus == 4) return "PAGADA";
      }
      
    },
    seleccionar(orden) {
      this.$emit("seleccionar", orden);
    },
  },
  computed: {
    ordenes_carousel() {
      return this.ordenes
        .slice(this.ordenes.length - this.minOrdenes, this.ordenes.length)
        .sort((a, b) => (a.intPOSEnc > b.intPOSEnc ? 1 : -1));
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
