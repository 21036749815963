<template>
  <v-dialog
    v-model="localValue.isVisible"
    scrollable
    transition="dialog-bottom-transition"
  >
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Busqueda Cliente</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="this.localValue.data"
              :items-per-page="5"
              @click:row="seleccionaCliente"
            >
              <template v-slot:top>
                <v-dialog v-model="editarCliente">
                  <v-card>
                    <v-toolbar color="primary" dark>
                      <v-btn icon dark @click="editarCliente = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Editar Cliente</v-toolbar-title>
                    </v-toolbar>
                    <v-container fluid>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Telefono"
                            v-model="cliente.strTelefono"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Nombre"
                            v-model="cliente.strNombre"
                          />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          ><v-text-field
                            rounded
                            outlined
                            label="Direccion"
                            v-model="cliente.strDireccion"
                        /></v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Entre Calles"
                            v-model="cliente.strEntreCalle"
                          />
                        </v-col>
                        <v-col>
                          <v-combobox
                            item-text="strColonia"
                            item-value="strColonia"
                            :items="colonias"
                            :return-object="false"
                            placeholder="Buscar..."
                            label="Colonia"
                            outlined
                            rounded
                            v-model="cliente.strColonia"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Referencia"
                            v-model="cliente.strReferencia"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Ciudad"
                            v-model="cliente.strPoblacion"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            rounded
                            outlined
                            label="Telefono 2"
                            v-model="cliente.strTelefono2"
                          />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-btn block rounded color="primary" @click="guardar()"
                            >Guardar</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.intCliente`]="{ item }">
                <!-- //* Previene el eventro de la fila -->
                <v-btn color="secondary" rounded @click.stop.prevent="editar(item)">
                  Editar
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import Loader from "./Loader.vue";
import { POSEncFunctions } from "../controllers/POSController.js";

export default {
  props: {
    value: {
      required: true,
    },
    colonias: {
      type: Array,
      default: () => [],
    },
    version: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localValue: this.value,
      editarCliente: false,
      loading: false,
      session: new Session(),
      ordenFunctions: new POSEncFunctions(this.$config.api_backend_route),
      cliente: {
        strTelefono: "",
        strNombre: "",
        strColonia: "",
        strDireccion: "",
        strEntreCalle: "",
        strReferencia: "",
        strPoblacion: "",
        strTelefono2: "",
        intCliente: 0,
      },
      headers: [
        { text: "Telefono", value: "strTelefono" },
        { text: "Nombre", value: "strNombre" },
        { text: "Colonia", value: "strColonia" },
        { text: "Direccion", value: "strDireccion" },
        { text: "Entre Calles", value: "strEntreCalle" },
        { text: "Referencia", value: "strReferencia" },
        { text: "Ciudad", value: "strPoblacion" },
        { text: "Telefono 2", value: "strTelefono2" },
        { text: "", value: "intCliente", sortable: false },
      ],
    };
  },
  components: {
    Loader,
  },
  watch: {
    version(newValue) {
      if (newValue == 2) {
        this.headers = [
          { text: "Nombre", value: "strNombre" },
          { text: "RFC", value: "strRFC" },
          { text: "Direccion", value: "strDireccion" },
          { text: "Colonia", value: "strColonia" },
          { text: "# Ext", value: "strNumeroExterior" },
          { text: "# Int", value: "strNumeroInterior" },
          { text: "Telefono", value: "strTelefono" },
          { text: "Correo", value: "strCorreoElectronico" },
          { text: "", value: "intCliente", sortable: false },
        ];
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    async guardar() {
      this.loading = true;
      try {
        await this.ordenFunctions.editarCliente(
          this.cliente,
          this.session.empresa,
          this.session.usuario,
          this.session.terminal
        );
        this.$toast.success("Cliente actualizado con exito", new Toast());
        this.editarCliente = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    seleccionaCliente(cliente) {
      this.$emit("clienteSeleccionado", cliente);
    },
    editar(cliente) {
      this.editarCliente = true;
      this.cliente = cliente;
    },
  },
};
</script>

<style></style>
