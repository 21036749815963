<template>
  <v-dialog v-model="localValue.visible" width="650">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cancelar Orden</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              v-model="localValue.usuario"
              :rules="[() => !!localValue.usuario || 'Llene este campo']"
              append-icon="mdi-account"
              label="Usuario"
              outlined
              required
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="localValue.password"
              :rules="[() => !!localValue.password || 'Llene este campo']"
              append-icon="mdi-shield-lock"
              type="password"
              label="Password"
              outlined
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="motivos"
              outlined
              item-text="strNombre"
              item-value="intPOSMotivoCancelacion"
              label="Motivo"
              v-model="localValue.motivo"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Comentario"
              v-model="localValue.comentario"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" dark block @click="guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    motivos: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    guardar() {
      this.$emit("guardar");
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
