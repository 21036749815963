const state = {
  session: {
    empresa : 0,
    usuario : "",
    terminal : "",
    tipo : "",
    register : 0,
    version : 'desktop',
    sucursal : 0
  }
};
const getters = {

};
const actions = {
  setEmpresa (context, empresa) {
    context.commit("SET_EMPRESA",empresa);
  },
  setSucursal (context, sucursal) {
    context.commit("SET_SUCURSAL",sucursal);
  },
  setRegister (context, register) {
    context.commit("SET_REGISTER",register);
  },
  setUsuario (context, usuario) {
    context.commit("SET_USUARIO",usuario);
  },
  setTerminal (context, terminal) {
    context.commit("SET_TERMINAL",terminal);
  },
  setTipo (context, tipo) {
    context.commit("SET_TIPO",tipo);
  },
  setVersion (context, version) {
    context.commit("SET_VERSION",version);
  }
};
const mutations = {
  SET_EMPRESA (state, empresa) {
    state.session.empresa = empresa;
  },
  SET_SUCURSAL (state, sucursal) {
    state.session.sucursal = sucursal;
  },
  SET_REGISTER (state, register) {
    state.session.register = register;
  },
  SET_USUARIO (state, usuario) {
    state.session.usuario = usuario;
  },
  SET_TERMINAL (state, terminal) {
    state.session.terminal = terminal;
  },
  SET_TIPO (state, tipo) {
    state.session.tipo = tipo;
  },
  SET_VERSION (state, version) {
    state.session.version = version;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
