<template>
  <UI :entity="entity">
    <template v-slot:main="{ entity }">
      <Search
        @selected="selectItem"
        :headers="headers"
        :rows="entities"
        v-model="search"
      />
      <Search
        @selected="selectUser"
        :headers="headersUser"
        :rows="entitiesUser"
        v-model="searchUser"
      />
      <Loader v-model="loading" />
      <v-container fluid>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-card-title dark> Usuario / Mesero </v-card-title>
            <v-btn icon dark @click="fillData()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon dark @click="nuevo()">
              <v-icon>mdi-new-box</v-icon>
            </v-btn>
            <v-btn icon dark @click="guardar()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon dark @click="eliminar()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col md="2">
                  <v-text-field
                    outlined
                    label="Folio"
                    v-model="entity.intFolio"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strNombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strApellidoPaterno"
                    label="Apellido Paterno"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strApellidoMaterno"
                    label="Apellido Materno"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="1">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intActivo"
                    label="Activo"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="1">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intCajero"
                    label="Cajero"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intDescuento"
                    label="Permiso Descuento"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intCancelacion"
                    label="Permiso Cancelacion"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    outlined
                    label="NIP"
                    v-model="entity.strNIP"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="Usuario"
                    @click:prepend="fillDataUser"
                    v-model="entity.strUsuario"
                    readonly
                    prepend-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    :items="terminales"
                    v-model="entity.terminales"
                    label="Terminales"
                    item-text="strNombre"
                    multiple
                    attach
                    chips
                    item-value="intPOSTerminal"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </UI>
</template>

<script>
import { POSMesero, POSTerminal, fillObject } from "../../controllers/AdminController";
import UI from "../../components/AdminUI.vue";
import Search from "../../components/SearchComponent.vue";
import Loader from "../../components/Loader.vue";
import { Toast, ErrorWeb } from "../../controllers/DataController.js";

export default {
  data() {
    return {
      search: {
        visible: false,
      },
      searchUser: {
        visible: false,
      },
      loading: false,
      entities: [],
      entitiesUser: [],
      headers: [
        { text: "Nombre", value: "strNombre" },
        { text: "Apellido Paterno", value: "strApellidoPaterno" },
        { text: "Apellido Materno", value: "strApellidoMaterno" },
      ],
      headersUser: [{ text: "Usuario", value: "strUsuario" }],
      entity: new POSMesero(),
      terminales: [],
      posTerminal: new POSTerminal(),
    };
  },
  async mounted() {
    var vm = this;
    window.addEventListener("keydown", async function (event) {
      // NOTE: metaKey == Command on Mac
      if ((event.metaKey || event.ctrlKey) && event.key == "g") {
        event.preventDefault();
        await vm.guardar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "e") {
        event.preventDefault();
        await vm.eliminar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "q") {
        event.preventDefault();
        vm.nuevo();
      }
    });

    this.loading = true;
    try {
      this.terminales = await this.posTerminal.get();
    } catch (e) {
      this.$toast.error(ErrorWeb(e), new Toast());
    } finally {
      this.loading = false;
    }
  },
  methods: {
    nuevo() {
      this.entity = new POSMesero();
    },
    async eliminar() {
      this.loading = true;
      try {
        await this.entity.delete();
        this.$toast.success("Registro eliminado", new Toast());
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        this.entity.strPOSTerminales = "";
        this.entity.terminales.forEach((x) => {
          this.entity.strPOSTerminales =
            this.entity.strPOSTerminales + x.toString() + ",";
        });

        if (this.entity.intPOSMesero == 0) {
          await this.entity.save();
          this.$toast.success("Registro agregado con exito", new Toast());
        } else {
          await this.entity.update();
          this.$toast.success("Registro agregado con exito", new Toast());
        }
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async selectItem(item) {
      this.loading = true;
      try {
        this.search.visible = false;
        this.entity = fillObject(this.entity, item);
        this.entity.strUsuario = await this.entity.username();
        this.entity.terminales = await this.entity.getTerminales();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async selectUser(item) {
      this.entity.strUsuario = item.strUsuario;
      this.entity.intUsuario = item.intUsuario;
      this.searchUser.visible = false;
    },
    async fillData() {
      this.loading = true;
      try {
        this.entities = await this.entity.get();
        this.search.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async fillDataUser() {
      this.loading = true;
      try {
        this.entitiesUser = await this.entity.users();
        this.searchUser.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UI,
    Loader,
    Search,
  },
};
</script>

<style></style>
