<template>
  <v-dialog v-model="localValue.isVisible" width="750">
    <Loader v-model="loading" />
    <v-card>
      <PrinterDialog v-model="printer" :impresoras="impresoras" />
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cortesia</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.usuario"
                        :rules="[() => !!usuario.usuario || 'Llene este campo']"
                        append-icon="mdi-account"
                        label="Usuario"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.password"
                        :rules="[() => !!usuario.password || 'Llene este campo']"
                        append-icon="mdi-shield-lock"
                        type="password"
                        label="Password"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        :items="descuentos"
                        outlined
                        ref="motivo"
                        item-text="strNombre"
                        item-value="intPOSMotivoDescuento"
                        label="Motivo"
                        v-model="motivoCortesia"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        ref="comentario"
                        outlined
                        label="Comentario"
                        v-model="comentario"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" dark block @click="guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Usuario, Session, Toast, ErrorWeb,Dialog } from "../controllers/DataController.js";
import { POSDetFunctions } from "../controllers/POSController";
import PrinterDialog from "./PrinterDialogComponent.vue";

export default {
  props: {
    value: {
      required: true,
    },
    descuentos: {
      type: Array,
      default: () => [],
    },
    impresoras: {
      type: Array,
      default: () => [],
    },
    POSInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localValue: this.value,
      loading: false,
      detFunctions: new POSDetFunctions(),
      printer: new Dialog(0, 0, "", "", ""),
      session: new Session(),
      usuario: new Usuario(),
      motivoCortesia:null,
      comentario:null
    }
  },
  components: {
    Loader,PrinterDialog
  },
  methods: {
    async guardar() {
      this.loading = true;
      try {
        //*Validamos usuario
        const validacion = await this.usuario.validate_descuento();
        if (!validacion) throw "No se pudo validar el usuario, no tiene permisos";

        await this.detFunctions.cortesia(
          this.localValue.POSEnc.intPOSEnc.toString(),
          this.usuario.usuario,
          this.motivoCortesia,
          this.comentario 
        );

        //*Limpiamos
        this.localValue.isVisible = false;
       this.usuario = new Usuario();
       this.$emit("guardar");
      //  this.$emit("guardar", this.localValue);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
