<template>
  <div>
    <Keyboard v-model="keyboard" />
    <v-text-field @click="show" name="name" label="label" id="id"></v-text-field>
    <v-btn elevation="2" @click="keyboard.visible = true"></v-btn>
    <!-- <input type="text" placeholder="Text input" @focus="show" data-layout="normal" />
    <vue-touch-keyboard
      :options="options"
      layout="normal"
      :cancel="hide"
      :accept="accept"
      :input="input"
    /> -->
  </div>
</template>

<script>
// import VueTouchKeyboard from "vue-touch-keyboard";
// // import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"; // load default style

// // Vue.use(VueTouchKeyboard);
import Keyboard from "../components/Keyboard.vue";
export default {
  data() {
    return {
      keyboard: {
        visible: false,
        input: null,
      },
      layout: "normal",
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },
    };
  },
  components: {
    Keyboard,
  },
  methods: {
    show(e) {
      this.keyboard.input = e.target;
      this.keyboard.visible = true;
    },
  },
};
</script>
