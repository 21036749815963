<template>
  <v-dialog persistent v-model="visible" transition="dialog-bottom-transition">
    <v-card color="#eeeeee">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Pago de {{titlePago}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :disabled="!pagoCompleto" text @click="pagar()"> Pagar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <Reservas
        v-model="reservas"
        :reservas="reservasActivas"
        @reservacion="seleccionaReservacion"
      />
      <Confirm
        :visible="confirm.visible"
        :text="confirm.text"
        :title="confirm.title"
        :aceptar-text="confirm.aceptarText"
        :cancelar-text="confirm.cancelarText"
        :event="confirm.event"
        @cancelar="confirm.visible = false"
        @aceptar="eliminarAceptado"
      />
      <v-dialog width="500px" v-model="envio_correo.visible">
        <v-card>
          <v-toolbar dark color="dark">
            <v-btn rounded icon dark @click="envio_correo.visible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Envio de Ticket Electronico</v-toolbar-title>
          </v-toolbar>
          <v-card outlined>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    rounded
                    label="Correo"
                    v-model="correo"
                    placeholder="fulanito@ejemplo.com"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn rounded block color="primary" @click="enviarcorreo()"
                    >ENVIAR</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn rounded block color="success" @click="pagar2()">PAGAR</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col md="4">
              <v-card min-height="100%" hover class="rounded-xl" elevation="50">
                <v-card-title>Totales {{titlePago}}</v-card-title>
                <v-card-text>
                  <v-container class="font-weight-black" fluid>
                    <v-row no-gutters>
                      <v-col>SubTotal</v-col>
                      <v-col>{{ toMoney(localValue.dblSubtotal) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>IVA</v-col>
                      <v-col>{{ toMoney(localValue.dblIVA) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>ISH</v-col>
                      <v-col>{{ toMoney(localValue.dblISH) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Envio</v-col>
                      <v-col>{{ toMoney(localValue.dblEnvio1) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Descuento</v-col>
                      <v-col>({{ toMoney(localValue.dblImporteDescuento) }})</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Total a Pagar</v-col>
                      <v-col>{{ toMoney(totalOrden) }}</v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card min-height="100%" hover class="rounded-xl" elevation="50" v-show="disabled">
                <v-card-title>Propina</v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col>
                        <v-text-field
                          dense
                          rounded
                          min="0"
                          type="number"
                          outlined
                          @keypress="isNumber($event)"
                          prefix="%"
                          v-model="propinaPorcentaje"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-text-field
                          rounded
                          dense
                          min="0"
                          type="number"
                          outlined
                          @keypress="isFloat($event)"
                          prefix="$"
                          v-model="propina"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card min-height="100%" hover class="rounded-xl" elevation="50">
                <v-card-title> Informacion Pago </v-card-title>
                <v-card-text>
                  <v-container class="font-weight-black" fluid>
                    <v-row no-gutters>
                      <v-col>Total</v-col>
                      <v-col>{{ toMoney(totalOrden) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Pago</v-col>
                      <v-col>{{ toMoney(pagado) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>Cambio</v-col>
                      <v-col>{{ toMoney(cambio) }}</v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row >
            <input class="FacCheck"   type="checkbox" v-model="requiere_factura" id="factura-checkbox">
            <label class="FacLab"  for="checkbox">  Solicitar Factura</label>
          </v-row>
          <v-row>
            <v-col :key="tipoPago.strNombre" v-for="tipoPago in tiposPagos" >
              <v-btn
                :color="tipoPago == pagoSeleccionado ? 'secondary' : 'primary'"
                rounded
                light
                block
                @click="seleccionarPago(tipoPago)"
                
              >
              <v-icon left dark v-text="get_icon(tipoPago.strNombre)"></v-icon>
                {{ tipoPago.strNombre }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pagos != []">
            <v-col md="4" :key="index" v-for="(pago, index) in pagos">
              <v-card min-height="100%" hover class="rounded-xl" elevation="50">
                <v-card-title>
                  <v-toolbar flat>
                    <v-toolbar-title
                      >{{ pago.tipoPago.strNombre }}
                      {{ pago.referencia }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon @click="eliminar(pago, index)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-card-title>
                <v-card-text class="text-center">
                  <h2>{{ toMoney(pago.cantidad) }}</h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="pagoSeleccionado != null">
            <v-col>
              <v-text-field
                dense
                rounded
                :label="pagoSeleccionado.strNombre"
                min="0"
                type="number"
                outlined
                @keypress="isFloat($event)"
                prefix="$"
                ref="cantidad"
                v-model="cantidad"
              ></v-text-field>
            </v-col>
            <v-col v-if="btnReferencia">
              <v-btn rounded color="primary" block @click="verReservas()"
                >Ver Reservaciones</v-btn
              >
            </v-col>
            <v-col v-if="mostrar_concepto ">
              <v-text-field
                rounded
                dense
                label="Concepto"
                outlined
                ref="Concepto"
                v-model="strConceptoTransaccion"
              ></v-text-field>
            </v-col>
            <v-col v-if="intPOSReferencia == 1">
              <v-text-field
                rounded
                dense
                label="Referencia"
                outlined
                ref="referencia"
                v-model="referencia"
              ></v-text-field>
            </v-col>
            <v-col v-if="mostrar_terminales">
              <v-select
                dense
                rounded
                ref="terminalpago"
                :items="terminalespago"
                outlined
                item-text="strNombre"
                item-value="intPOSTerminalPago"
                label="Terminal"
                v-model="terminal_seleccionada"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-btn rounded color="primary" dark block @click="guardarPago()"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="mostrar_prosepago">
            <v-col md="4">
              <v-text-field
                rounded
                dense
                label="Nombre"
                outlined
                ref="nombre"
                v-model="referencia"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                rounded
                dense
                label="Correo Electronico"
                outlined
                ref="email"
                v-model="correo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isNumber, isMoney, isFloat } from "../js/utilities";
import Confirm from "./ConfirmComponent.vue";
import Reservas from "./ReservasActivasComponent.vue";
import { Toast, Session, Data } from "../controllers/DataController.js";
import { POSEncPagoFunctions } from "../controllers/POSController.js";
import Cookies from "js-cookie";
import axios from "axios";
var math = require("mathjs");
var Decimal = require("decimal.js");

export default {
  props: {
    value: {
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    terminalespago: {
      type: Array,
      default: () => [],
    },
    tipospago: {
      type: Array,
      default: () => [],
    },
    envio: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Confirm,
    Reservas,
  },
  data() {
    return {
     
      
      mostrar_prosepago: false,
      loading: false,
      localValue: this.value,
      session: new Session(),
      pagoFunctions: new POSEncPagoFunctions(this.$config.api_backend_route),
      facturacion: false,
      requiere_factura: false,
      facturacionModal: {
        visible: true,
      },
      correo: "sincorreo@gmail.com",
      envio_correo: {
        visible: false,
      },
      confirm: {
        visible: false,
        text: "",
        title: "¿Desea eliminar el registro?",
        aceptarText: "Aceptar",
        cancelarText: "Cancelar",
        event: "confirm",
      },
      reservas: {
        visible: false,
      },
      reservasActivas: [],
      tiposPagos: [],
      pagoSeleccionado: null,
      pagos: () => [],
      cantidad: 0.0,
      indexSeleccionado: 0,
      referencia: "",
      intPOSReferencia: 0,
      propina: 0,
      propinaPorcentaje: 0,
      reservacion: 0,
      referenciaDisabled: false,
      btnReferencia: false,
      mostrar_terminales: false,
      terminal_seleccionada: null,
      disabled:true,
      total:0,
      strConceptoTransaccion:"",
      mostrar_concepto: false,
      titlePago: this.$config.ordenTitle,
      config_21: this.$config.opciones.find((c) => c.id == 21)
     
    };
  },
  computed: {
    faltante() {
    console.log("Faltante",math.round(math.chain(this.totalOrden).subtract(this.pagado).done(),2))
      return math.round(math.chain(this.totalOrden).subtract(this.pagado).done(),2);
    },
    
    totalDescuento() {
      return this.localValue.posDet.reduce((a, b) => {
        return a + (b.dblPU * b.dblCantidad * b.dblPorcentajeDescuento) / 100;
      }, 0);
    },
    detalles() {
      return this.localValue.posDet.filter(
        (detalle) => detalle.intEstatus != 8 && detalle.intEstatus != 9
      );
    },
    
    
    // total() {
    //   if (this.detalles.length > 0) {
    //     return this.detalles.reduce((a, b) => {
    //       console.log(b.dblTotal)
    //       let total= math.chain(a).add(math.round(b.dblTotal, 2)).done()
    //       return math.round(total,2)
    //     }, 0);
    //   } else {
    //     return 0.0;
    //   }
    // },
    totalOrden() {
      //* Se le agrega envio
      let total = math.round(this.localValue.dblTotal, 2);
      let propina = math.round(this.propina, 2);
      let orden = math.chain(total).add(propina).done();
     return math.round(orden, 2);
    },
    pagoCompleto() {
      let x = math.round(this.cambio, 2);
      const cambio = new Decimal(x);
      return cambio.greaterThanOrEqualTo(0.0);
    },
    cambio() {
      let pagado = math.round(this.pagado, 2);
      let total = math.round(this.localValue.dblTotal, 2);
      let propina = math.round(this.propina, 2);

      let cambio = math
        .chain(pagado)
        .subtract(total)
        .subtract(propina)
        .done();
      return math.round(cambio, 2);
    },
    pagado() {
      if (this.pagos.length > 0) {
        return this.pagos.reduce((a, b) => {
          let a2 = math.round(a, 2);
          let cantidad = math.round(b.cantidad, 2);
          return math.round(math.chain(a2).add(cantidad).done(), 2);
        }, 0);
      } else {
        return 0;
      }
    },
  },
  watch: {
  
    
    loading: {
      handler(newValue) {
        this.$emit("loading", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
    propinaPorcentaje: {
      handler(value) {
        let subtotal = math.round(this.localValue.dblSubtotal, 2);
        let x = math.round(value, 2);
        let propina = math.chain(subtotal).multiply(x).divide(100).done();
        this.propina = math.round(propina, 2);
      },
      deep: true,
    },
    localValue: {
      handler(newValue) {
        this.pagoSeleccionado = null;
        this.pagos = [];
        this.cantidad = 0.0;
        this.indexSeleccionado = 0;
        this.referencia = "";
        this.reservacion = 0;
        this.terminal_seleccionada = null;
        this.intPOSReferencia = 0;
        this.propina = this.localValue.dblPropina;
        this.propinaPorcentaje = 0;
        this.mostrar_prosepago = false;
        this.correo = "sincorreo@gmail.com";
        this.strConceptoTransaccion = "";
        this.mostrar_concepto = false;
        
        this.$emit("input", newValue);
      },
      deep: true,
    },
     // Nuevo watch para intPOSTipoVenta
  'localValue.intPOSTipoVenta': {
    handler(newValue) {
      // Llama a la función para obtener los tipos de pago en base a intPOSTipoVenta
      this.obtenerTiposPago(newValue); 
    },
    immediate: true, // Si deseas que la función se ejecute inmediatamente al cargar
  },
  },
  methods: {
    isNumber: isNumber,
    isFloat: isFloat,
    toMoney: isMoney,
    getColor(tipoPago) {
      if (this.pagoSeleccionado == null) {
        return "red";
      } else if (tipoPago.strNombre == this.pagoSeleccionado.strNombre) {
        return "green";
      } else {
        return "red";
      }
    },
    get_icon(pago) {
      if (pago.substring(0, 2) == "04" || pago.substring(0, 2) == "28") {
        return "mdi-credit-card";
      } else if (pago.substring(0, 2) == "01") {
        return "mdi-cash";
      } else if (pago == "99-CARGO HABITACION") {
        return "mdi-seat-individual-suite";
      } else {
        return "mdi-alert-circle-outline";
      }
    },
    seleccionarPago(tipopago) {
      try {
        const totalPago = math.chain(this.cantidad).add(this.pagado);
        //Solo pagos en efectivo pueden exceder
        if (totalPago > this.totalOrden) {
          throw (
            "Verifique su pago a agregar, no puede superar el total de la orden, " +
            this.toMoney(this.totalOrden)
          );
        }

        if (this.pagoSeleccionado == null) {
          this.pagoSeleccionado = tipopago;
          this.intPOSReferencia = this.pagoSeleccionado.intPOSReferencia;
          //Asignamos el faltante al importe
          //Si es domicilio ya tenemos la cantidad que paga con
          if (this.localValue.intPOSTipoServicio == 2 && this.config_21.valor==false) {
              this.cantidad = this.localValue.dblPagaCon;
          } else {
            this.cantidad = this.faltante;
          }

          this.mostrar_terminales =
            this.pagoSeleccionado.strNombre.substring(0, 2) == "04" ||
            this.pagoSeleccionado.strNombre.substring(0, 2) == "28";

          this.mostrar_prosepago = this.pagoSeleccionado.strNombre == "04-PROSEPAGO";

          if (this.pagoSeleccionado.strNombre == "99-CARGO HABITACION") {
            this.referenciaDisabled = true;
            this.btnReferencia = true;
          } else {
            this.referenciaDisabled = false;
            this.btnReferencia = false;
          }
          this.mostrar_concepto =this.pagoSeleccionado.strNombre.substring(0, 2) == "03";

        } else if (this.pagoSeleccionado.strNombre == tipopago.strNombre) {
          this.pagoSeleccionado = null;
          this.mostrar_terminales = false;
          this.mostrar_prosepago = false;
          this.correo = "sincorreo@gmail.com";
          this.referencia = null;
          this.referencia = "";
          this.intPOSReferencia = 0;
          this.terminal_seleccionada = null;
          this.mostrar_concepto =false;
          this.strConceptoTransaccion="";
        }
        //this.$refs.cantidad.focus();
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async eliminar(pago, index) {
      
      this.indexSeleccionado = index;
      this.confirm.visible = true;
    },
    async verReservas() {
      this.loading = true;
      try {
        this.reservasActivas = await this.pagoFunctions.getReservas(this.session.empresa);
        this.reservas.visible = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    seleccionaReservacion(reserva) {
      if (reserva.strHabitacion != "") {
        this.referencia = reserva.strHabitacion;
        this.reservacion = reserva.intHotReservacion;
      } else {
        this.referencia = "";
        this.reservacion = 0;
        this.correo = "sincorreo@gmail.com";
        this.$toast.error("La reservacion no tiene habitacion asignada", new Toast());
      }
    },
    eliminarAceptado() {
      this.pagos.splice(this.indexSeleccionado, 1);
      this.confirm.visible = false;
    },
    facturaGenerada(facturaGenerada) {
      const obj = {
        propina: this.propina,
        cambio: this.cambio,
        pagos: this.pagos,
        factura: facturaGenerada,
      };
      this.$emit("pago", obj);
    },
    cerrar() {
      this.$emit("cerrar");
    },
    pagar2() {
      
      
      this.envio_correo.visible = false;
      const obj = {
        propina: this.propina,
        cambio: this.cambio,
        pagos: this.pagos,
        factura: this.requiere_factura,
        
       
        
      };
      console.log("factura",this.requiere_factura)
      console.log("factura",obj)

      this.$emit("pago", obj);
    },
    async enviarcorreo() {
      //Enviamos por axios, pero hay que validar que el correo tenga el formato correcto
      this.loading = true;
      try {
        if (
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.correo
          )
        ) {
          //! CAMBIAR
          await axios.get(
            "../../../TootleCFDUIReports/Utilerias/POSLite_Ticket.aspx?action=POS_EnviarTicket&correo=" +
              this.correo +
              "&id=" +
              this.localValue.intPOSEnc
          );
          this.$toast.success("Correo enviado correctamente", new Toast());
        } else {
          throw "El correo no tiene un formato valido, intente de nuevo";
        }
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
    pagar() {
      if (this.envio.activo) this.envio_correo.visible = true;
      else this.pagar2();
    },
    async guardarPago() {
      try {
        //Si ya existe registrado un efectivo entonces ya no guardamos
        const pagoEfectivo = this.pagos.find(
          (pago) =>
            pago.tipoPago.strNombre == "01-CASH" ||
            pago.tipoPago.strNombre == "01-EFECTIVO"
        );

        if (
          pagoEfectivo != null &&
          (this.pagoSeleccionado.strNombre == "01-CASH" ||
            this.pagoSeleccionado.strNombre == "01-EFECTIVO")
        )
          throw "No pueden existir dos pagos en efectivo, favor de modificar el ya existente";

        let efectivo = false;
        if (
          this.pagoSeleccionado.strNombre == "01-CASH" ||
          this.pagoSeleccionado.strNombre == "01-EFECTIVO"
        )
          efectivo = true;

        if (this.mostrar_terminales && this.terminal_seleccionada == null) {
          this.$refs.terminalpago.focus();
          throw "Indique la terminal que utilizara para cobrar";
        }

        if (this.mostrar_prosepago && this.referencia == "") {
          this.$refs.nombre.focus();
          throw "Indique el nombre del titular de la tarjeta";
        }

        if (this.intPOSReferencia == 1 && this.referencia == "") {
          this.$refs.referencia.focus();
          throw "Indique una referencia para poder agregar el pago";
        }

        if (this.cantidad == 0.0 || this.cantidad == null || this.cantidad == "") {
          this.$refs.cantidad.focus();
          throw "Indique una cantidad para el tipo de pago";
        }

        if (this.mostrar_concepto && this.strConceptoTransaccion == "" && this.mostrar_terminales ) {
          this.$refs.strConceptoTransaccion.focus();
          throw "Indique un concepto para poder agregar el pago";
        }
        const totalPago = math.chain(this.cantidad).add(this.pagado);
        //Solo pagos en efectivo pueden exceder

        if (totalPago > this.totalOrden && !efectivo) {
          throw (
            "Verifique su pago a agregar, no puede superar el total de la orden, " +
            this.toMoney(this.totalOrden)
          );
        }

        let terminal = 0;
        if (this.terminal_seleccionada != null) {
          terminal = this.terminal_seleccionada;
        }

        // if (this.correo == "sincorreo@gmail.com") {
        //   this.$toast.error(
        //     "No se ha indicado un correo, el ticket no llegara a su destino correctamente",
        //     new Toast()
        //   );
        // }

        const pago = {
          tipoPago: this.pagoSeleccionado,
          cantidad: this.cantidad,
          referencia: this.referencia,
          correo: this.correo,
          terminal_seleccionada: terminal,
          reservacion: this.reservacion,
          strConceptoTransaccion:this.strConceptoTransaccion,
          facturacion: this.facturacion
        };
        console.log(this.solicitarFactura)
        console.log(pago.cantidad)
        this.pagos.push(pago);
        console.log( this.pagos)
        this.cantidad = math.chain(totalPago).subtract(this.pagado);
        console.log( this.cantidad.value)
        this.pagoSeleccionado = null;
        this.referencia = "";
        this.correo = "sincorreo@gmail.com";
        this.reservacion = 0;
        this.strConceptoTransaccion = "";
        this.solicitarFactura;
        this.intPOSReferencia = 0;
        this.terminal_seleccionada = null;
        this.mostrar_terminales = false;
        this.mostrar_prosepago = false;
        this.mostrar_concepto=false;
        this.$emit('pago-realizado', { factura: this.factura });
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async obtenerTiposPago() {
     
            const data = new Data();
            const intPOSTipoVenta = this.localValue.intPOSTipoVenta; // Asegúrate de tener localValue definido
            try {
                const tiposPagos = await data.loadTipoPrecio(intPOSTipoVenta);
                console.log(tiposPagos);
                this.tiposPagos = tiposPagos;
                // Maneja los tiposPagos como necesites
            } catch (error) {
                console.error("Error al cargar tipos de pago:", error);
            }
        },
   
    
  
    
  },
  created() {
    // Llama a obtenerTiposPago cuando el componente es creado
    this.obtenerTiposPago();
  },
  
  mounted() {
    this.obtenerTiposPago(); // Asegúrate de que esta función se llame al montar el componente

    this.empresa = Cookies.get("empresaPOS");
   
    let config = this.$config.opciones.find((c) => c.id == 18)
    if (config.valor) {
        this.disabled = true;
    } else {
      this.disabled = false;
    }
    //indica si muestra campo de concepto en transferencias
    // let config_21 = this.$config.opciones.find((c) => c.id == 21)
    if (this.config_21.valor) {
        this.mostrar_concepto = true;
    } else {
      this.mostrar_concepto = false;
    }
  },
  
};
</script>

<style>

.FacCheck{
  margin: 0px 10px 0px 15px; 
  width: 25px;
  
}

.FacLab{
  font-size: 20px;
}



</style>
