class Configuracion {
	constructor(_id,_descripcion,_valor,_activo) {
		return {
			id: _id,
			descripcion: _descripcion,
			valor: _valor,
			activo: _activo
		}
	}
} 

class LabelConfigurable {
	constructor(_label, _valor) {
		return {
			label: _label,
			valor: _valor
		}
	}
}

export { Configuracion, LabelConfigurable }