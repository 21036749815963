<template>
  <v-card min-width="100%" hover class="rounded-xl" color="white" elevation="50">
    <v-card-title>
      <v-container fluid>
        <v-row v-if="!disabled">
          <v-col v-for="tipo in tipos_visible" :key="tipo.valor">
            <v-btn
              rounded
              small
              block
              :color="tipo.color"
              @click="set_tipo(tipo.valor)"
              >{{ tipo.label }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <transition-group name="slide-fade">
        <Mesa
          key="mesa-component"
          v-show="localValue.tipo == 'mesa'"
          v-model="localValue.mesapago"
          :mesas="mesas"
          :meseros="meseros"
          :disabled="disabled"
        />
        <Llevar
          key="llevar-component"
          v-show="localValue.tipo == 'llevar'"
          v-model="localValue.claveLlevar"
          :data="tiposVenta"
          :disabled="disabled"
        />
        <Domicilio
          :orden="orden"
          :repartidores="repartidores"
          :colonias="colonias"
          :disabled="disabled"
          :tipo="tipodomicilio"
          :impresoras="impresoras"
          :POSInfo="POSInfo"
          key="domicilio-component"
          v-model="localValue.domicilio"
          v-show="localValue.tipo == 'domicilio'"
          @saleRepa="saleRepa"
        />
        <Reserva
          v-if="config_11"
          key="reservacion-component"
          :disabled="disabled"
          v-show="localValue.tipo != ''"
          v-model="localValue.reservacion"
        />
      </transition-group>
    </v-card-text>
  </v-card>
</template>

<script>
import Mesa from "../components/MesaPagoComponent.vue";
import Llevar from "../components/LlevarPagoComponent.vue";
import Domicilio from "../components/DomicilioPagoComponent.vue";
import { DomicilioPago } from "../controllers/DataController";
import Reserva from "../components/ReservaComponent.vue";
import { Toast } from "../controllers/DataController";

export default {
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orden: {
      type: Object,
      default: () => {},
    },
    mesas: {
      type: Array,
      default: () => [],
    },
    meseros: {
      type: Array,
      default: () => [],
    },
    repartidores: {
      type: Array,
      default: () => [],
    },
    tipodomicilio: {
      type: Object,
      default: () => {},
    },
    colonias: {
      type: Array,
      default: () => [],
    },
    tiposVenta: {
      type: Array,
      default: () => [],
    },
    impresoras: {
      type: Array,
      default: () => [],
    },
    POSInfo: {
      type: Object,
      default: () => {},
    },
    // clientes: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
     
      localValue: this.value,
      tipos: this.$config.servicios,
      config_11: false,
   
    };
  },
  components: {
    Reserva,
    Mesa,
    Llevar,
    Domicilio,
  },
  methods: {
    set_tipo(x) {
      this.localValue.tipo = x;
    },
    saleRepa() {
      this.$emit("saleRepa",  this.localValue.domicilio);
      //console.log("repa")
    },
    
  },
  watch: {
    ["localValue.tipo"](newValue) {
      if (newValue == null || newValue == "") {
        this.localValue.reservacion = {
          strApellidoReferencia: "",
          strHabitacion: "",
          intHotReservacion: 0,
        };
        this.localValue.domicilio = new DomicilioPago();
      }
    },
    ["orden.intPOSEnc"](newValue) {
      if (newValue > 0) {
        if (this.localValue.tipo == "domicilio") {
          this.localValue.domicilio.strTelefono = this.orden.cliente.strTelefono;
          this.localValue.domicilio.strDireccion = this.orden.cliente.strDireccion;
          this.localValue.domicilio.strTelefono2 = this.orden.cliente.strTelefono2;
          this.localValue.domicilio.intEmpleado = this.orden.intEmpleado;

          this.localValue.domicilio.strEntreCalle = this.orden.cliente.strEntreCalle;
          this.localValue.domicilio.strColonia = this.orden.cliente.strColonia;
          this.localValue.domicilio.strPoblacion = this.orden.cliente.strPoblacion;
          this.localValue.domicilio.strNombre = this.orden.cliente.strNombre;
          this.localValue.domicilio.strReferencia = this.orden.cliente.strReferencia;
          this.localValue.domicilio.strRFC= this.orden.cliente.strRFC
        }
      }
    },
    reservacion(newValue) {
      this.$emit("change_reservacion", newValue);
    },
    mesa_value(newValue) {
      this.$emit("change_mesa", newValue);
    },
    llevar_value(newValue) {
      this.$emit("change_llevar", newValue);
    },
    domicilio_value(newValue) {
      this.$emit("change_domicilio", newValue);
    },
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
   
  },
  mounted() {
    try {
      let config = this.$config.opciones.find((c) => c.id == 11);
      if (config.valor) {
        this.config_11 = true;
      } else {
        this.config_11 = false;
      }
    } catch (e) {
      this.$toast.error("Error en configuracion 11. " + e, new Toast());
    }
  },
   computed: {
    tipos_visible() {
      return this.tipos.filter((x) => {
        if (x.visible) {
          return x;
        }
      });
    },
  },
};
</script>

<style>
.rounded-card {
  border-radius: 50px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
