<template>
  <div>
    <Loader v-model="loading" />
    <Keyboard v-model="keyboard" :layout="layout" />

    <PrinterDialog
      :terminal="terminal"
      @closed="despuesImpresion"
      @finished="despuesImpresion"
      v-model="printer"
      :impresoras="impresoras"
    />

    <PrinterDialog
      :terminal="terminal"
      @closed="despuesImpresionCorte"
      @finished="despuesImpresionCorte"
      v-model="printerCorte"
      :impresoras="impresoras"
    />
    <PrinterDialog
      :terminal="terminal"
      @closed="despuesImpresionGlobal"
      @finished="despuesImpresionGlobal"
      v-model="printerGlobal"
      :impresoras="impresoras"
    />

    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card shaped class="justify-center rounded-xl">
            <v-toolbar dark color="primary">
              <!-- <v-icon @click="goBack">mdi-keyboard-backspace</v-icon> -->
              <v-spacer></v-spacer>
              <v-toolbar-title v-if="opcion == 1">Registro Caja</v-toolbar-title>
              <v-toolbar-title v-else-if="opcion == 2">Arqueo</v-toolbar-title>
              <v-toolbar-title v-else-if="opcion == 3">Cierre Turno</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  dense
                  label="Billetes de 500"
                  outlined
                  rounded
                  v-model="registro.dblDenominacion500"
                  @keypress="isNumber($event)"
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 200"
                  outlined
                  :disabled="opcion == 3"
                  @click="showKeyboard($event, 'numeric')"
                  v-model="registro.dblDenominacion200"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 100"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion100"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 50"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion50"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 20"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion20"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Monedas"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblMonedas"
                  @keypress="isFloat($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  v-if="opcion == 3"
                  dense
                  label="Otros Pagos"
                  outlined
                  v-model="registro.dblOtrosPagos"
                  @keypress="isFloat($event)"
                  rounded
                  readonly
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Comentario"
                  @click="showKeyboard($event, 'compact')"
                  v-model="registro.strComentario"
                  outlined
                  id="comentario"
                  ref="nota"
                  rounded
                  type="text"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      readonly
                      v-model="totalRegistrado"
                      label="Total"
                      outlined
                      rounded
                      ref="total"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn rounded block color="primary" @click="registrarCaja()"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
var numeral = require("numeral");
 var moment = require("moment");
var Decimal = require("decimal.js");
import Cookies from "js-cookie";
import Keyboard from "../components/Keyboard.vue";

import { POSRegister, POSRegisterFunctions } from "../controllers/RegisterController.js";
import { POSTerminalFunctions,POSValidaciones } from "../controllers/POSController.js";
import { isNumber, isFloat } from "../js/utilities";
// import DialogUI from "../components/DialogComponent.vue";
import Loader from "../components/Loader.vue";
import PrinterDialog from "../components/PrinterDialogComponent.vue";
import { Data, Dialog, Toast, Session, ErrorWeb } from "../controllers/DataController.js";
// import Decimal from 'decimal.js';

export default {
  data() {
    return {
      keyboard: {
        visible: false,
        input: null,
      },
      layout: "numeric",
      session: new Session(),
      opcion: this.$route.params.opcion,
      printer: new Dialog(0, 0, "", "", ""),
      printerCorte: new Dialog(0, 0, "", "", ""),
      printerGlobal: new Dialog(0, 0, "", "", ""),
      impresoras: [],
      nombreTerminal: "",
      corteGlobal: false,
      errorLogin: false,
      registroActual: 0,
      datacierre: null,
      datacierreCorte: null,
      datacierreGlobal: null,
      registro: new POSRegister(),
      registerFunctions: new POSRegisterFunctions(),
      terminalFunctions: new POSTerminalFunctions(),
      validaciones: new POSValidaciones(),
      dataFunctions: new Data(),
      intentos: 1,
      intentosMaximos: 3,
      loading: false,
      terminal: "",
      poliza_Atm:this.$config.poliza_Atm,
      poliza_MP:this.$config.poliza_MP,
      ocultaMesero:this.$config.opciones.find((c) => c.id == 24).valor,
      fecha: moment().format("yyyy-MM-DD"),
      config:null,
      habilitaCorteGlobal:false,
      hour: moment().format('H'),
      pagosGlobal:[],
      fondo_Caja:this.$config.opciones.find((c) => c.id == 25),
      config_21 :this.$config.opciones.find((c) => c.id == 21),
      config_29 :this.$config.opciones.find((c) => c.id == 29)
    
      
    };
  },
  components: {
    // DialogUI,
    Keyboard,
    PrinterDialog,
    Loader,
  },
  methods: {
    showKeyboard(e, layout) {
      if (this.keyboardSession) {
        if (this.opcion != 3 || e.target.id == "comentario") {
          this.layout = layout;
          this.keyboard.input = e.target;
          this.keyboard.visible = true;
        }
      }
    },
    keyboardMode() {
      this.keyboardSession = !this.keyboardSession;
      let session = new Session();
      session.setKeyboard(this.keyboardSession);
    },
    goBack() {
      if (this.opcion == 1) this.$router.push("/Login");
      else if (this.opcion == 2) this.$router.push("/Main");
      else if (this.opcion == 3) this.$router.push("/Main");
    },
    isNumber: isNumber,
    isFloat: isFloat,
    convertDateJSON(POSRegister) {
      if (POSRegister.DatFechaInicio.toString().includes("T")) {
        return POSRegister.DatFechaInicio.toString();
      } else {
        let x = POSRegister.DatFechaInicio.split("/");
        let response =
          x[2] + "-" + x[1] + "-" + x[0] + "T" + POSRegister.DatHora + ".511Z";
        return response;
      }
    },
    irLogin() {
      Cookies.remove("userPOS");
      Cookies.remove("empresaPOS");
      Cookies.remove("terminalPOS");
      Cookies.remove("aperturaCaja");
      this.$router.push("/login");
    },
    cerrarSesion() {
      Cookies.remove("userPOS");
      this.$router.push("/login");
    },
    despuesImpresionGlobal(event) {
      if (event == "corteGlobal") {
        console.log("cierreglobal")
        this.$router.push("/Login");
      }
    },
    despuesImpresionCorte() {
      if (this.habilitaCorteGlobal == false ) {
        console.log("cierreTurno")
        this.$router.push("/Login");
      }
    },
    despuesImpresion(event) {
      if (event == "apertura") {
        if(this.ocultaMesero)
          this.$router.push("/Main");
        else
          this.$router.push("/RegistroMesero");
      } else if (event == "arqueo") {
        this.$router.push("/Main");
      } 
    },

    async generarPoliza_Atm()
    {
      // if (this.poliza_Atm) {
          await this.registerFunctions.generarPoliza(this.session.register,this.session.usuario);
      // }
    },
  
    async registrarCaja() {
      this.loading = true;
      try {
        // * MCZ -> Agregar comentario obligatorio
        if (this.registro.strComentario == "") {
          this.$refs.nota.focus();
          throw "Favor de agregar un comentario";
        }
        // * MCZ -> Registramos POSRegister
        // ** MCZ ->  1 Apertura
        if (this.opcion == 1) {
          // const REGISTER_ACTUAL = await this.registerFunctions.register_actual(
          //   this.session.empresa,
          //   this.session.terminal
          // );
          const REGISTER_ACTUAL = await this.registerFunctions.register_actualUsuario(
            this.session.empresa,
            this.session.terminal,this.session.usuario
          );

          if (REGISTER_ACTUAL.intPOSRegister != 0) {
            throw "No se puede crear una nueva apertura, ya existe una en esta caja";
          }
          this.registro.intPOSTipoMovimientoTurno = 1;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.dblImporte = this.totalRegistradoV2;
          
          if (this.fondo_Caja.activo  &&  this.registro.dblImporte  < this.fondo_Caja.valor ) {
          this.$refs.total.focus();
          console.log( this.registro.dblImporte )
          throw "Favor de capturar fondo de caja. Monto: " + this.fondo_Caja.valor ;
        }
          let POSRegister = await this.registerFunctions.save(this.registro);

          Cookies.set("POS_REGISTER_CAJA", POSRegister.intPOSRegister, {
            expires: 5,
          });
          POSRegister.strTerminal = this.nombreTerminal.strNombre;
          // *? Verificar si va directo, esto solo es pruebas
          this.printer.event = "apertura";
          this.printer.visible = true;
          this.printer.printable = POSRegister;
          this.printer.after_event = true;
          //          this.$router.push("/registromesero");
        }
        // ** MCZ ->  2 Arqueo
        if (this.opcion == 2) {
          this.registro.intPOSTipoMovimientoTurno = 2;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.intPOSRegisterPadre = this.session.register;
          this.registro.dblImporte = this.totalRegistradoV2;

          let POSRegister = await this.registerFunctions.save(this.registro);
          //await TicketFunctions.ticketArqueo(POSRegisterResult, this.nombreTerminal)
          this.printer.event = "arqueo";
          this.printer.visible = true;
          this.printer.printable = POSRegister;
          this.printer.after_event = true;
        }
        // ** MCZ ->  3 Cierre de turno
        if (this.opcion == 3) {
          this.registro.intPOSTipoMovimientoTurno = 3;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.intPOSRegisterPadre = this.session.register;
          this.registro.dblImporte = this.totalRegistradoV2;

          //* Validaciones de cierre
          this.datacierre = await this.registerFunctions.cierre();

          if (this.habilitaCorteGlobal== true) 
            this.registro.intCorteGlobal=1
          else 
            this.registro.intCorteGlobal=0

          //* Retiros + Tarjetas = Venta
          const retiros = Decimal(this.datacierre.dblRetiros);
          const tarjetas =Decimal(this.registro.dblOtrosPagos);
          const venta = Decimal(this.datacierre.dblVenta);

          console.log(retiros.plus(tarjetas).toString())
          
          console.log(venta.toString())

          const dblImporteEntregado = this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas;
         console.log("entregado " + dblImporteEntregado +  "venta " + this.datacierre.dblVenta)
          if(dblImporteEntregado != this.datacierre.dblVenta){
          // if (retiros.plus(tarjetas).toString() != venta.toString()) {
            if (this.intentos < this.intentosMaximos) {
              var usuarios="Corte global inhabilitado, usuarios activos. "+ this.datacierre.strRegistersAbiertos ;
              const intento = this.intentos;
              this.intentos++;
              // throw (
              //   "Verifique las cantidades, tienen una diferencia. Intentos: " + intento
              // );

              if (this.hour >= this.datacierre.intHora || this.hour < 4) {
               throw ("Verifique las cantidades, tienen una diferencia.  "+ usuarios +  "Intentos: " + intento );
              }
              else {
                throw (
                  "Verifique las cantidades, tienen una diferencia. Intentos: " + intento
                );
              }
             
            }
            //* Guardamos con diferencia y notificamos al usuario
            else {
              if(this.config_29.valor ==true){
                  if( this.datacierre.intRegistersAbiertos == 1 && (this.hour >= this.datacierre.intHora  || this.hour < 4 ))
                  {
                    console.log("corte habilitado")
                    this.$toast.success("Es usted el ultimo usuario en cerrar. Corte global habilitado", new Toast());
                  }
                  else if (this.hour >= this.datacierre.intHora || this.hour < 4) {
                    console.log("corte inhabilitado")
                    this.$toast.error("Corte global inhabilitado, usuarios activos. "+ this.datacierre.strRegistersAbiertos , new Toast(),);
                  }
              }
              console.log("entro diferencias")
              
              const dblImporteEntregado = this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas;
              console.log("Calculo",dblImporteEntregado)
              this.registro.dblDiferencia =dblImporteEntregado- this.datacierre.dblVenta;
                // (this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas)-
                // - this.datacierre.dblVenta
              this.registro.dblImporteEntregado = dblImporteEntregado;
              console.log(  "importe", this.registro.dblImporteEntregado)
                //this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas;
              this.registro.dblImporte= this.registro.dblImporteEntregado;
              let POSRegister = await this.registerFunctions.save(this.registro);
              POSRegister.strTerminal = this.nombreTerminal.strNombre;
              POSRegister.pagos = await this.registerFunctions.pagos();
              POSRegister.salidas = await this.registerFunctions.salidas();
              console.log(  POSRegister.salidas )
              POSRegister.dblVentas = await this.registerFunctions.ventas();
              POSRegister.intTotalOrdenesVenta= this.datacierre.intTotalOrdenesVenta;
              POSRegister.intTotalPlatillosVenta= this.datacierre.intTotalPlatillosVenta;
              POSRegister.intCortesiaCta= this.datacierre.intCortesiaCta;
              POSRegister.intCortesiaPlat= this.datacierre.intCortesiaPlat;

              // this.printer.event = "cierre";
              // this.printer.visible = true;
              // this.printer.printable == POSRegister;

               await this.PrintCorte(POSRegister,this.datacierre.datFechaInicioTurno)
              
             if (this.habilitaCorteGlobal== true) 
              {
                // await new Promise(r => setTimeout(r, 10000 / 2));
                await this.PrintCorteGlobal(POSRegister,this.datacierre.datFechaInicioTurno)

                 if (this.poliza_MP) {
                    await this.polizaMP(this.session.sucursal,this.datacierre.datFechaInicioTurno);
                 }
              }
              if (this.poliza_Atm) {
                this.generarPoliza_Atm()
              }

              this.session.logout();

            }
          }
          //* Guardamos correctamente
          else { 
            if(this.config_29.valor ==true){
                  if( this.datacierre.intRegistersAbiertos == 1 && (this.hour >= this.datacierre.intHora  || this.hour < 4 ))
                  {
                    console.log("corte habilitado")
                    this.$toast.success("Es usted el ultimo usuario en cerrar. Corte global habilitado", new Toast());
                  }
                  else if (this.hour >= this.datacierre.intHora || this.hour < 4) {
                    console.log("corte inhabilitado")
                    this.$toast.error("Corte global inhabilitado, usuarios activos. "+ this.datacierre.strRegistersAbiertos , new Toast(),);
                  }
              }
            console.log("entro sin diferencias")
            const dblImporteEntregado = this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas;
         console.log("entregado " + dblImporteEntregado +  "venta " + this.datacierre.dblVenta)
            this.registro.dblImporteEntregado = dblImporteEntregado;
              console.log(  "importe", this.registro.dblImporteEntregado)
              console.log(  "importeqry", this.datacierre.dblImporteEntregado)
           // this.registro.dblImporteEntregado =
              //  this.datacierre.dblRetiros + this.registro.dblOtrosPagos+this.datacierre.dblSalidas;
            this.registro.dblImporte=dblImporteEntregado;
            //this.registro.dblImporte = this.registro.dblImporteEntregado;//this.totalRegistradoV2;
            let POSRegister = await this.registerFunctions.save(this.registro);
            POSRegister.strTerminal = this.nombreTerminal.strNombre;
            POSRegister.pagos = await this.registerFunctions.pagos();
            POSRegister.dblVentas = await this.registerFunctions.ventas();
            POSRegister.salidas = await this.registerFunctions.salidas();
              console.log(  POSRegister.salidas )
            POSRegister.intTotalOrdenesVenta= this.datacierre.intTotalOrdenesVenta;
            POSRegister.intTotalPlatillosVenta= this.datacierre.intTotalPlatillosVenta;
            POSRegister.intCortesiaCta= this.datacierre.intCortesiaCta;
            POSRegister.intCortesiaPlat= this.datacierre.intCortesiaPlat;

              // this.printer.event = "cierre";
              // this.printer.visible = true;
              // this.printer.printable = POSRegister;
              
            await this.PrintCorte(POSRegister,this.datacierre.datFechaInicioTurno)
              
             if (this.habilitaCorteGlobal== true) 
              {
                // await new Promise(r => setTimeout(r, 10000 / 2));
                await this.PrintCorteGlobal(POSRegister,this.datacierre.datFechaInicioTurno)

                 if (this.poliza_MP) {
                    await this.polizaMP(this.session.sucursal,this.datacierre.datFechaInicioTurno);
                 }
              }
              if (this.poliza_Atm) {
                this.generarPoliza_Atm()
              }

              this.session.logout();
          }

        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async polizaMP()
    {
        await this.registerFunctions.polizaAutomaticaMP(this.session.sucursal,this.datacierre.datFechaInicioTurno);
    },
    
    async PrintCorte(response,fecha) {
      this.loading = true;
      try {
          this.datacierreCorte =  await this.registerFunctions.corteGlobal(response.intPOSRegisterPadre, fecha);
           
            response.dblVenta=this.datacierreCorte.dblVenta,
            response.dblVentaSinImpuesto=this.datacierreCorte.dblVentaSinImpuesto,
            response.intEnvios=this.datacierreCorte.intEnvios,
            response.dblEnvios=this.datacierreCorte.dblEnvios,
            response.dblVentaConImpuesto=this.datacierreCorte.dblVentaConImpuesto,
            response.intTotalOrdenesVenta=this.datacierreCorte.intTotalOrdenesVenta,
            response.intTotalPlatillosVenta=this.datacierreCorte.intTotalPlatillosVenta,
            response.intPromCta=this.datacierreCorte.intPromCta,
            response.dblPromCta=this.datacierreCorte.dblPromCta,
            response.intCortesiaCta=this.datacierreCorte.intCortesiaCta,
            response.dblCortesiaCta=this.datacierreCorte.dblCortesiaCta,
            response.intCortesiaPlat=this.datacierreCorte.intCortesiaPlat,
            response.dblCortesiaPlat=this.datacierreCorte.dblCortesiaPlat,
            response.intCancelacionCta=this.datacierreCorte.intCancelacionCta,
            response.dblCancelacionCta=this.datacierreCorte.dblCancelacionCta,
            response.intCancelacionPlat=this.datacierreCorte.intCancelacionPlat,
            response.dblCancelacionPlat=this.datacierreCorte.dblCancelacionPlat,
            response.intDescuentoCta=this.datacierreCorte.intDescuentoCta,
            response.dblDescuentoCta=this.datacierreCorte.dblDescuentoCta,
            response.intDescuentoPlat=this.datacierreCorte.intDescuentoPlat,
            response.dblDescuentoPlat=this.datacierreCorte.dblDescuentoPlat,
            response.intDevolucionCta=this.datacierreCorte.intDevolucionCta,
            response.dblDevolucionCta=this.datacierreCorte.dblDevolucionCta,
            response.intDevolucionPlat=this.datacierreCorte.intDevolucionPlat,
            response.dblDevolucionPlat=this.datacierreCorte.dblDevolucionPlat,
            response.intOrdenesDomicilio=this.datacierreCorte.intOrdenesDomicilio,
            response.dblTotalDomicilio=this.datacierreCorte.dblTotalDomicilio,
            response.intOrdenesLLevar=this.datacierreCorte.intOrdenesLLevar,
            response.dblTotalLLevar=this.datacierreCorte.dblTotalLLevar,
            response.intOrdenesMesa=this.datacierreCorte.intOrdenesMesa,
            response.dblTotalMesa=this.datacierreCorte.dblTotalMesa,
            response.dblFondoCaja=this.datacierreCorte.dblFondoCaja,
            response.dblEntregaReal=this.datacierreCorte.dblEntregaReal,
            response.dblDiferencia=this.datacierreCorte.dblDiferencia,
            response.intRetiroObligatorio=this.datacierreCorte.intRetiroObligatorio,
            response.dblRetiroObligatorio=this.datacierreCorte.dblRetiroObligatorio,
            response.dblComisiones=this.datacierreCorte.dblComisiones,
            response.intIntentos=this.datacierreCorte.intIntentos,
            response.dblCompras=this.datacierreCorte.dblCompras,
            response.intOrdenesRappi=this.datacierreCorte.intOrdenesRappi,
            response.dblTotalRappi=this.datacierreCorte.dblTotalRappi
            response.intOrdenesUber=this.datacierreCorte.intOrdenesUber,
            response.dblTotalUber=this.datacierreCorte.dblTotalUber
            response.intOrdenesDidi=this.datacierreCorte.intOrdenesDidi,
            response.dblTotalDidi=this.datacierreCorte.dblTotalDidi

            if(this.config_21.valor)
              this.printerCorte.event = "corteTurnoDicex";
            else
              this.printerCorte.event = "corteTurno";

            this.printerCorte.visible = true;
            this.printerCorte.printable =response;
            
            if (this.habilitaCorteGlobal == false ) {
              this.printerCorte.after_event = true;
              console.log("entrocorte")
            }
           

      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
 
    async PrintCorteGlobal(global,fecha) {
      this.loading = true;
      try {
        global.pagos= await this.registerFunctions.pagosGlobal();
          this.datacierreCorteGlobal =  await this.registerFunctions.corteGlobal(0,fecha);
        // console.log(fecha)
          console.log(global.pagos)
          global.dblVenta=this.datacierreCorteGlobal.dblVenta,
          global.dblVentaSinImpuesto=this.datacierreCorteGlobal.dblVentaSinImpuesto,
          global.intEnvios=this.datacierreCorteGlobal.intEnvios,
          global.dblEnvios=this.datacierreCorteGlobal.dblEnvios,
          global.dblVentaConImpuesto=this.datacierreCorteGlobal.dblVentaConImpuesto,
          global.intTotalOrdenesVenta=this.datacierreCorteGlobal.intTotalOrdenesVenta,
          global.intTotalPlatillosVenta=this.datacierreCorteGlobal.intTotalPlatillosVenta,
          global.intPromCta=this.datacierreCorteGlobal.intPromCta,
          global.dblPromCta=this.datacierreCorteGlobal.dblPromCta,
          global.intCortesiaCta=this.datacierreCorteGlobal.intCortesiaCta,
          global.dblCortesiaCta=this.datacierreCorteGlobal.dblCortesiaCta,
          global.intCortesiaPlat=this.datacierreCorteGlobal.intCortesiaPlat,
          global.dblCortesiaPlat=this.datacierreCorteGlobal.dblCortesiaPlat,
          global.intCancelacionCta=this.datacierreCorteGlobal.intCancelacionCta,
          global.dblCancelacionCta=this.datacierreCorteGlobal.dblCancelacionCta,
          global.intCancelacionPlat=this.datacierreCorteGlobal.intCancelacionPlat,
          global.dblCancelacionPlat=this.datacierreCorteGlobal.dblCancelacionPlat,
          global.intDescuentoCta=this.datacierreCorteGlobal.intDescuentoCta,
          global.dblDescuentoCta=this.datacierreCorteGlobal.dblDescuentoCta,
          global.intDescuentoPlat=this.datacierreCorteGlobal.intDescuentoPlat,
          global.dblDescuentoPlat=this.datacierreCorteGlobal.dblDescuentoPlat,
          global.intDevolucionCta=this.datacierreCorteGlobal.intDevolucionCta,
          global.dblDevolucionCta=this.datacierreCorteGlobal.dblDevolucionCta,
          global.intDevolucionPlat=this.datacierreCorteGlobal.intDevolucionPlat,
          global.dblDevolucionPlat=this.datacierreCorteGlobal.dblDevolucionPlat,
          global.intOrdenesDomicilio=this.datacierreCorteGlobal.intOrdenesDomicilio,
          global.dblTotalDomicilio=this.datacierreCorteGlobal.dblTotalDomicilio,
          global.intOrdenesLLevar=this.datacierreCorteGlobal.intOrdenesLLevar,
          global.dblTotalLLevar=this.datacierreCorteGlobal.dblTotalLLevar,
          global.intOrdenesMesa=this.datacierreCorteGlobal.intOrdenesMesa,
          global.dblTotalMesa=this.datacierreCorteGlobal.dblTotalMesa,
          global.dblFondoCaja=this.datacierreCorteGlobal.dblFondoCaja,
          global.dblEntregaReal=this.datacierreCorteGlobal.dblEntregaReal,
          global.dblDiferencia=this.datacierreCorteGlobal.dblDiferencia,
          global.intRetiroObligatorio=this.datacierreCorteGlobal.intRetiroObligatorio,
          global.dblRetiroObligatorio=this.datacierreCorteGlobal.dblRetiroObligatorio,
          global.dblComisiones=this.datacierreCorteGlobal.dblComisiones,
          global.intIntentos=this.datacierreCorteGlobal.intIntentos,
          global.dblCompras=this.datacierreCorteGlobal.dblCompras
          global.intOrdenesRappi=this.datacierreCorteGlobal.intOrdenesRappi,
          global.dblTotalRappi=this.datacierreCorteGlobal.dblTotalRappi
          global.intOrdenesUber=this.datacierreCorteGlobal.intOrdenesUber,
          global.dblTotalUber=this.datacierreCorteGlobal.dblTotalUber
          global.intOrdenesDidi=this.datacierreCorteGlobal.intOrdenesDidi,
          global.dblTotalDidi=this.datacierreCorteGlobal.dblTotalDidi
          
          if(this.config_21.valor)
            this.printerGlobal.event = "corteGlobalDicex";
            else
             this.printerGlobal.event = "corteGlobal";

            
            this.printerGlobal.visible = true;
            this.printerGlobal.printable =global;
            this.printerGlobal.after_event = true;

      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    
  },
  computed: {
    totalRegistrado() {
      let total =
        this.registro.dblDenominacion500 * 500 +
        this.registro.dblDenominacion200 * 200 +
        this.registro.dblDenominacion100 * 100 +
        this.registro.dblDenominacion50 * 50 +
        this.registro.dblDenominacion20 * 20 +
        parseFloat(this.registro.dblMonedas) +
        parseFloat(this.registro.dblOtrosPagos);
      return numeral(total).format("$0,0.00");
    },
    totalRegistradoV2() {
      let total =
        this.registro.dblDenominacion500 * 500 +
        this.registro.dblDenominacion200 * 200 +
        this.registro.dblDenominacion100 * 100 +
        this.registro.dblDenominacion50 * 50 +
        this.registro.dblDenominacion20 * 20 +
        parseFloat(this.registro.dblMonedas) +
        parseFloat(this.registro.dblOtrosPagos);
      return numeral(total).format("0.00");
    },
  },
  async mounted() {
    this.keyboardSession = new Session().keyboard;
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.config = this.$config.opciones.find((x) => x.id == 2);
        if (!this.session.usuario) throw "No existe una sesion abierta";

        this.terminalFunctions.retrieve().then((res) => {
          this.nombreTerminal = res;
        });

        if (this.session.registro) this.registroActual = this.session.registro;
        if(this.opcion == 2 && this.config_21.valor){
          let folio = await this.registerFunctions.folioArqueo();
          this.registro.strComentario = "Arqueo " + folio
        }
        
        if (this.opcion == 3) {
          this.datacierre = await this.registerFunctions.cierre();
          this.registro.dblOtrosPagos = this.datacierre.dblTarjetas;
           var hour = moment().format('H');
          console.log(this.datacierre.intHora )
          console.log("Hora ",hour)
          console.log("this.hora ",this.hour)
          console.log("conf",this.config_29)
          console.log("conf",this.config_29.valor)
          //Indica se se realiza el corte global
         if(this.config_29.valor ==true){
            if( this.datacierre.intRegistersAbiertos == 1 && (this.hour >= this.datacierre.intHora  || this.hour < 4 ))
            {
              console.log("corte habilitado")
              this.$toast.success("Es usted el ultimo usuario en cerrar. Corte global habilitado", new Toast());
              this.habilitaCorteGlobal=true;
            }
            else if (this.hour >= this.datacierre.intHora || this.hour < 4) {
             // this.saludar("cierre");
              console.log("corte inhabilitado")
             this.$toast.error("Corte global inhabilitado, usuarios activos. "+ this.datacierre.strRegistersAbiertos , new Toast(),);
              this.habilitaCorteGlobal=false;
            }
         }else this.habilitaCorteGlobal=false;
          console.log(this.habilitaCorteGlobal)

        }
        this.impresoras = await this.dataFunctions.loadImpresoras();
        let salidas= await this.registerFunctions.salidas();
              console.log( salidas )
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
  saludar(event) {
      // `this` dentro de los métodos apunta a la instancia de Vue
      if(event == "Cierre")
        alert('Hola Corte global inhabilitado')
      // `evento` es el evento DOM nativo
      // if (event) {
      //   alert(event.target.tagName)
      // }
    },
  created() {
    if (
      this.$route.params.opcion != 1 &&
      this.$route.params.opcion != 2 &&
      this.$route.params.opcion != 3
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
