<template>
  <div>
    <Loader v-model="loading" />
    <v-dialog v-model="confirm" persistent max-width="650">
      <v-card>
        <v-card-title class="text-h5" v-if="tab == 0">Entrada</v-card-title>
        <v-card-title class="text-h5" v-if="tab == 1">Salida</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                Desea realizar esta accion con
                {{ articulosInventario.length }} articulos?
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Comentario"
                  v-model="comentario"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirm = false"> No </v-btn>
          <v-btn v-if="tab == 0" color="green darken-1" text @click="entradas(2)">
            Si
          </v-btn>
          <v-btn v-if="tab == 1" color="green darken-1" text @click="salidas(2)">
            Si
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Inventario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
        <template v-slot:extension>
          <v-tabs fixed-tabs v-model="tab">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab> Entrada </v-tab>
            <v-tab> Salida </v-tab>
            <v-tab> Actual </v-tab>
            <v-tab> Movimientos </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid v-show="tab == 0">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :search="search"
                        :headers="entrada_headers"
                        :items="articulos"
                      >
                        <template v-slot:[`item.dblCosto`]="{ item }">
                          {{ isMoney(item.dblSubtotal) }}
                        </template>
                        <template v-slot:[`item.dblCantidad`]="{ item }">
                          <v-row>
                            <v-col>
                              <v-text-field
                                outlined
                                dense
                                min="0"
                                @keypress="isNumber($event)"
                                type="number"
                                v-model="item.dblCantidad"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="secondary" @click="entradas(1)">Agregar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-show="tab == 1">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :search="search2"
                        :headers="salida_headers"
                        :items="articulos"
                      >
                        <template v-slot:[`item.dblCosto`]="{ item }">
                          {{ isMoney(item.dblSubtotal) }}
                        </template>
                        <template v-slot:[`item.dblCantidad`]="{ item }">
                          <v-row>
                            <v-col>
                              <v-text-field
                                outlined
                                dense
                                min="0"
                                @keypress="isNumber($event)"
                                type="number"
                                v-model="item.dblCantidad"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="secondary" @click="salidas(1)">Salida</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-show="tab == 2">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table :headers="saldos_headers" :items="saldos">
                        <template v-slot:[`item.dblExistencias`]="{ item }">
                          {{ item.dblEntradas - item.dblSalidas }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-show="tab == 3">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="movimientos_headers"
                        :items="movimientos"
                        show-group-by
                      >
                        <template v-slot:[`item.intTipoMovimiento`]="{ item }">
                          <v-chip :color="getColor(item.intTipoMovimiento)" dark>
                            {{ getLabel(item.intTipoMovimiento) }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.datFechaAlta`]="{ item }">
                          {{ toDateString(item.datFechaAlta, 2) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSInventarioFunctions } from "../controllers/POSController";
import { isMoney, isNumber, toDateString } from "../js/utilities";

export default {
  data() {
    return {
      session: new Session(),
      inventarioFunctions: new POSInventarioFunctions(),
      tab: null,
      confirm: false,
      search: "",
      search2: "",
      loading: false,
      articulos: [],
      movimientos: [],
      comentario: null,
      entrada_headers: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
        { text: "Costo", value: "dblCosto" },
        { text: "Entrada", value: "dblCantidad" },
      ],
      salida_headers: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
        { text: "Costo", value: "dblCosto" },
        { text: "Salida", value: "dblCantidad" },
      ],
      movimientos_headers: [
        { text: "Tipo", value: "intTipoMovimiento" },
        { text: "Fecha", value: "datFechaAlta" },
        { text: "Folio", value: "intFolio" },
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
        { text: "Cantidad", value: "dblCantidad" },
        { text: "Usuario", value: "strUsuarioPOS" },
        { text: "Comentario", value: "strComentario" },
      ],
      saldos_headers: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
        { text: "Entradas", value: "dblEntradas" },
        { text: "Salidas", value: "dblSalidas" },
        { text: "Existencias", value: "dblExistencias" },
      ],
      results: [],
      saldos: [],
    };
  },
  computed: {
    articulosInventario() {
      return this.articulos
        .filter((x) => x.dblCantidad > 0)
        .map((obj) => ({ ...obj, strComentario: this.comentario }));
    },
  },
  components: {
    Loader,
  },
  watch: {
    async tab(newVal) {
      if (newVal == 0) {
        this.articulos.forEach((x) => (x.dblCantidad = 0));
        this.search2 = "";
      } else if (newVal == 1) {
        this.articulos.forEach((x) => (x.dblCantidad = 0));
        this.search = "";
      } else if (newVal == 2) {
        this.loading = true;
        try {
          this.saldos = await this.inventarioFunctions.saldos();
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      } else if (newVal == 3) {
        this.loading = true;
        try {
          this.movimientos = await this.inventarioFunctions.movimientos();
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    toDateString: toDateString,
    isMoney: isMoney,
    isNumber: isNumber,
    async entradas(paso) {
      if (paso == 1) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.loading = true;
        try {
          if (this.articulosInventario.length == 0) {
            throw "No se ha hecho ninguna entrada";
          }
          await this.inventarioFunctions.entrada(this.articulosInventario);
          this.$toast.success(
            "Se realizaron " + this.articulosInventario.length + " entradas",
            new Toast()
          );
          this.articulos.forEach((x) => (x.dblCantidad = 0));
          this.comentario = null;
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    async salidas(paso) {
      if (paso == 1) {
        this.confirm = true;
      } else {
        this.confirm = false;

        this.loading = true;
        try {
          if (this.articulosInventario.length == 0) {
            throw "No se ha hecho ninguna salida";
          }
          await this.inventarioFunctions.salida(this.articulosInventario);
          this.$toast.success(
            "Se realizaron " + this.articulosInventario.length + " entradas",
            new Toast()
          );
          this.articulos.forEach((x) => (x.dblCantidad = 0));
          this.comentario = null;
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    getColor(x) {
      if (x == 1) {
        return "primary";
      } else {
        return "secondary";
      }
    },
    getLabel(x) {
      if (x == 1) {
        return "Entrada";
      } else {
        return "Salida";
      }
    },
    goBack() {
      this.$router.push("/Main");
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.articulos = await this.inventarioFunctions.articulos();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
