// db.js
import Dexie from 'dexie';

export const db = new Dexie('tpv');

db.version(10).stores({
  articulos: 'intArticulo, strNombre, strNombreCorto, dblPU, dblPF, dblPorcentajeIVA, intFamilia, strFamilia, intOrdenImpresion, strImagen, strCodigoBarras, intArticuloConfiguracion,dblPorcentajeISH,intPOSImpresora', 
  grupos: 'intFamilia, intOrdenImpresion, strFamilia',
  tiposPago: 'intTipoPago, intPOSReferencia, strImagenPago, strNombre',
  motivosDescuento: 'intPOSMotivoDescuento, Folio, strNombre',
  motivosDevolucion: 'intPOSMotivoCancelacion, intFolio, strNombre',
  colonias: 'intPOSColonia, intFolio, strColonia,dblEnvio',
  impresoras: 'name, address, port, url,intPOSImpresora',
  meseros: 'intPOSMesero, intFolio, intActivo, strActivo, strApellidoMaterno, strApellidoPaterno, strNombre, strUsuario',
  terminalPago: 'intPOSTerminalPago, bolGetnet, datFechaAlta, datFechaMod, intEmpresa, intSucursal, strLicencia, strMaquinaAlta, strMaquinaMod, strNoSerie, strNombre, strNombreCorto, strUsuarioAlta, strUsuarioMod',
  tiposVenta: 'intPOSTipoVenta, intEmpresa, intSucursal, strNombre, strNombreCorto, datFechaAlta, datFechaMod, strUsuarioAlta, strUsuarioMod, strMaquinaAlta, strMaquinaMod, intTipoPrecio',
  clientes:'intCliente,strNombre,strDireccion,strEntreCalle,strTelefono,strRFC,strColonia,strReferencia,strPoblacion,strTelefono2',
  mesas:'label,value',
  repartidores:'intFolio,strNombreCompleto,intEmpleado',
  articulo_configuraciones:"intArticuloConfiguracion,intArticuloConfiguracionPadre,intArticuloConfiguracionMaster,intArticulo,strNombre,strNombreCorto,dblPrecio,DblPrecio2,DblPrecio3,DblPrecio4,DblPrecio67,strImagen,strClaveArticulo,intPaso,intCantidadSeleccionable,intOrden",
  // Primary key and indexed props
});



