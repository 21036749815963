<template>
  <UI :entity="entity">
    <template v-slot:main="{ entity }">
      <Search
        @selected="selectItem"
        :headers="headers"
        :rows="entities"
        v-model="search"
      />
      <Loader v-model="loading" />
      <v-container fluid>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-card-title dark> Tipo Pago </v-card-title>
            <v-btn icon dark @click="fillData()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon dark @click="nuevo()">
              <v-icon>mdi-new-box</v-icon>
            </v-btn>
            <v-btn icon dark @click="guardar()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon dark @click="eliminar()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strNombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intPOSReferencia"
                    label="Referencia"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-select
                    outlined
                    :items="[
                      { value: 0, text: 'NO' },
                      { value: 1, text: 'SI' },
                    ]"
                    v-model="entity.intActivo"
                    label="Activo"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    outlined
                    v-model="entity.strSATCodigo"
                    label="Codigo SAT"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </UI>
</template>

<script>
import { TipoPago, fillObject } from "../../controllers/AdminController";
import UI from "../../components/AdminUI.vue";
import Search from "../../components/SearchComponent.vue";
import Loader from "../../components/Loader.vue";
import { Toast, ErrorWeb } from "../../controllers/DataController.js";

export default {
  data() {
    return {
      search: {
        visible: false,
      },
      loading: false,
      entities: [],
      headers: [
        { text: "Nombre", value: "strNombre" },
        { text: "Codigo SAT", value: "strSATCodigo" },
        { text: "Activo", value: "intActivo" },
      ],
      entity: new TipoPago(),
    };
  },
  mounted() {
    var vm = this;
    window.addEventListener("keydown", async function (event) {
      // NOTE: metaKey == Command on Mac
      if ((event.metaKey || event.ctrlKey) && event.key == "g") {
        event.preventDefault();
        await vm.guardar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "e") {
        event.preventDefault();
        await vm.eliminar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "q") {
        event.preventDefault();
        vm.nuevo();
      }
    });
  },
  methods: {
    nuevo() {
      this.entity = new TipoPago();
    },
    async eliminar() {
      this.loading = true;
      try {
        await this.entity.delete();
        this.$toast.success("Registro eliminado", new Toast());
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        if (this.entity.intTipoPago == 0) {
          await this.entity.save();
          this.$toast.success("Registro agregado con exito", new Toast());
        } else {
          await this.entity.update();
          this.$toast.success("Registro agregado con exito", new Toast());
        }
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    selectItem(item) {
      this.search.visible = false;
      this.entity = fillObject(this.entity, item);
    },
    async fillData() {
      this.loading = true;
      try {
        this.entities = await this.entity.get();
        this.search.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UI,
    Loader,
    Search,
  },
};
</script>

<style></style>
