<template>
  <v-dialog v-model="localValue.visible" persistent width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Orden Domicilio</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                dense
                rounded
                label="Total"
                min="0"
                type="number"
                outlined
                @keypress="isFloat($event)"
                prefix="$"
                readonly
                v-model="localValue.total"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                rounded
                label="CLIENTE PAGA CON"
                min="0"
                type="number"
                outlined
                @keypress="isFloat($event)"
                prefix="$"
                v-model="localValue.pagacon"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                rounded
                label="CAMBIO"
                min="0"
                type="number"
                outlined
                @keypress="isFloat($event)"
                prefix="$"
                readonly
                v-model="cambio"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="continuar" :disabled="cambio < 0" block
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { isFloat } from "../js/utilities";
var math = require("mathjs");
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    cambio() {
      return math.chain(this.localValue.pagacon).subtract(this.localValue.total).done();
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    isFloat: isFloat,
    continuar() {
      this.$emit("continuar", {
        cambio: this.cambio,
        total: this.localValue.total,
        pagaCon: this.localValue.pagacon,
      });
    },
  },
};
</script>

<style></style>
