<template>
  <v-dialog v-model="localValue.visible">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Reservacion</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="reservas"
                :search="search"
                @click:row="seleccionaReserva"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    reservas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.value,
      selected: null,
      search: "",
      headers: [
        { text: "Habitacion", value: "strHabitacion" },
        { text: "Apellido", value: "strApellidoReferencia" },
        { text: "Regimen", value: "strRegimen" },
        { text: "Ocupantes", value: "intOcupantes" },
        { text: "Trato", value: "trato" },
        { text: "Folio", value: "intFolio" },
        { text: "Desglose", value: "intDesglose" },
      ],
    };
  },
  methods: {
    cancelar() {
      this.$emit("cancelar", this.event);
    },
    aceptar() {
      this.$emit("aceptar", this.event);
    },
    seleccionaReserva(seleccion) {
      this.$emit("reservacion", seleccion);
      this.localValue.visible = false;
      this.search = "";
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
