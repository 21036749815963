<template>
  <UI :entity="entity">
    <template v-slot:main="{ entity }">
      <Loader v-model="loading" />
      <Search
        @selected="selectItem"
        :headers="headers"
        :rows="entities"
        v-model="search"
      />
      <v-dialog v-model="confirm" max-width="290">
        <v-card>
          <v-card-title>Confirmacion</v-card-title>
          <v-card-text>
            <p>Desea eliminar este registro?</p>
            <p>
              <b>{{ deleteItem.strNombre }}</b>
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="confirm = false">Cancelar</v-btn>

            <v-btn color="green darken-1" text @click="eliminarConfig">Si</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-card-title dark> Configuracion de Articulo </v-card-title>
            <v-btn icon dark @click="fillData()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon dark @click="nuevo()">
              <v-icon>mdi-new-box</v-icon>
            </v-btn>
            <v-btn icon dark @click="guardar()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon dark @click="eliminar()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Clave"
                    v-model="entity.strNombreCorto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="entity.strNombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-treeview
                    :key="treeKey"
                    item-key="intArticuloConfiguracion"
                    item-text="strNombre"
                    item-children="childs"
                    activatable
                    ref="tree"
                    :items="tree"
                  >
                    <template v-slot:label="{ item }">
                      <router-link
                        :to="{
                          name: 'ConfiguracionArticuloChild',
                          params: { id: item.intArticuloConfiguracion },
                        }"
                      >
                        {{ item.strNombre }}</router-link
                      >
                    </template>
                    <template v-slot:prepend="{ item, open }">
                      <v-icon v-if="item.childs.length > 0">
                        {{ open ? "mdi-folder-open" : "mdi-folder" }}
                      </v-icon>
                      <v-icon v-else> mdi-file </v-icon>
                    </template>
                    <template v-slot:append="{ item }">
                      <v-btn @click="agregarHijo(item)" fab dark small color="green">
                        <v-icon dark> mdi-human-child </v-icon>
                      </v-btn>
                      <v-btn @click="confirmEliminar(item)" fab dark small color="red">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                  </v-treeview>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </UI>
</template>

<script>
import { ArticuloConfiguracion, fillObject } from "../../controllers/AdminController";
import UI from "../../components/AdminUI.vue";
import Search from "../../components/SearchComponent.vue";
import Loader from "../../components/Loader.vue";
import { Toast, ErrorWeb } from "../../controllers/DataController.js";
import { guid } from "../../js/utilities";

function getChild(father, configuraciones) {
  return configuraciones
    .filter(
      (item) => item.intArticuloConfiguracionPadre == father.intArticuloConfiguracion
    )
    .sort((a, b) => (a.intOrden > b.intOrden ? 1 : -1));
}

export default {
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      treeKey: guid(),
      search: {
        visible: false,
      },
      tree: [],
      loading: false,
      entities: [],
      headers: [
        { text: "Clave", value: "strNombreCorto" },
        { text: "Nombre", value: "strNombre" },
      ],
      entity: new ArticuloConfiguracion(),
      confirm: false,
      deleteItem: new ArticuloConfiguracion(),
      deleteTree: null,
    };
  },
  watch: {
    editModal(newValue) {
      if (!newValue) {
        this.editItem = null;
      }
    },
    addModal(newValue) {
      if (!newValue) {
        this.addItem = new ArticuloConfiguracion();
      }
    },
  },
  mounted() {
    var vm = this;
    window.addEventListener("keydown", async function (event) {
      // NOTE: metaKey == Command on Mac
      if ((event.metaKey || event.ctrlKey) && event.key == "g") {
        event.preventDefault();
        await vm.guardar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "e") {
        event.preventDefault();
        await vm.eliminar();
      } else if ((event.metaKey || event.ctrlKey) && event.key == "q") {
        event.preventDefault();
        vm.nuevo();
      }
    });
  },
  async created() {
    if (this.$route.params.id) {
      this.entity.intArticuloConfiguracion = this.$route.params.id;
      let item = await this.entity.retrieve();
      await this.selectItem(item);
    }
  },
  methods: {
    agregarHijo(item) {
      this.$router.push({
        name: "ConfiguracionArticuloChild",
        params: { id: item.intArticuloConfiguracion },
        query: { parenting: true },
      });
    },
    async confirmEliminar(item) {
      this.deleteTree = item;
      this.deleteItem = fillObject(this.deleteItem, item);
      this.confirm = true;
    },
    async eliminarConfig() {
      this.confirm = false;
      this.loading = true;
      try {
        await this.deleteItem.deleteTree(this.deleteTree);
        this.$toast.success("Registro eliminado", new Toast());
        //* Recargamos el arbol
        this.tree = this.tree.filter(
          (x) => x.intArticuloConfiguracion != this.deleteItem.intArticuloConfiguracion
        );
        this.tree.forEach((x) => {
          x.childs.forEach((y) => {
            y.childs = y.childs.filter(
              (z) =>
                z.intArticuloConfiguracion != this.deleteItem.intArticuloConfiguracion
            );
          });
          x.childs = x.childs.filter(
            (x) => x.intArticuloConfiguracion != this.deleteItem.intArticuloConfiguracion
          );
        });
        this.treeKey = guid();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    nuevo() {
      this.entity = new ArticuloConfiguracion();
    },
    async editar(item) {
      this.loading = true;
      this.editItem = item;
      try {
        this.editItem.intMultiple = 0;
        console.log(item);
        this.articulo.intArticulo = item.intArticulo;
        if (this.articulo.intArticulo > 0) {
          let articulo = await this.articulo.retrieve();
          this.editItem.strNombreArticulo = articulo.strNombre;
          this.editItem.strNombreCortoArticulo = articulo.strNombreCorto;
        }
        if (
          !this.editItem.intCantidadSeleccionable ||
          this.editItem.intCantidadSeleccionable == 0
        ) {
          this.editItem.intMultiple = 1;
        }
        this.editModal = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async eliminar() {
      this.loading = true;
      try {
        await this.entity.delete();
        this.$toast.success("Registro eliminado", new Toast());
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async guardar() {
      this.loading = true;
      try {
        if (this.entity.intArticuloConfiguracion == 0) {
          await this.entity.save();
          this.$toast.success("Registro agregado con exito", new Toast());
        } else {
          await this.entity.update();
          this.$toast.success("Registro agregado con exito", new Toast());
        }
        this.nuevo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async selectItem(item) {
      if (this.$route.params.id != item.intArticuloConfiguracion) {
        this.$router.push({
          name: "ConfiguracionArticuloRetrieve",
          params: { id: item.intArticuloConfiguracion },
        });
      }

      this.loading = true;
      try {
        this.search.visible = false;
        this.entity = fillObject(this.entity, item);
        //Llenamos el tree
        let configuraciones = await this.entity.getTree();
        this.tree = getChild(this.entity, configuraciones);
        this.tree.forEach((x) => {
          x.childs = getChild(x, configuraciones);
          x.childs.forEach((y) => {
            y.childs = getChild(y, configuraciones);
            y.childs.forEach((z) => {
              z.childs = getChild(z, configuraciones);
            });
          });
        });
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async fillData() {
      this.loading = true;
      try {
        this.entities = await this.entity.get();
        this.search.visible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UI,
    Loader,
    Search,
  },
};
</script>

<style></style>
