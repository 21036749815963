var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.session.version == 'desktop')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.listaImpresion()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,false,1595079345)},[_c('span',[_vm._v("Lista de Impresion")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.refresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1473563052)},[_c('span',[_vm._v("Refrescar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.nuevo()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-new-box")])],1)]}}],null,false,2025115375)},[_c('span',[_vm._v("Nueva Orden")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.mobile()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cellphone")])],1)]}}],null,false,3207359414)},[_c('span',[_vm._v("Version Mobile")])])],1):_c('div',[_c('v-toolbar',{attrs:{"dense":"","color":"secondary"}},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.listaImpresion()}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',[_vm._v("mdi-new-box")])],1),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.desktop()}}},[_c('v-icon',[_vm._v("mdi-monitor")])],1),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.ordenes()}}},[_c('v-icon',[_vm._v("mdi-arrow-expand-all")])],1),_c('v-spacer')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }