<template>
  <div>
    <Loader v-model="loading" />
    <PrinterDialog v-model="printer" :impresoras="impresoras" />


    <v-dialog v-model="edit" persistent max-width="290">
      <v-card>
        <v-card-title> Editar </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field outlined 
                  v-model="ordenFunctions.strReferencia"
                  label="Referencia">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="edit = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="editConfirm()"> Aceptar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Corte de Vouchers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <!-- <v-text-field
                        v-model="ordenFunctions.intOrden"
                        label="Orden"
                        outlined
                        @click="ordenes(ordenFunctions.intOrden) "
                      /> -->
                      <v-text-field
                      v-model="ordenFunctions.intOrden"
                      :append-icon="'mdi-magnify'"
                      label="Orden"
                      :clear-icon="'mdi-close-circle'"
                      clearable
                      outlined
                      @click:clear="clear()"
                      @click:append="ordenes(ordenFunctions.intOrden)"
                    ></v-text-field>
                    </v-col>
                     <v-col>
                      <v-select
                        v-model="ordenFunctions.intPOSEncPago"
                        :items="pagos"
                        label="Pagos"
                        item-text="strPagos"
                        item-value="intPOSEncPago"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    
                    <v-col>
                      <v-text-field
                        v-model="ordenFunctions.dblImporte"
                        label="Importe"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="ordenFunctions.strReferencia"
                        label="Referencia"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <v-btn x-large block color="primary" @click="agregar()">
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn x-large block color="primary" @click="confirmar()">
                        Confirmar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table :headers="headers" :items="results" item-key="id">
                        <template v-slot:[`item.dblImporte`]="{ item }">
                          {{ isMoney(item.dblImporte) }}
                        </template>
                        <template v-slot:[`item.dblTotal`]="{ item }">
                          {{ isMoney(item.dblTotal) }}
                        </template>
                        <template v-slot:[`item.strNombre`]="{ item }">
                          {{ item.strNombre.substring(3, item.lenght) }}
                        </template>
                        <!-- <template v-slot:[`item.intTipoPago`]="{ item }">
                          {{ item.intTipoPago }}
                        </template> -->
                        <template v-slot:[`item.intPOSEncPago`]="{ item }">
                          <!-- <v-icon small @click="editItem(item)"> mdi-pencil </v-icon> -->
                          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                        </template>
                        <template v-slot:[`body.append`]="{headers}">
                          <tr class="summary">
                              <td v-for="(header,i) in headers" :key="i">
                                <div v-if="header.text == 'Importe'">
                                {{isMoney( sumField('dblImporte') )}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb,Dialog,Data } from "../controllers/DataController.js";
import { POSEncFunctions ,POSEnc,POSInfo} from "../controllers/POSController";
import { isMoney } from "../js/utilities";
import PrinterDialog from "../components/PrinterDialogComponent.vue";

export default {
  props: {
    value: {
      default: "",
    },
  },
  data() {
    return {
      session: new Session(),
      loading: false,
      ordenFunctions: new POSEncFunctions(),
      POSEnc: new POSEnc(),
      dataFunctions: new Data(),
      printer: new Dialog(0, 0, "", "", ""),
      POSInfo: new POSInfo(),
      edit: false,
      confirm: false,
      headers: [
        {text: "Orden", value: "intOrden" },
        {text: "Total Orden", value: "dblTotal" },
        {text: "Tipo", value: "strNombre" },
        {text: "Importe", value: "dblImporte" },
        {text: "Referencia", value: "strReferencia" },
        // { text: "a", value: "intTipoPago",align: ' d-none'  },
        {text: "Acciones", value: "intPOSEncPago" },
        {text: "", value: "intPOSEnc",align: ' d-none' },
      ],
      results: [],
      impresoras: [],
      pagos:[],
     
    }
    
  },
  components: {
    Loader,PrinterDialog
  },
  watch: {
    ["ordenFunctions.intPOSEncPago"](newVal) {
      if(newVal)
      {
        let pago = this.pagos.find(x => x.IntPOSEncPago=newVal)
        this.ordenFunctions.intPOSEnc= pago.intPOSEnc;
        this.ordenFunctions.intPOSEncPago= pago.intPOSEncPago;
        this.ordenFunctions.intTipoPago=pago.intTipoPago;
        this.ordenFunctions.dblImporte  = (pago.dblImporte- pago.dblCambio);
        this.ordenFunctions.strReferencia  = pago.strReferencia;
        this.ordenFunctions.strSATCodigo=pago.strSATCodigo;
      }
      else 
      this.ordenFunctions = new POSEncFunctions();

    },
  },
  methods: {
    clear(){
      this.ordenFunctions = new POSEncFunctions();

    },
    sumField(key) {
        // sum data in give key (property)
        return this.results.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumFields (key) {
    let total = 0
    const sum = this.results.reduce((acc, cur) => {
        return (total += +cur[key])
    }, 0)
    return sum
},
    async ordenes(intOrden) {
      this.loading = true;
      try { 
        if(intOrden>0){
        let  result = await this.ordenFunctions.retrieve(intOrden);
        let   POSEnc = await this.ordenFunctions.retrieve_id(result.intPOSEnc);
        //Revisa Cancelacion y devolucion
        if (POSEnc.IntCancelacionDevolucion != 0)
          {
              if (POSEnc.IntCancelacionDevolucion == 9)
                throw "Imposible. La orden " + POSEnc.IntOrden + "fue Cancelada.";
              
              if (POSEnc.IntCancelacionDevolucion == 8)
                throw "Imposible. La orden " + POSEnc.IntOrden + "fue Devuelta.";
          }
          if (POSEnc.IntPOSDescuento == 9)
            throw "Imposible. La orden " + POSEnc.IntOrden + "tiene un descuento de 100%.";

        let array = await this.ordenFunctions.pago(result.intPOSEnc);
         if (array.length > 2){
            throw "No es posible modificar esta orden, la orden cuenta con mas de 2 pagos";
        }
        
        else 
          this.pagos=array
      }
      else 
        this.ordenFunctions = new POSEncFunctions();
    } catch (e) {
        this.$toast.error( "No existe la orden " + intOrden + " el dia de hoy",new Toast());
      } finally {
        this.loading = false;
      }
    },
    isMoney: isMoney, 
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
      );
    },
    async confirmar() {
      this.loading = true;
      try {

        await this.ordenFunctions.confirmaVouchers();
        let  dblTotalVoucher=0;
            for( let item of this.results){
                dblTotalVoucher = dblTotalVoucher + item.dblImporte
            }
        this.POSEnc.dbltotal=dblTotalVoucher;

        this.POSEnc.POSInfo = this.POSInfo;
        this.POSEnc.StrTerminal = this.session.terminal
        this.POSEnc.strUsuarioPOS = this.session.usuario;
        this.POSEnc.datFecha =this.toIsoString(new Date());
        this.printer.event ="voucher";
        this.printer.visible = true;
        this.printer.log = "Voucher";
        this.printer.printable =  this.POSEnc;
       
        this.$toast.success("Se han confirmado los pagos en tarjeta", new Toast());
            
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      console.log(item.intTipoPago)
      this.ordenFunctions.intPOSEnc = item.intPOSEnc;
      this.ordenFunctions.intPOSEncPago = item.intPOSEncPago;
      this.ordenFunctions.strReferencia = item.strReferencia;
      this.ordenFunctions.intTipoPago = item.intTipoPago;
      this.edit = true;
    },

    async deleteItem(item) {
      this.loading = true;
      try {
        this.ordenFunctions.intEmpresa = this.session.empresa;
        this.ordenFunctions.strUsuarioMod = this.session.usuario;
        this.ordenFunctions.intPOSRegister = this.session.register;

        this.ordenFunctions.intPOSEnc = item.intPOSEnc;
        this.ordenFunctions.intPOSEncPago = item.intPOSEncPago;
        this.ordenFunctions.strSATCodigo='01',
        this.ordenFunctions.dblImporte = item.dblImporte;
        this.ordenFunctions.strReferencia =null;
       

        await this.ordenFunctions.UpdateCierrePagos(
          this.ordenFunctions.intPOSEnc,
          this.ordenFunctions.intPOSEncPago,
          this.ordenFunctions.strSATCodigo,
          this.ordenFunctions.dblImporte,
          this.ordenFunctions.strReferencia
        );
        this.ordenFunctions = new POSEncFunctions();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.ordenFunctions.vouchers();
        this.edit = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async editConfirm() {
      this.loading = true;
      try {
        this.ordenFunctions.intEmpresa = this.session.empresa;
        this.ordenFunctions.strUsuarioMod = this.session.usuario;
        this.ordenFunctions.intPOSRegister = this.session.register;
        
        await this.ordenFunctions.actualizaVouchers();
        this.ordenFunctions = new POSEncFunctions();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.ordenFunctions.vouchers();
        this.edit = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async agregar() {
      this.loading = true;
      try {
        if(this.ordenFunctions.strSATCodigo=='04' || this.ordenFunctions.strSATCodigo=='28')
        throw "No se puede modificar ordenes con formas de pago con tarjetas";

        //this.ordenFunctions.intTipoPago = 15;
        await this.ordenFunctions.UpdateCierrePagos(
          this.ordenFunctions.intPOSEnc,
          this.ordenFunctions.intPOSEncPago,
          this.ordenFunctions.strSATCodigo='04',
          this.ordenFunctions.dblImporte,
          this.ordenFunctions.strReferencia
        );
        this.ordenFunctions = new POSEncFunctions();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.ordenFunctions.vouchers();
        this.edit = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async deleteitem() {
      this.loading = true;
      try {
        this.ordenFunctions.intEmpresa = this.session.empresa;
        this.ordenFunctions.strUsuarioMod = this.session.usuario;
        this.ordenFunctions.intPOSRegister = this.session.register;
       
        this.ordenFunctions.strReferencia = "";
        this.ordenFunctions.intTipoPago=1
        await this.ordenFunctions.actualizaVouchers();
        this.ordenFunctions = new POSEncFunctions();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.ordenFunctions.vouchers();
        this.edit = false;
        
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push("/Main");
    },
    
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.impresoras = await this.dataFunctions.loadImpresoras();
        this.results = await this.ordenFunctions.vouchers();
        this.POSInfo = await this.dataFunctions.loadDataPOSInfo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
