<template>
  <div>
    <Loader v-model="loading" />
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md8>
          <v-card shaped class="justify-center rounded-xl">
            <v-toolbar dark color="primary">
              <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
              <v-spacer></v-spacer>
              <v-toolbar-title>Registro Mesero</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-data-table
                  v-model="meserosRegister"
                  :headers="headers"
                  :items="meserosTerminal"
                  :single-select="singleSelect"
                  item-key="intPOSMesero"
                  show-select
                  class="elevation-1"
                >
                  <template v-slot:[`item.strNombre`]="{ item }">
                    {{ item.strNombre + " " + item.strApellidoPaterno }}
                  </template>
                  <template v-slot:top>
                    <v-btn @click="guardar()" block color="success">Guardar</v-btn>
                  </template>
                </v-data-table>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { POSRegisterFunctions } from "../controllers/RegisterController.js";
import { Data, Toast, Session, ErrorWeb } from "../controllers/DataController";
import Loader from "../components/Loader.vue";

export default {
  data() {
    return {
      loading: false,
      meserosTerminal: [],
      meserosRegister: [],
      session: new Session(),
      singleSelect: false,
      dataFunctions: new Data(),
      registerFunctions: new POSRegisterFunctions(),
      headers: [
        { text: "Nombre", value: "strNombre" },
        { text: "Usuario", value: "strUsuario" },
      ],
    };
  },
  components: {
    Loader,
  },
  methods: {
    goBack() {
      this.$router.push("/Main");
    },
    async guardar() {
      this.loading = true;
      try {
        await this.registerFunctions.saveMeseros(this.meserosRegister);
        //* Redirigimos al main
        this.$router.push("/Main");
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        //* Cargamos los meseros de la terminal
        this.meserosTerminal = await this.dataFunctions.loadMeserosTerminal();
        //* Cargamos los meseros del register
        this.meserosRegister = await this.dataFunctions.loadMeserosRegister();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
