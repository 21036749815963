<template>
  <v-dialog v-model="localValue.visible" max-width="400">
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Autorizacion</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div class="row">
          <div class="col">
            <v-text-field
              v-model="localValue.strUsuario"
              :rules="[() => !!localValue.strUsuario || 'Llene este campo']"
              append-icon="mdi-account"
              label="Usuario"
              outlined
              required>
            </v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-text-field
              v-model="localValue.strPassword"
              :rules="[() => !!localValue.strPassword || 'Llene este campo']"
              append-icon="mdi-shield-lock"
              type="password"
              label="Password"
              outlined
              required>
            </v-text-field>
          </div>
        </div>
         <div class="row">
          <div class="col">
            <v-btn block color="primary" @click="autorizar()"> Autorizar </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Usuario, Session, Toast, ErrorWeb } from "../controllers/DataController.js";

export default {
  props: {
    value: {
      required: true,
    },
    event: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localValue: this.value,
      loading: false,
      session: new Session(),
      usuario: new Usuario(),
    };
  },
  components: {
    Loader,
  },
  methods: {
    async autorizar() {
      this.loading = true;
      try {
        //Validamos que la autorizacion es correcta
        this.usuario.usuario=this.localValue.strUsuario;
        this.usuario.password=this.localValue.strPassword;//validate_Autorizacion
        if (!(await this.usuario.validate_Autorizacion()))
          throw "No se pudo validar el usuario, no tiene permisos";

        this.localValue.strUsuario = "";
        this.localValue.strPassword = "";
         this.localValue.isVisible = false;
        this.usuario = new Usuario();
        this.$emit("autorizar",this.localValue.event);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
  },
};
</script>
