<template>
  <v-dialog width="650" v-model="localValue.visible">
    <v-dialog v-model="modal_auth" width="650">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="modal_auth = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Autorizacion</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                dense
                v-model="auth.usuario"
                :rules="[() => !!auth.usuario || 'Llene este campo']"
                append-icon="mdi-account"
                label="Usuario"
                outlined
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                v-model="auth.password"
                :rules="[() => !!auth.password || 'Llene este campo']"
                append-icon="mdi-shield-lock"
                type="password"
                label="Password"
                outlined
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="success" dark block @click="autorizar()"> Autorizar </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal_sep" width="300">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="modal_sep = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Separar por cantidad</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row v-if="detalle">
            <v-col>
              <v-text-field
                v-if="detalle.dblSeparar"
                outlined
                label="Cantidad"
                v-model="detalle.dblSeparar"
                type="number"
                :max="detalle.dblCantidad"
                min="1"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" dark block @click="guardar_det_separacion()">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Separar Cuenta</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Orden {{ POSEncOriginal.intOrden }}</v-card-title>
              <v-list dense nav>
                <v-list-item
                  v-for="(detalle, index) in POSEncOriginal.POSDet"
                  :key="detalle.intPOSDet"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >({{ detalle.dblCantidad }}) -
                      {{ detalle.strNombre }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary" @click="separar_detalle(index, detalle)"
                      ><v-icon dark>mdi-arrow-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>Nueva Orden</v-card-title>
              <v-list dense nav>
                <v-list-item
                  v-for="(detalle, index) in POSEncNuevo.POSDet"
                  :key="detalle.intPOSDet"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >({{ detalle.dblCantidad }}) -
                      {{ detalle.strNombre }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary" @click="regresar_detalle(index, detalle)"
                      ><v-icon dark>mdi-arrow-left</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block color="primary" @click="separar()">SEPARAR</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Toast } from "../controllers/DataController.js";
import { POSEnc, POSEncFunctions } from "../controllers/POSController.js";
import { POSUserFunctions } from "../controllers/UserController.js";

export default {
  props: {
    empresa: {
      type: Number,
      default: 1,
    },
    terminal: {
      type: String,
      default: "",
    },
    posenc: {
      type: Object,
      default: () => {},
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localValue: this.value,
      modal_auth: false,
      modal_sep: false,
      detalle: null,
      ordenFunctions: new POSEncFunctions(this.$config.api_backend_route),
      userFunctions: new POSUserFunctions(this.$config.api_backend_route),
      POSEncOriginal: new POSEnc(),
      POSEncNuevo: new POSEnc(),
      auth: {
        usuario: "",
        password: "",
      },
    };
  },
  watch: {
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    ["localValue.visible"](newValue) {
      if (newValue) {
        this.POSEncNuevo = new POSEnc();
        this.POSEncOriginal = new POSEnc();
        this.POSEncOriginal.intOrden = this.posenc.intOrden;
        this.posenc.POSDet.forEach((x) => {
          this.POSEncOriginal.POSDet.push(x);
        });
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    async autorizar() {
      this.loading = true;
      try {
        const responseAuth = await this.userFunctions.autorizaSeparacion(this.empresa, {
          strUsuario: this.auth.usuario,
          strPassword: this.auth.password,
        });
        if (responseAuth.IntError > 0) throw responseAuth.StrMessage;

        this.separacion_proceso();
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
    async separacion_proceso() {
      let POSEncAgregado = null;
      let POSEncAgregadoOriginal = null;
      try {
        //#region Guardamos el nuevo POSEnc
        this.POSEncNuevo.intPOSMesa = this.posenc.intPOSMesa;
        this.POSEncNuevo.intPOSTipoServicio = this.posenc.intPOSTipoServicio;
        this.POSEncNuevo.strMesa = this.posenc.strMesa;
        this.POSEncNuevo.intPOSTipoVenta = this.posenc.intPOSTipoVenta;
        this.POSEncNuevo.intEstatus = this.posenc.intEstatus;
        this.POSEncNuevo.intPOSRegister = this.posenc.intPOSRegister;
        this.POSEncNuevo.strUsuarioPOS = this.posenc.strUsuarioPOS;
        this.POSEncNuevo.intGrupo = this.posenc.intGrupo;

        const response = await this.ordenFunctions.save(
          this.POSEncNuevo,
          this.empresa,
          this.terminal
        );
        if (response.IntError > 0) throw response.StrMessage;
        if (response.ObjPOSEncResult != null) {
          POSEncAgregado = response.ObjPOSEncResult;
          POSEncAgregado.POSDet.forEach((detalle) => {
            if (detalle.POSDetArticuloConfiguracion) {
              detalle.POSDetArticuloConfiguracion.forEach((articulo_conf) => {
                articulo_conf = this.ordenFunctions.propertiesToLower(articulo_conf);
                console.log(articulo_conf);
              });
            }
            detalle = this.ordenFunctions.propertiesToLower(detalle);
          });
          POSEncAgregado = this.ordenFunctions.propertiesToLower(POSEncAgregado);
          POSEncAgregado.POSEncPago = [];
          POSEncAgregado.intPOSMesero = this.posenc.intPOSMesero;
          await this.ordenFunctions.saveMesero(this.empresa, {
            intPOSEnc: POSEncAgregado.intPOSEnc,
            intPOSMesero: this.posenc.intPOSMesero,
          });
          POSEncAgregado.strMesa = await this.ordenFunctions.saveMesa(this.empresa, {
            intPOSEnc: POSEncAgregado.intPOSEnc,
            intPOSMesa: this.posenc.intPOSMesa,
          });
          POSEncAgregado.intPOSMesa = this.posenc.intPOSMesa;
        }
        //#endregion

        //#region Quitamos los productos del original
        this.posenc.POSDet = this.POSEncOriginal.POSDet;
        const response_original = await this.ordenFunctions.save_delete(
          this.posenc,
          this.empresa,
          this.terminal
        );
        if (response_original.IntError > 0) throw response_original.StrMessage;
        if (response_original.ObjPOSEncResult != null) {
          POSEncAgregadoOriginal = response_original.ObjPOSEncResult;
          POSEncAgregadoOriginal.POSDet.forEach((detalle) => {
            if (detalle.POSDetArticuloConfiguracion) {
              detalle.POSDetArticuloConfiguracion.forEach((articulo_conf) => {
                articulo_conf = this.ordenFunctions.propertiesToLower(articulo_conf);
                console.log(articulo_conf);
              });
            }
            detalle = this.ordenFunctions.propertiesToLower(detalle);
          });
          POSEncAgregadoOriginal = this.ordenFunctions.propertiesToLower(
            POSEncAgregadoOriginal
          );
          POSEncAgregadoOriginal.POSEncPago = [];
          POSEncAgregadoOriginal.intPOSMesero = this.posenc.intPOSMesero;
          await this.ordenFunctions.saveMesero(this.empresa, {
            intPOSEnc: this.posenc.intPOSEnc,
            intPOSMesero: this.posenc.intPOSMesero,
          });
          POSEncAgregadoOriginal.strMesa = await this.ordenFunctions.saveMesa(
            this.empresa,
            {
              intPOSEnc: this.posenc.intPOSEnc,
              intPOSMesa: this.posenc.intPOSMesa,
            }
          );
          POSEncAgregadoOriginal.intPOSMesa = this.posenc.intPOSMesa;
        }
        //#endregion
        this.$toast.success("Orden Guardada con exito", new Toast());

        this.$emit("separado", {
          nuevo: POSEncAgregado,
          original: POSEncAgregadoOriginal,
        });
        this.modal_auth = false;
        this.localValue.visible = false;
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    guardar_det_separacion() {
      this.posenc.POSDet.forEach((x) => {
        if (x.intPOSDet == this.detalle.intPOSDet) {
          x.bolSeparado = true;
          x.dblSeparar = this.detalle.dblSeparar;
        }
      });
      this.modal_sep = false;
    },
    separar_detalle(index, detalle) {
      try {
        if (this.POSEncOriginal.POSDet.length == 1) {
          throw "No puede dejar la orden sin productos";
        }
        this.POSEncNuevo.POSDet.push(detalle);
        this.POSEncOriginal.POSDet.splice(index, 1);
        //if (detalle.dblCantidad > 1) {
        //    this.detalle = detalle;
        //    this.detalle.dblSeparar = detalle.dblCantidad;
        //    this.modal_sep = true;
        //}
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    regresar_detalle(index, detalle) {
      this.POSEncOriginal.POSDet.push(detalle);
      this.POSEncNuevo.POSDet.splice(index, 1);
      //if (detalle.dblCantidad > 1) {
      //    this.detalle = detalle;
      //    this.detalle.dblSeparar = detalle.dblCantidad;
      //    this.modal_sep = true;
      //}
    },
    separar() {
      this.loading = true;
      try {
        if (this.posenc.POSDet.length == 1) {
          throw "No puedes separar cuentas de solo un producto";
        }
        this.modal_auth = true;
      } catch (e) {
        this.$toast.error(e, new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
